import {Injectable} from '@angular/core';
import {Dth} from './../utils/dth';
import {IndicatorsService} from './indicators.service';
import {Connector} from './connector';
import { TituloCard, Carteira } from '../model/titulo-card';

export interface CalcTDData {
    dt_compra: string,
    dt_venda: string,
    dt_vencto: string,
    id_titulo: number,
    indexador:  string,
    ipca:  string,
    juros: boolean,
    lastro: boolean,
    nom: string,
    nome: string,
    p: string,
    preco_compra: string,
    preco_venda: string,
    qtd: string,
    rbalm: number,
    rlalm: number,
    selected: string,
    selic: string,
    taxa_adm: string,
    taxa_compra: string,
    taxa_venda: string,
    tipo: string,
    valor_aporte: number
}

export interface CalcComparadorData {
    tipo_titulos: string[];
    preco: string;
    dc: string;
    selic: string;
    cdi: string;
    cdb_pos_cdi: string;
    cdb_pos_ipca: string;
    cdb_pre: string;
    lci_pos_cdi: string;
    lci_pos_ipca: string;
    lci_pre: string;
    tx_adm: string;
    td_pre: string;
    td_ipca: string;
    td_selic: string;
    ipca: string;
    poupanca: string;
}

export interface SimulateResult {
    titulo: TituloCard,
    valpoup: number
}

@Injectable()
export class CalculatorService {
    private customHost: string = "https://api.apprendafixa.com.br";
    constructor(private connector: Connector, private indicatorsService: IndicatorsService) {

    }

    calcTD(data): Promise<any> {
        return this.connector.api.post("calcTD", data);
    }
    
    calcEquivalencia(data): Promise<any> {
        return this.connector.api.post("calcEquivalencia", data);
    }
    
    calcPrePos(data): Promise<any> {
        return this.connector.usingHost(this.customHost).post("calcPre_Pos", data);
    }
    
    calcAplicacao(data): Promise<any> {
        return this.connector.api.post("calcAplicacao", data);
    }
    
    calcTaxas(data): Promise<any> {
        return this.connector.api.post("conversaoTaxas", data);
    }

    calcComparador(data): Promise<any> {
        return this.connector.api.post("calcComparador", data);
    }

    calcDI(data){
        return this.connector.api.post("calcDI", data);
    }

    simulatePoupInvestment(valinv: number, dc: number) {
        return this.calcComparador({
            "tipo_titulos":["poupanca"],
            "preco": valinv.toString(),
            "dc": dc.toString(),
            "selic": "7.400000000000001",
            "cdi": "7.39",
            "tx_adm": "0",
            "td_selic": "0",
            "ipca": "2.54",
            "poupanca": "6.890000000000001"
        });
    }

    simulateTdInvestment(titulo: TituloCard, ipca: number, selic: number, poup: number, venda?: Date): Promise<any> {
        ipca = ipca * 100;
        selic = selic * 100;
        const datacompra = Dth.fromDate(new Date());
        const datavencto = Dth.fromDate(titulo.vencto);
        const datavenda = venda ? Dth.fromDate(venda) : datacompra;
        const idx = titulo.idx.toLowerCase();
        const indexador = idx === 'ipca' ? ipca : (idx === 'selic' ? selic : titulo.taxa * 100);
        const qtd = titulo.valinv / titulo.pu;
        const _data = {
            dt_compra: datacompra.toString(),
            dt_vencto: datavencto.toString(),
            dt_venda: datavenda.toString(),
            id_titulo: parseInt(titulo._id),
            indexador: indexador.toString(),
            ipca: ipca.toString(),
            juros: titulo.jurosSemestrais ? true : "undefined",
            lastro: true,
            nom: titulo.nome,
            nome: titulo.nomeTd,
            p: titulo.valinv.toString(),
            preco_compra: (titulo.precoCompra || titulo.pu).toString(),
            preco_venda: (titulo.precoVenda || titulo.pu).toString(),
            qtd: qtd.toString(),
            rlalm: titulo.rentLiqAno * 100,
            rbalm: titulo.rentAno * 100,
            selic: selic.toString(),
            taxa_adm: 0,
            taxa_compra: (titulo.taxaCompra * 100).toString(),
            taxa_venda: (titulo.taxaVenda * 100).toString(),
            tipo: titulo.tipo
        }
        
        return this.calcTD(_data);
    }

    simulatePrivateInvestment(titulo: {
        nome: string, 
        emissor: string, 
        liquidez: string/*liquidez: boolean*/,
        diascorridos: number, 
        incentivado: boolean, 
        valinv: number,
        corretora: string,
        _taxa: string, 
        vencto: Date,
    }, venda?: Date): Promise<any> {
        const nome = titulo.nome.toUpperCase();
        const vencto = Dth.fromDate(titulo.vencto);
        const dc = venda ? parseInt(vencto.daysBetween(Dth.fromDate(venda)) + "") : 0;

        return this.connector.api.post("privateSimulate", {
            emissor: titulo.emissor,
            nome: nome,
            dc: titulo.diascorridos - dc,
            liquidez: titulo.liquidez === "No Vencimento" ? "Vencimento" : titulo.liquidez,
            incentivada: titulo.incentivado,
            preco: titulo.valinv,
            tipo: nome,
            corretora: titulo.corretora,
            taxa: titulo._taxa
        });
    }
}