import { DOCUMENT } from "@angular/common";
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
// import { RouterStateService } from "./router-state.service";

@Injectable()
export class PageService {
    private static _titleService = new Title(DOCUMENT);
    private static _defaultTitle: string;
    constructor(
        // private _titleService: Title,
        private _router: Router,
    ) {
        if (!this.defaultTitle) {
            PageService._defaultTitle = this._titleService.getTitle();
        }
        // RouterStateService.navigationEndSubscribe(event => {
        //     this.restore();
        // });
    }

    public get _titleService(): Title {
        return PageService._titleService;
    }

    public static get defaultTitle(): string {
        return this._defaultTitle;
    }

    public get defaultTitle(): string {
        return PageService.defaultTitle;
    }

    public static get title(): string {
        return this._titleService.getTitle();
    }

    public static set title(value: string) {
        this._titleService.setTitle(value || this.defaultTitle);
    }

    public get title(): string {
        return PageService.title;
    }

    public set title(value: string) {
        PageService.title = value;
    }

    public static restore(): void {
        this.title = null;
    }

    public restore(): void {
        PageService.restore();
    }
}