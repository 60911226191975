import {Component, HostBinding, Input, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {routerAnimation} from '../../../../utils/page.animation';
// import {defaultOptions} from './default.options';
import {defaultOptions} from '../../../../components/grafico-taxas/default.options';
import { IndicatorsService } from '../../../../providers/indicators.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-graphic-chart',
  templateUrl: './graphic-chart.component.html',
  styleUrls: ['./graphic-chart.component.scss'],
  animations: [routerAnimation]
})
export class GraphicChartComponent {
  @ViewChild("datepickerStart", {static: true}) datepickerStart;
  @ViewChild("datepickerEnd", {static: true}) datepickerEnd;
  public startDate: Date;
  public endDate: Date;
  public minDate: Date;
  public maxDate: Date;
  private ready: boolean;
  isHighcharts = typeof Highcharts === 'object'; // Essa verificação é necessária para não executar o highcharts no SSR
  private __refresher: (options: any) => void;
  @Input("chartOptions") __options: any = {};
  @Input() darkMode: boolean;

  constructor(
      private indicatorsService: IndicatorsService,
      private datePipe: DatePipe
  ) {}

  dateToString(date: number): string {
      const _date = new Date(date);
      return this.datePipe.transform(_date, "MM/yyyy");    // "dd/MM/yyyy"
  }

  ngOnInit() {
      this.ready = false;
      let _date = new Date();
      let today    = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());
      let lastYear = new Date(_date.getFullYear()-10, _date.getMonth(), _date.getDate());

      this.startDate = lastYear;
      this.endDate = today;
      this.minDate = new Date(1996, 0, 1);
      this.maxDate = today;
      this.datepickerStart.value = this.startDate;
      this.datepickerEnd.value = this.endDate;
  }

  public get isReady(): boolean {
      return this.ready;
  }

  protected onResize() {
      this.refresh();
  }

  public push($event: (options: any) => void) {
      defaultOptions.series = [];
      if(this.darkMode){
        defaultOptions.chart.backgroundColor = '#383836';
        defaultOptions.xAxis.labels.style.color = 'white';
        defaultOptions.yAxis.labels.style.color = 'white';
        defaultOptions.yAxis.title.style.color = 'white';
        defaultOptions.legend.itemStyle.color = 'white';
      }
      this.__options = Object.assign({}, defaultOptions);
      this.__refresher = $event;
      this.ready = true;
      this.refresh();
  }

  refresh() {
      if (!this.ready) return;
      this.indicatorsService.indicatorsChartObject(this._startDate, this._endDate).then((result) => {
          result = result["dados"];
          if ("ibov" in result)
              delete result['ibov'];
          const legend = [];
          this.__options["series"] = [];
          for (let key in result) {
              const dados = result[key];
              const name = key.toUpperCase();
              const serie = [];
              legend.push(name);
              for (let entry of dados["datas"]) {
                  if (!entry)
                      continue;
                  if (entry.acu12 === null || entry.data === null)
                      continue;
                  const value = parseFloat((<number>entry.acu12).toFixed(2));
                  // Corrige o problema das horas e dia do mes
                  const date = new Date(parseInt(entry.data));
                  const ts = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0).getTime();
                  serie.push([ts, value]);
              }
              if (!serie.length) {
                  continue;
              }
              this.__options["series"].push({
                  name: name,
                  type: "line",
                  data: serie,
                  color: ((this.darkMode && name == 'IGPM') ? '#FF4081' : dados["color"])
              });
              // this.__options.plotOptions.series.color.push(dados["color"]);
          }
          this.__refresher(this.__options);
      });
  }

  public get minEndDate() {
      return this.startDate;
  }

  public get maxStartDate() {
      return this._endDate;
  }

  public get _startDate(): Date | null {
      return this.datepickerStart.value || null;
  }

  public get _endDate(): Date | null {
      return this.datepickerEnd.value || null;
  }

  public get options() {
      return this.__options;
  }

  public onMinDateChange($event) {
      this.refresh();
  }

  public onMaxDateChange($event) {
      this.refresh();
  }
}
