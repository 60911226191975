import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Constants } from '../providers/constants';

@Injectable()
export class FundosService {
    url;
    private api_url;
    constructor(
        private http: HttpClient,
        private constants: Constants
    ) {
        this.url = this.constants.getUrl()
        this.api_url = this.url + 'fundos';
    }

    private category = 0;

    private lastUrl: string;

    getIbov(){
        return this.http.get(this.constants.get('ibovValue'));
    }

    getPersonalizado(indices, dt_inicio, dt_fim, cnpj_fundo, multiplicador) {
        let params;
        if ((!cnpj_fundo) || (!(cnpj_fundo.length > 0))) {
            params = {
                "indices": indices,
                "dt_inicio": dt_inicio,
                "dt_fim": dt_fim,
                "multiplicador": multiplicador
            }
        } else {
            params = {
                "cnpj_fundo": cnpj_fundo,
                "indices": indices,
                "dt_inicio": dt_inicio,
                "dt_fim": dt_fim,
                "multiplicador": multiplicador
            }
        }
        return this.http.post(this.api_url + '/personalizado', 
        params,
         {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    getDistribuidoras() {
        return this.http.get(this.api_url + '/fundos_distribuidoras', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    getLastUrl() {
        if (!this.lastUrl) {
            this.lastUrl = "/app/indicadores"
        }
        return this.lastUrl
    }

    setLastUrl(last_url) {
        this.lastUrl = last_url;
    }

    getTopFundos() {
        return this.http.get(this.api_url + '/fundos_top', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    getFundosNames() {
        return this.http.get(this.api_url + '/fundos_nomes', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });

        //
    }

    getFundosNamesHome() {
        return this.http.get(this.api_url + '/fundos_nomes_home', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'max-age=31536000, must-revalidate'
            })
        });
    }

    getHashFundos() {
        const token = localStorage.getItem('g-recaptcha');
        return this.http.get(this.api_url + '/fundos_hash', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'g-rcp-tkn': token
            })            
        });
    }

    accertGetFundosResponse(data) {
        if (data instanceof Object) {
            if (data.fundos_lista instanceof Array) {
                if (data.fundos_lista[0] instanceof Object) {
                    return true
                }
            }
        }
        return false
    }

    accertGetFundosNames(data) {
        if (data instanceof Object) {
            if (data.fundos instanceof Array) {
            }
        }
    }

    getGestores() {
        const token = localStorage.getItem('g-recaptcha');
        return this.http.get(this.api_url + '/fundos_gestores', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'g-rcp-tkn': token
            })
        });
    }

    getFundoComparador(cnpj: number, correlacao: any[]) {
        if (cnpj) {
            return this.http.post(this.api_url + '/fundo_comparador', { "cnpj_fundo": cnpj, "correlacao": correlacao }, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            });
        }
    }

    getFundos(filters?) {
        if (!filters) {
            filters = { "todos": true }
        }
        const token = localStorage.getItem('g-recaptcha');
        return this.http.post(this.api_url + '/fundos_lista', filters, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'g-rcp-tkn': token
            })
        });
    }

    getFundoDetalhe(data: string) {
        const token = localStorage.getItem('g-recaptcha');
        return this.http.post(this.api_url + '/fundo_detalhe_ibov', { "nome_fundo": data }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'g-rcp-tkn': token,
            })
        });
    }
    
    getIndices(params?) {
        if (!params) {
            params = {};
        } 
        return this.http.post(this.api_url + '/indices', params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    setCategory(category) {
        this.category = category;
    }

    getCategory() {
        return this.category;
    }
}
