import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideitemComponent} from './item/sideitem.component';
import {SidemenuComponent} from './menu/sidemenu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {RouterModule} from '@angular/router';
import { SidenavService } from './sidenav.service';
import { CloseService } from './close.service';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
  ],
  providers: [SidenavService, CloseService],
  declarations: [SidemenuComponent, SideitemComponent],
  exports: [SidemenuComponent, SideitemComponent]
})
export class SidemenuModule {
}
