import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssessorLinksDialogComponent } from './assessor-links-dialog/assessor-links-dialog.component';

@Injectable()
export class AssessorService {
    public typesDialogs: string;
    constructor(private dialog: MatDialog) {}

    public get assessorWhatsapp(): string {
        return AssessorLinksDialogComponent.assessorWhatsapp;
    }
    public get assessorEmail(): string {
        return AssessorLinksDialogComponent.assessorEmail;
    }
    public set assessorTypesDialogs(typesDialogs){

    }
    public openDialog() {
        return this.dialog.open(AssessorLinksDialogComponent,{maxWidth: 'none'});
    }
}