import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { HelpMeComponent } from "./help-me.component";
import { HelpMeService } from "./help-me.service";


@NgModule({
    declarations: [HelpMeComponent],
    imports: [MatIconModule, MatTooltipModule],
    exports: [HelpMeComponent],
    providers: [HelpMeService]
})
export class HelpMeModule {

}