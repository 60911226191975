import {Injectable, NgZone} from '@angular/core';
import {Connector} from './connector';
import {LocalStorageService} from './localstorage.service';
import {UserService} from './user.service';
import {ProgressiveServiceBase} from './progressive.service.base';
import {TituloCard, Carteira} from './../model/titulo-card';
import {Dth} from "./../utils/dth";
import {Idx} from "./../utils/idx";
import { CorretorasService } from './corretoras.service';
import { EmissoresService } from './emissores.service';
import { Constants } from './constants';
import { HttpClient } from '@angular/common/http';

interface TdCotacaoTituloDetails {
    data_vencto?: string,
    dc?: number,
    dt_ini?: string,
    dt_venda?: string,
    du?: number,
    id_titulo?: number,
    ir?: number,
    lastro?: boolean,
    nom?: string,
    nome?: string,
    p?: string,
    prba?: number,
    preco_compra?: string,
    preco_venda?: string,
    prla?: number,
    qtd?: string,
    rba?: number,
    rbd?: number,
    rbm?: number,
    rbp?: number,
    rla?: number,
    rld?: number,
    rlm?: number,
    rlp?: number,
    rpd?: number,
    rpp?: number,
    subtipo?: string,
    taxa_adm?: number,
    taxa_compra?: string,
    taxa_venda?: string,
    tipo?: string,
    teq?: string,
    tt?: number,
    tx?: string,
    va?: number,
    vb?: number,
    vl?: number,
    vbr?: number,
    vir?: number,
    vlr?: number,
    vpp?: number,
    vrb?: number,
    vrl?: number,
    vtbr?: number,
    vtc?: number,
    vtlr?: number,
    corretora?: string,
    patrocinado?: boolean,
    nm_url_amigavel?: string,
    imagem?: string,
    detalhes?: string,
    enable_popup?: boolean,
    url?: string,
    rentabilidades?: any;
    escalonado?: any;
}

interface PrivadosTituloDetails {
    _id?: any,
    a?: string,
    agencia?: string,
    am?: number,
    amortizacao?: string,
    carencia?: string,
    corretora?: string,
    dc?: number,
    du?: number,
    cores?:any,
    logo?: any,
    emissor?: string,
    idx?: string,
    incentivada?: false,
    tx_prog?: boolean,
    juros?: number,
    liquidez?: string,
    nr?: number,
    preco?: number,
    prlt?: number,
    qtdMinima?: number,
    qualificado?: false,
    rating?: string,
    rba?: number,
    rbd?: number,
    rbm?: number,
    rbp?: number,
    rla?: number,
    rld?: number,
    rlm?: number,
    rlp?: number,
    rpd?: number,
    rpp?: number,
    taxa?: string,
    tipo?: string,
    tir?: number,
    tp_d?: string,
    tt?: number,
    vb?: number,
    vencimento?: string,
    vir?: number,
    vl?: number,
    vbr?: number,
    vlr?: number,
    vpp?: number,
    vrl?: number,
    msg_patrocinado?: string,
    investir?: boolean,
    escalonado?: boolean
}

interface InvestmentDates {
    minDate?: Date,
    maxDate?: Date,
    _id: any
}

export interface Investment {
    imovel: any;
    lft: any;
    vencimento?: string,
    vl?: number,
    vb?: number,
    vbr?: number,
    vlr?: number,
    rpp?: number,
    am?: number,
    qtdMinima?: number,
    qualificado?: boolean,
    tir?: number,
    tt?: number,
    idx?: string,
    rpd?: number,
    liquidez?: string,
    total?: number,
    rbp?: number,
    a?: string,
    logo?: any[],
    incentivada?: boolean,
    tx_prog?: boolean,
    du?: number,
    tipo?: string,
    chat?: boolean,
    avista_id?: number,
    rbm?: number,
    rld?: number,
    amortizacao?: string,
    rla?: number,
    rbd?: number,
    rlm?: number,
    rba?: number,
    vpp?: number,
    prlt?: number,
    rating?: string,
    carencia?: string,
    corretora?: string,
    rlp?: number,
    cores?: any[],
    vrl?: number,
    dc?: number,
    preco?: number,
    _id?: any,
    tp_d?: string,
    vir?: number,
    taxa?: string,
    teq?: string,
    nr?: number,
    emissor?: string,
    agencia?: string,
    investir?: boolean,
    juros?: number,
    msg_patrocinado?: string,
    nm_url_amigavel?: string ,
    imagem?: string,
    detalhes?: string,
    enable_popup?: boolean,
    rentabilidades?: any,
    url?: string;
    escalonado?: any
}

export interface Query {
    type?: number,
    dc?: number,
    preco?: number,
    limit?: number,
    idx?: string[],
    tipo?: string[],
    corretora?: string[],
    emissor?: string[],
    random?: boolean,
    dc_ini?: number,
    dc_fim?: number,
    risco_ini?: number,
    risco_fim?: number,
    irp_ini?: number,
    irp_fim?: number,
    liquidez?: boolean
}

export function calculateIofPercent(dc: number): number {
    return [
        1.00, 0.96, 0.93, 0.90,
        0.86, 0.83, 0.80,
        0.76, 0.73, 0.70,
        0.66, 0.63, 0.60,
        0.56, 0.53, 0.50,
        0.46, 0.43, 0.40,
        0.36, 0.33, 0.30,
        0.26, 0.23, 0.20,
        0.16, 0.13, 0.10,
        0.06, 0.03, 0.0
    ][dc] || 0;
}


export function calculateIrPercent(dc: number): number {
    return dc <= 180 ? 0.225 : (dc <= 360 ? 0.2 : (dc <= 720 ? 0.175 : 0.15));
}

@Injectable()
export class InvestmentsService extends ProgressiveServiceBase {
    private _dates: any = {};
    private _idxs_set: any = {};
    private _idxs: {id: string, alias: string}[] = [];
    constructor (
        private connector: Connector,
        protected localStorage: LocalStorageService,
        private userService: UserService,
        private corretorasService: CorretorasService,
        private emissoresService: EmissoresService,
        private constants: Constants,
        private http: HttpClient,
        private $zone: NgZone,
    ) {
        super(localStorage);
    };

    public getInvestmentDates(_id: any): InvestmentDates {
        return this._dates[_id] || null;
    }

    public getInvestmentDatesSafe(_id: any): InvestmentDates {
        return this.getInvestmentDates(_id) || {_id: _id};
    }

    private pushInvestmentDates(_id: any, minDate?: Date, maxDate?: Date) {
        if (!this.getInvestmentDates(_id)) {
            this._dates[_id] = {_id: _id};
        }
        this._dates[_id].minDate = minDate || null;
        this._dates[_id].maxDate = maxDate || null;
    }

    public get idxs(): {id: string, alias: string}[] {
        return this._idxs || [];
    }

    private solveIdx(idx: string) {
        const idxSolved: string = Idx.solveSafe(idx);
        if (idxSolved === null) {
            return null;
        }
        if (!this._idxs_set[idxSolved]) {
            this._idxs.push({
                id: idxSolved,
                alias: idxSolved
            });
            if (idxSolved !== idx) {
                this._idxs_set[idxSolved] = idx;
            }
        }
        if (!this._idxs_set[idx]) {
            this._idxs_set[idx] = idx;
        }
        return idxSolved;
    }

    public idxsFor(isTd: boolean, isPrivado: boolean): string[] {
        if (isTd && !isPrivado) {
            return Idx.idxsForTd;
        } else if (!isTd && isPrivado) {
            return Idx.idxsForPrivados;
        } else {
            return Idx.idxs;
        }
    }

    public idxsForKey(key?: string, all?: boolean): {id: string, alias: string}[] {
        key = key && !all ? key.toLowerCase() : "all";
        const td = key === "tesourodireto";
        return this.idxsFor(key === "all" || td, key === "all" || !td).reduce((arr, element) => {
            arr.push({
                id: element,
                alias: element
            })
            return arr;
        }, []);
    }

    public investmentsTdCotacaoUrl(key: string): string {
        key += "/true"
        return this.connector.constants.get("publicInvestments").replace("{asset}", key);
    }

    public investmentsTdCotacao(key?: string): Promise<any> {
        key = !key ? "" : key;
        if (key.toLowerCase() === "all") {
            key = "ALL";
        }
        const payload = {objectId: null, email: this.userService.user.email};
        return this.connector.post(this.investmentsTdCotacaoUrl(key), payload);
    }

    public investmentsTdCotacaoObject(key?: string): Promise<TituloCard[]> {
        const pkey: string = this.joinKeys("tesourodireto", key, ".");
        return new Promise<TituloCard[]>((resolve, reject) => {
            this.investmentsTdCotacao(key).then((response) => {
                let titulos: TituloCard[] = [];
                this.$zone.runOutsideAngular(() => {
                    const _json = response;
                    const data = _json["resultado"]["titulos"];
                    const hist = {};
                    _json["hist_min_date_all"].forEach(element => {
                        hist[element.id_titulo] = Dth.from(element.min_date);
                    });
                    this.save(pkey, data, 5).forEach((element: TdCotacaoTituloDetails) => {
                        const _id = element.id_titulo.toString();
                        const preco = parseFloat(element.p);
                        const vencto = Dth.from(element.data_vencto);
                        const dateini = hist[_id];
                        const datefim = Dth.from(element.dt_venda);
                        const idx = this.solveIdx(element.tipo);
                        const _p = element.nome.indexOf("(");
                        const _pf = element.nome.indexOf(")") + 1;
                        const nomeTdOld = _p > -1 ? element.nome.slice(_p, _pf) : element.nome;
                        const nomeTd = _p > -1 ? element.nome.slice(0, _p) : element.nome;
                        this.pushInvestmentDates(_id, dateini.date, datefim.date);
                        titulos.push(TituloCard.from({
                            patrocinado: null,
                            agencia: null,
                            __agencia: null,
                            distribuicao: null,
                            rating: null,
                            amortizacao: null,
                            carencia: null,
                            corretora: null,
                            diasIni: dateini,
                            diascorridos: element.dc || vencto.daysBetween(dateini),
                            diasuteis: element.du || null,
                            emissor: null,
                            idx: idx,
                            incentivado: false,
                            _id: _id,
                            iof: null,
                            iofp: null,
                            irp: element.ir,
                            ir: element.vir,
                            liquidez: null,
                            aporte_fgc: null,
                            nom: element.nom,
                            _taxaCompra: element.taxa_compra,
                            nome: element.nome,
                            nomeTd: nomeTd,
                            nomeTdOld: nomeTdOld,
                            pu: preco,
                            qtd: parseFloat(element.qtd),
                            rent12Mes: undefined,
                            rentAcu: element.rbp/100,
                            rentAno: element.rba/100,
                            rentMes: element.rbm/100,
                            rentDia: element.rbd/100,
                            rentLiq12Mes: undefined,
                            rentLiqAcu: element.rlp/100,
                            rentLiqAno: element.rla/100,
                            rentLiqMes: element.rlm/100,
                            rentLiqDia: element.rld/100,
                            taxa: element.tt,
                            _taxa: element.tt.toString(),
                            tipo: element.tipo,
                            valbrt: element.vtbr || element.vbr || element.vb,
                            valliq: element.vtlr || element.vlr || element.vl,
                            valinv: preco,
                            _vencto: element.data_vencto,
                            vencto: vencto.date,
                            qualificado: false,
                            carteira: Carteira.Td,
                            taxaCompra: parseFloat(element.taxa_compra)/100,
                            taxaVenda: parseFloat(element.taxa_venda)/100,
                            precoCompra: parseFloat(element.preco_compra),
                            precoVenda: parseFloat(element.preco_venda),
                            jurosSemestrais: !!element.subtipo,
                            poup: element.vpp,
                        }));
                    });
                });
                resolve(titulos);
            }).catch(this.catch(pkey, resolve, reject));
        });
    }

    public investmentsTdCotacaoAll(): Promise<TituloCard[]> {
        return this.investmentsTdCotacaoObject("ALL")
    }

    public investmentsPrivadosUrl(key: string): string {
        return this.connector.constants.get("privateInvestments").replace("{asset}", key);
    }

    public investmentsPrivados(key: string): Promise<any> {
        return this.connector.get(this.investmentsPrivadosUrl(key));
    }

    public investmentsPrivadosObject(key: string, subkey?: string | string[]): Promise<TituloCard[]> {
        console.log('entrou investmentsPrivadosObject');

        subkey = Array.isArray(subkey) ? subkey : (subkey ? [subkey] : []);
        const pkey = this.joinKeys("privados", key, ".");
        return new Promise<TituloCard[]>((resolve, reject) => {
            this.investmentsPrivados(key).then(response => {
                const data = response;
                let titulos: TituloCard[] = [];
                data.resultados.forEach(resultados => {
                    const idx = this.solveIdx(resultados["alias"]);
                    if (subkey.indexOf(idx) >= 0) {
                        return;
                    }
                    resultados["titulos"].forEach((element: PrivadosTituloDetails) => {
                        let _id = element._id;
                        _id = _id ? (_id["$oid"] ? _id["$oid"] : _id) : _id;
                        const vencto = TituloCard.formatVencto(element.vencimento);
                        const juros = element.taxa.indexOf('-') > 0 ? -element.juros : element.juros;

                        titulos.push(TituloCard.from({
                            patrocinado: element.msg_patrocinado || null,
                            invest: element.investir || false,
                            agencia: element.agencia,
                            __agencia: element.a,
                            distribuicao: element.tp_d,
                            rating: TituloCard.parseRating(element.rating),
                            amortizacao: element.amortizacao,
                            carencia: TituloCard.parseCarencia(element.carencia),
                            corretora: element.corretora,
                            diascorridos: element.dc,
                            tt: element.tt,
                            diasuteis: element.du,
                            emissor: element.emissor,
                            idx: idx,
                            tx_prog: element.tx_prog,
                            incentivado: element.incentivada,
                            _id: _id,
                            iof: 0,
                            iofp: 0,
                            irp: element.tir,
                            ir: element.vir,
                            liquidez: element.liquidez,
                            aporte_fgc: element.am,
                            nome: element.tipo,
                            pu: element.preco,
                            qtd: element.preco/element.qtdMinima,
                            rent12Mes: undefined,
                            rentAcu: element.rbp/100,
                            rentAno: element.rba/100,
                            rentMes: element.rbm/100,
                            rentDia: element.rbd/100,
                            rentLiq12Mes: undefined,
                            rentLiqAcu: element.rlp/100,
                            rentLiqAno: element.rla/100,
                            rentLiqMes: element.rlm/100,
                            rentLiqDia: element.rld/100,
                            taxa: juros/100,
                            _taxa: element.taxa,
                            tipo: element.tipo,
                            valbrt: element.vb,
                            valliq: element.vl,
                            valinv: element.preco,
                            _vencto: vencto,
                            vencto: TituloCard.parseVencto(vencto),
                            qualificado: !!element["qualificado"],
                            carteira: Carteira.Privados,
                            poup: element.vpp
                        }));
                    });
                });
                resolve(this.save(pkey, titulos, 5));
            }).catch(this.catch(pkey, resolve, reject));
        });
    }

    public featuredInvestments(
        query: number,
        dc?: number,
        preco?: number,
        limit?: number,
        idx?: string[],
        tipo?: string[],
        corretora?: string[],
        emissor?: string[],
        random?: boolean,
        dc_ini?: number,
        dc_fim?: number,
        risco_ini?: number,
        risco_fim?: number,
        irp_ini?: number,
        irp_fim?: number,
        liquidez?: boolean,
        fgc?: boolean
    ): Promise<any> {
        if (tipo) {
            tipo = Array.isArray(tipo) ? tipo : [tipo];
            tipo = tipo.reduce((arr: string[], v) => {
                if (v === "Debênture") {
                    arr.push("deb")
                } else {
                    v = v.toUpperCase();
                    if (v === "TESOURODIRETO") {
                        arr.push("TD");
                    } else {
                        arr.push(v);
                    }
                }
                return arr;
            }, []);
        }
        if (idx) {
            idx = Idx.normalize(idx);
        }
        if (corretora) {
            corretora = Array.isArray(corretora) ? corretora : [corretora];
            corretora = corretora.reduce((arr: string[], v) => {
                const c = this.corretorasService.getCorretoraSafe(v)
                arr.push(c.nome);
                return arr;
            }, []);
        }
        if (emissor) {
            emissor = Array.isArray(emissor) ? emissor : [emissor];
            emissor = emissor.reduce((arr: string[], v) => {
                arr.push(this.emissoresService.getEmissorName(v));
                return arr;
            }, []);
        }
        const token = this.localStorage.getItem('g-recaptcha');
        const teste = this.http.post(this.constants.get("privateFeatured"), {
            tp_q: query,
            dc: dc,
            valor: preco,
            limit: limit,
            idx: idx,
            tipo: tipo,
            corretora: corretora,
            emissor: emissor,
            random: random,
            dc_ini: dc_ini,
            dc_fim: dc_fim,
            risco_ini: risco_ini,
            risco_fim: risco_fim,
            irp_ini: irp_ini,
            irp_fim: irp_fim,
            l: liquidez,
            fgc: fgc
        }, {
            headers: {
            'g-rcp-tkn': token
        }}).toPromise();
        // const teste =  this.connector.api.post("privateFeatured", {
        //     tp_q: query,
        //     dc: dc,
        //     valor: preco,
        //     limit: limit,
        //     idx: idx,
        //     tipo: tipo,
        //     corretora: corretora,
        //     emissor: emissor,
        //     random: random,
        //     dc_ini: dc_ini,
        //     dc_fim: dc_fim,
        //     risco_ini: risco_ini,
        //     risco_fim: risco_fim,
        //     irp_ini: irp_ini,
        //     irp_fim: irp_fim,
        //     l: liquidez,
        //     fgc: fgc
        // },{
        //     'g-rcp-tkn': token
        // });
        return teste;
    }

    featuredInvestmentsObject(
        query: number,
        limit?: number,
        dc?: number,
        preco?: number,
        idx?: string[],
        tipo?: string[],
        corretora?: string[],
        emissor?: string[],
        random?: boolean,
        dc_ini?: number,
        dc_fim?: number,
        risco_ini?: number,
        risco_fim?: number,
        irp_ini?: number,
        irp_fim?: number,
        liquidez?: boolean,
        fgc?:boolean,
        imovel?: any,
        lft?: any
    ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.featuredInvestments(
                query, dc, preco, limit,
                idx, tipo, corretora, emissor, random,
                dc_ini, dc_fim, risco_ini, risco_fim,
                irp_ini, irp_fim, liquidez,fgc
            )
            .then(
                (response) => {
                    resolve(response);
                }
            )
            .catch(
                (error) => {
                    reject([])
                }
            );
        });
    }

    private parseFeaturedInvestmentPrivados(element: Investment): TituloCard {
        let _id = element._id;
        _id = _id ? (_id["$oid"] ? _id["$oid"] : _id) : _id;
        const vencto = TituloCard.formatVencto(element.vencimento);
        const juros = element.taxa.indexOf('-') > 0 ? -element.juros : element.juros;
        const tipo = element.tipo.indexOf("DEB") >= 0 ? "Debênture" : element.tipo;
        return TituloCard.from({
            patrocinado: element.msg_patrocinado || null,
            invest: element.investir || false,
            agencia: element.agencia,
            __agencia: element.a,
            distribuicao: element.tp_d,
            rating: TituloCard.parseRating(element.rating),
            amortizacao: element.amortizacao,
            carencia: TituloCard.parseCarencia(element.carencia),
            corretora: element.corretora,
            diascorridos: element.dc,
            tt: element.tt,
            diasuteis: element.du,
            emissor: element.emissor,
            idx: element.idx,
            tx_prog: element.tx_prog,
            incentivado: element.incentivada,
            _id: _id,
            iof: 0,
            iofp: 0,
            irp: element.tir/100,
            ir: element.vir,
            liquidez: element.liquidez,
            aporte_fgc: element.am,
            nome: tipo,
            nm_url_amigavel: element.nm_url_amigavel,
            imagem: element.imagem,
            detalhes: element.detalhes,
            enable_popup: element.enable_popup,
            rentabilidades: element.rentabilidades,
            url: element.url,
            pu: element.preco,
            qtd: element.preco/element.qtdMinima,
            rent12Mes: undefined,
            rentAcu: element.rbp/100,
            rentAno: element.rba/100,
            rentMes: element.rbm/100,
            rentDia: element.rbd/100,
            rentLiq12Mes: undefined,
            rentLiqAcu: element.rlp/100,
            rentLiqAno: element.rla/100,
            rentLiqMes: element.rlm/100,
            rentLiqDia: element.rld/100,
            taxa: juros/100,
            _taxa: element.taxa,
            tipo: tipo,
            teq: element.teq,
            valbrt: element.vb || element.vbr,
            valliq: element.vl || element.vlr,
            valinv: element.preco,
            _vencto: vencto,
            vencto: TituloCard.parseVencto(vencto),
            qualificado: !!element["qualificado"],
            carteira: Carteira.Privados,
            poup: element.vpp,
            lft: element.lft,
            cores: element.cores,
            logo: element.logo,
            imovel: element.imovel,
            escalonado: element.escalonado,
            juros: element.juros
        }, {
            avista_id: element.avista_id
        });
    }

    private parseFeaturedInvestmentTd(element: TdCotacaoTituloDetails): TituloCard {
        const _id = element.id_titulo.toString();
        const preco = parseFloat(element.p);
        const vencto = Dth.from(element.data_vencto);
        const dateini = Dth.from(element.dt_ini);
        const datefim = Dth.from(element.dt_venda);
        const idx = this.solveIdx(element.tipo);
        const _p = element.nome.indexOf("(");
        const _pf = element.nome.indexOf(")") + 1;
        const nomeTdOld = _p > -1 ? element.nome.slice(_p, _pf) : element.nome;
        const nomeTd = _p > -1 ? element.nome.slice(0, _p) : element.nome;
        const patrocinado = element.patrocinado;
        this.pushInvestmentDates(_id, dateini.date, datefim.date);
        return TituloCard.from({
            patrocinado: patrocinado ? (typeof patrocinado === "string" ? patrocinado : "patrocinado") : null,
            agencia: null,
            __agencia: null,
            distribuicao: null,
            rating: null,
            amortizacao: null,
            carencia: null,
            corretora: element.corretora || null,
            diascorridos: element.dc || vencto.daysBetween(dateini),
            diasuteis: element.du || null,
            emissor: null,
            idx: idx,
            incentivado: false,
            _id: _id,
            iof: null,
            iofp: null,
            irp: element.ir,
            ir: element.vir,
            liquidez: null,
            aporte_fgc: null,
            nome: element.nome,
            nomeTd: nomeTd,
            nomeTdOld: nomeTdOld,
            nm_url_amigavel: element.nm_url_amigavel,
            imagem: element.imagem,
            detalhes: element.detalhes,
            enable_popup: element.enable_popup,
            rentabilidades: element.rentabilidades,
            url: element.url,
            pu: preco,
            qtd: parseFloat(element.qtd),
            rent12Mes: undefined,
            rentAcu: element.rbp/100,
            rentAno: element.rba/100,
            rentMes: element.rbm/100,
            rentDia: element.rbd/100,
            rentLiq12Mes: undefined,
            rentLiqAcu: element.rlp/100,
            rentLiqAno: element.rla/100,
            rentLiqMes: element.rlm/100,
            rentLiqDia: element.rld/100,
            taxa: element.tt/100,
            _taxa: element.tt.toString(),
            _taxaCompra: element.taxa_compra,
            nom: element.nom,
            tipo: element.tipo,
            valbrt: element.vtbr || element.vbr || element.vb,
            valliq: element.vtlr || element.vlr || element.vl,
            valinv: preco,
            _vencto: element.data_vencto,
            vencto: vencto.date,
            qualificado: false,
            carteira: Carteira.Td,
            taxaCompra: parseFloat(element.taxa_compra)/100,
            taxaVenda: parseFloat(element.taxa_venda)/100,
            teq: element.teq,
            precoCompra: parseFloat(element.preco_compra),
            precoVenda: parseFloat(element.preco_venda),
            jurosSemestrais: !!element.subtipo,
            poup: element.vpp
        });
    }

    private parseFeaturedInvestment(element: any) {
        /* Aqui que define se um título é publico ou privado */
        if (!element.vencimento || element.data_vencto){
            return this.parseFeaturedInvestmentTd(element);
        }
        return this.parseFeaturedInvestmentPrivados(element);
    }

    public parseFeaturedInvestments(result): TituloCard[] {
        let titulos: TituloCard[] = [];
        result.forEach((element: any) => {
            const titulo = this.parseFeaturedInvestment(element);
            titulos.push(titulo);
        });
        return titulos;
    }

    public queryInvestments(query?: Query): Promise<TituloCard[]> {
        query = !query ? {type: 3} : query;
        query.type = !query.type ? 3 : query.type;
        return new Promise<TituloCard[]>((resolve, reject) => {
            this.featuredInvestmentsObject(
                query["type"], query["limit"], query["dc"],
                query["preco"], query["idx"], query["tipo"],
                query["corretora"], query["emissor"], query["random"],
                query["dc_ini"], query["dc_fim"], query["risco_ini"],
                query["risco_fim"], query["irp_ini"], query["irp_fim"], query["liquidez"],query["fgc"]
            ).then((result) => {
                if (!Array.isArray(result)) {
                    result = [];
                }
                resolve(this.parseFeaturedInvestments(result));
            }).catch(err => {
                console.error(err)
                resolve([]);
            });
        });
    }

    public investments(key: string, subkey?: string): Promise<TituloCard[]> {
        console.log('entrou investments');

        if (key === "ALL") {
            console.log('entrou if (key === "ALL")');
            return this.queryInvestments();
        } else if (key === "tesourodireto") {
            if (subkey)
                return this.investmentsTdCotacaoObject(subkey);
            return this.investmentsTdCotacaoAll();
        }
        return this.investmentsPrivadosObject(key, subkey);
    }

    public investmentCount(query: {
        valor?: number,
        vencto?: number,
        corretora?: string[],
        tipo?: string[],
        idx?: string[],
    }): Promise<any> {
        const data: any = query || {};
        return this.connector.api.post("privateInvestmentCountTitles", {
            txtValor: data.valor,
            txtVencto: data.vencto,
            txtCorretora: data.corretora,
            txtAtivo: data.tipo,
            txtTipo: data.idx
        });
    }

    public investmentCountObject(query: {
        valor?: number,
        vencto?: number,
        corretora?: string[],
        tipo?: string[],
        idx?: string[],
    }): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            this.investmentCount(query).then(response => {
                resolve(response['count']);
            }).catch(reject);
        });
    }

    public investmentTdRateHistory(titulo: TituloCard): Promise<any> {
        const _id = titulo._id;
        const _now = new Date()
        const _lastYear = new Date();
        _lastYear.setFullYear(_now.getFullYear() - 1);
        const now = "/" + _now.getTime();
        const lastYear = "/" + _lastYear.getTime();
        const url = this.connector.constants.get("publicHistoricChart") + _id + lastYear + now;
        return this.connector.get(url + "/");
    }

    public investmentPrivadosRateHistory(titulo: TituloCard): Promise<any> {
        return this.connector.api.post("privateHistory", {
            corretora: titulo.corretora,
            emissor: titulo.emissor,
            tipo: titulo.tipo,
            valor: titulo.valinv,
            dc: titulo.diascorridos,
            idx: titulo.idx,
        });
    }

}
