import {LocalStorageService, JsonValid} from './localstorage.service';
import {getApiError} from './../utils/error';

export class ProgressiveServiceBase {
    constructor (protected localStorage: LocalStorageService) {
        
    }

    private __string(value: string): string {
        return !value ? "" : value;
    }

    protected joinKeys(key1: string, key2: string, separator?: string) {
        if (!key2) {
            return this.__string(key1);    
        }
        return this.__string(key1) + this.__string(separator) + key2;
    }

    protected limit(value, count: number) {
        count = !count ? 0 : count;
        if (!Array.isArray(value)) {
            return value;
        }
        if (!value) {
            return [];
        }
        if (value.length < count || count == 0) {
            return value;
        }
        return value.slice(0, count);
    }

    protected save(key: string, data: JsonValid, limit?: number): any {
        this.localStorage.set(key, this.limit(data, limit), true);
        return data;
    }

    protected load(
        key: string, 
        error: {status: number, json: ()=>any}
    ): any {
        const data = this.localStorage.get(key, true);
        if (!data) {
            throw getApiError(error);
        }
        return data;
    }

    protected parseValue(value: any, key: string) {
        return value;
    }

    protected then(
        key: string, 
        resolve: (value: {json: () => any}) => void | any,
        parseValue?: (value: any, key: string) => any,
        limit?: number
    ) {
        return (value) => {
            if (!parseValue) {
                parseValue = this.parseValue;
            }
            resolve(this.save(key, parseValue(value, key), limit));
        }
    }

    protected catch(key: string, resolve: (value) => void, reject: (value) => void) {
        return (error) => {
            try {
                resolve(this.load(key, error));
            } catch (error) {
                reject(error);
            }
        }        
    }
}