import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import * as errors from './../utils/error';
import {User, UserInterface, UserPerfil, UserSuitability} from './../model/user';
import {Connector} from './connector';
import {LocalStorageService} from './localstorage.service';
import {FacebookService} from './facebook.service';
import {Destroyable, Future, FutureFactory} from './../utils/future';
import { RouterStateService } from './router-state.service';
import { Dth } from '../utils/dth';
import { Constants } from '../providers/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { isPlatformBrowser } from '@angular/common';

interface UserPerfilRequest {
	cidade: string;
	dt_nascto: string;
	dt_nascto_t: number;
	estado: string;
	first_name: string;
	gender: string;
	idade: number;
	last_name: string;
	name: string;
	user_email: string;
}

interface UserSuitabilityRequest {
	nome: string;
	telefone: string;
	valinv: number;
	aporte?: number;
	p1: true;
	p2: number;
	p3: number;
	p4: Array<number>;
	p5: number;
	p6: number;
	p7: number;
	p8: number;
	p9: boolean;
	p10: number;
	p11: number;
	p12: number;
	p13: number;
	p14: number;
	p15: number;
	idade: number;
	last_name: string;
	name: string;
	user_email: string;
}


@Injectable()
export class UserService {
	private informer = new Subject();
	private informerLogout = new Subject();
	private url;

	constructor(
        @Inject(PLATFORM_ID) private platformId: string,
		private connector: Connector,
		private localStorage: LocalStorageService,
		private router: Router,
		private snackBar: MatSnackBar,
		private facebookService: FacebookService,
		private routerStateService: RouterStateService,
		private constants: Constants,
		private reCaptchaV3Service: ReCaptchaV3Service,
		private authService: SocialAuthService,
	) {
		this.url = this.constants.getUrl()
		if(isPlatformBrowser(this.platformId)){
			FacebookService.onRunningOnFacebook(() => {
				this.loginFacebook();
			});	
		}
	}

	public get currentUrl(): string {
		return this.routerStateService.currentUrl;
	}

	public getErrorWithSnackBar(errorResponse, redirect?: string, timeoutInMiliSeconds?: number, customMessage?: string){
        const errorMessage = 'Erro ' + errorResponse.status + (!customMessage ? '.' : ('. ' + customMessage)) +' Por gentileza, envie um feedback com este erro para nossos porquinhos consertarem o problema! Obrigado!';
        if(redirect) this.router.navigate([redirect]);
        if(timeoutInMiliSeconds) setTimeout(()=> {return this.snackBar.open(errorMessage, 'OK');}, timeoutInMiliSeconds);
        else return this.snackBar.open(errorMessage, 'OK');
    }

	private getError(response) {
		return errors.getApiError(response);
	}

	private setUser(user: UserInterface, __doNotEmit?): User {
		this.localStorage.set("user", user);
		const _user = User.from(user);
		if (!__doNotEmit) {
			this._emitStatusChange(_user);
		}
		return _user;
	}

	private deleteUser(__doNotEmit?: boolean) {
		this.localStorage.delete("user");
		if (!__doNotEmit) {
			this._emitStatusChange(this.user);
		}
	}

	private getUser(): UserInterface {
		let data: UserInterface = this.localStorage.get("user");
		if (!data) {
			return new User();
		}
		return data;
	}

	public get user(): User {
		return User.from(this.getUser());
	}

	public get token(): string {
		return this.getUser().token;
	}

	signInWithGoogle(data): Promise<User>  {
		return new Promise<User>((resolve, reject) => {
			const credentials = JSON.parse(atob(data.split(".")[1]));
			const user: User = User.from({
				name: credentials['name'],
				token: data,
				lastName: credentials['family_name'],
				firstName: credentials['given_name'],
				email: credentials['email'],
				picture: credentials['picture'],
				_id: credentials['sub'],
			});
			const objectId = this.getOneSignalId();
			this.sendUserDataToServer(user, objectId).then(() => {
					let userCached = this.applyChachedPerfil(user);
					resolve(this.setUser(userCached));
				}).catch(error => {
					this.deleteUser();
					if (error === undefined) {
						reject('Erro interno ao logar pelo Google');
					}
					reject("Não foi possível logar pelo Google");
				});
		});
	}

	socialSubscription() {
		return this.authService.authState;
	}

	signOut(){
		return this.authService.signOut();
	}

	public logout(): User {
		this.deleteUser();
		this.updateOneSignalPlayerId();
		this.facebookService.logout().then(function () {
		}).catch(function () {
		});
		this.informerLogout.next();
		this.socialSubscription().subscribe((user) => {
			user ? this.signOut : '';
		});
		return new User();
	}

	public onLogout(): { subscribe: (callback: () => void) => Subscription } {
		return this.informerLogout;
	}

	public getPerfil(email?: string){
		return this._getPerfil(email);
	}

	private _getPerfil(email?: string): Promise<any> {
		return this.connector.api.get({id: "perfil", params: {email: email || this.user.email}}, {token: this.token});
	}

	private setPerfil(data: UserPerfil): Promise<any> {
		const user = this.user;
		return new Promise((resolve, reject) => {
			const payload = {
				email: user.email,
				nome: data.firstName,
				sobrenome: data.lastName,
				datanascimento: Dth.fromDate(data.birth).toString(),
				cidade: data.city,
				estado: data.state,
				sexo: data.gender,
				idade: user ? user.age : undefined
			};
			this.connector.api.post({
				id: "perfil",
				params: {email: undefined}
			}, payload, {Token: this.token}).then(response => {
				user.gender = data.gender;
				user.birth = data.birth;
				user.firstName = data.firstName;
				user.lastName = data.lastName;
				user.name = data.firstName + " " + data.lastName;
				user.city = data.city;
				user.state = data.state;
				this.setUser(user, false);
				resolve(response);
			}).catch(reject);
		});
	}

	private get perfil(): Promise<UserPerfil> {
		return new Promise((resolve, reject) => {
			const user = this.user;
			if (!user.logged) {
				return reject("Usuário não está logado");
			}
			this._getPerfil().then(response => {
				const perfil: UserPerfilRequest = (response || {perfil: {}}).perfil;
				if (!perfil) {
					return reject("Nenhum perfil encontrado para " + user.email);
				}
				user.gender = perfil.gender;
				user.birth = new Date(perfil.dt_nascto_t);
				user.firstName = perfil.first_name;
				user.lastName = perfil.last_name;
				user.name = perfil.name;
				user.city = perfil.cidade;
				user.state = perfil.estado;
				this.setUser(user, true);
				resolve(<UserPerfil>user);
			}).catch(reject);
		});
	}

	public updatePerfil(): Promise<UserPerfil>
	public updatePerfil(data: UserPerfil): Promise<UserPerfil>
	public updatePerfil(data?: UserPerfil): Promise<UserPerfil> {
		const promise: Promise<any> = data ? this.setPerfil(data) : this.perfil;
		return new Promise<UserPerfil>((resolve, reject) => {
			promise.then(() => {
				resolve(<UserPerfil>this.user);
			}).catch(reject);
		});
	}

	private applyChachedPerfil(user: User): User {
		const perfil = this.user;
		if (perfil.email !== user.email) {
			return user;
		}
		const name = perfil.firstName && perfil.lastName ? perfil.firstName + " " + perfil.lastName : undefined;
		return Object.assign(user, {
			gender: perfil.gender || user.gender,
			birth: perfil.birth || user.birth,
			firstName: perfil.firstName || user.firstName,
			lastName: perfil.lastName || user.lastName,
			name: name || user.name,
			city: perfil.city || user.city,
			state: perfil.state || user.state
		});
	}

	private sendUserDataToServer(user, objectId?: string) {
		const usuario = {
			access_token: user.token,
			age_range: user.ageRange,
			date: new Date(),
			email: user.email,
			first_name: user.firstName,
			gender: user.gender,
			id: user._id,
			last_name: user.lastName,
			name: user.name,
			picture: user.picture,
			birthday: user.birthday,
		};
		const data = {
			usuario: usuario,
			platform: this.connector.constants.env.platform,
			appVersion: "2.5", //this.connector.constants.env.appVersion,
			objectId: objectId
		}
		return this.connector.api.post("insertOrUpdateUser", data);
	}

	private _readToken(token): Promise<any> {
		let headers: object = {
			Token: token
		}
		return this.connector.usingHost(this.url).get("loginToken", headers);
	}

	public readToken(): Promise<any> {
		return this._readToken(this.token);
	}

	public verifyPhone(phoneNumber, country){
		const query = {
			telefone: phoneNumber,
			pais: country
		}
		const headers = {
			'Content-Type': 'application/json',
			'g-rcp-tkn': localStorage.getItem('g-recaptcha'),
		}
		return this.connector.api.get({id: "validatePhoneNumberGet", params: query}, headers);
	}

	public investPremium(payload){
		const headers = {
			'Content-Type': 'application/json',
			'g-rcp-tkn': localStorage.getItem('g-recaptcha'),
		}
		return this.connector.api.post({id: "investPremium"}, payload, headers);
	}

	private _readLoginToken(token?, __doNotEmit?: boolean): Promise<User> {
		return new Promise<User>((resolve, reject) => {
			if (!token) {
				token = this.token;
			}
			this._readToken(token).then((response) => {
				if (!response) {
					this.deleteUser();
					return reject(this.getError(response));
				}
				let user: User = User.from({
					token: response["access_token"] || this.token,
					name: response['name'],
					lastName: response['last_name'],
					firstName: response['first_name'],
					email: response['email'],
					picture: response['picture'],
					_id: response['id'],
					birth: Dth.from(response['birthday']).date,
					gender: response["gender"],
					state: response["state"],
					city: response["city"],
				});
				user = this.applyChachedPerfil(user);
				resolve(this.setUser(user, __doNotEmit));
			}).catch((error) => {
				this.deleteUser(__doNotEmit);
				reject(this.getError(error));
			});
		});
	}

	public readLoginToken(): Promise<User> {
		return this._readLoginToken();
	}

	public login(email, password) {
		let credentials = `${email}:${password}`;
		let headers = {
			'Authorization': `Basic ${btoa(credentials)}`
		}
		return this.connector.api.get("loginToken", headers);
	}

	public loginUser(email, password): Promise<User> {
		return new Promise<User>((resolve, reject) => {
			this.login(email, password)
			.then((response) => {
				let user: User = User.from({
					name: response['name'],
					token: response['access_token'],
					lastName: response['last_name'],
					firstName: response['first_name'],
					email: response['email'],
					picture: response['picture'],
					_id: response['id'],
					birth: Dth.from(response['birthday']).date,
					gender: response["gender"],
					state: response["state"],
					city: response["city"],
				});
				user = this.applyChachedPerfil(user);
				resolve(this.setUser(user));
			}).catch((error) => {
				this.deleteUser();
				reject(this.getError(error));
			});
		});
	}

	public loginApple(token){
		let headers: object = {
			'Token': `${token}`,
			'login-type': 'apple',
			'source': 'angular'
		}
		return this.connector.api.get("loginToken", headers);
	}

	public recover(email): Promise<any> {
		return this.connector.api.post("loginRecover", {email: email});
	}

	public recoverUser(email): Promise<object> {
		return new Promise<object>((resolve, reject) => {
			this.recover(email).then((response) => {
				if (response.status != 200) {
					return reject(this.getError(response));
				}
				resolve(response);
			}).catch((error) => {
				reject(this.getError(error));
			});
		});
	}

	public signUp(email, password, userName, firstName, lastName, objectId?): Promise<any> {
		return this.connector.api.post("loginSignUp", {
			objectId: objectId || undefined,
			appVersion: "2.5", // this.connector.constants.env.appVersion,
			platform: this.connector.constants.env.platform,
			usuario: {
				email: email,
				password: password,
				name: userName,
				first_name: firstName,
				last_name: lastName
			}
		});
	}

	public signUpEx(email, password, firstName, lastName, phoneNumber?, ddi?, pais?): Promise<any> {
		return this.connector.api.post("loginSignUpEx", {
			objectId: this.getOneSignalId(),
			appVersion: "3.0",  //this.connector.constants.env.appVersion,
			platform: this.connector.constants.env.platform,
			usuario: {
				objectId: this.getOneSignalId(),
				email: email,
				password: password,
				name: firstName + " " + lastName,
				first_name: firstName,
				last_name: lastName,
				phone: phoneNumber,
				ddi: ddi,
				pais: pais
			}
		});
	}

	public loginFacebook(): Promise<User> {
		return new Promise<User>((resolve, reject) => {
			// Login pelo facebook e obtencao do profile
			this.facebookService.login().then(data => {
				let user: User = User.from({
					name: data.name,
					token: data.token,
					lastName: data.lastName,
					firstName: data.firstName,
					email: data.email,
					picture: data.picture,
					_id: data._id,
					birth: Dth.from(data['birthday']).date,
					gender: data["gender"],
					state: data["state"],
					city: data["city"],
				});
				const objectId = this.getOneSignalId();
				user = this.applyChachedPerfil(user);
				this.sendUserDataToServer(user, objectId).then(() => {
					resolve(this.setUser(user));
				}).catch(reject);
			}).catch((error) => {
				this.deleteUser();
				if(error === "unknown"){
					reject("Pop-up do Facebook fechado sem resposta.")
				}
				else if (error === undefined) {
					reject("Alguma coisa está bloqueando o acesso ao Facebook");
				}
				console.error(error);
				reject("Não foi possível logar pelo facebook");
			});
		});
	}

	public isLogged(user?: User): boolean {
		if (!user) {
			user = this.user;
		}
		return !!user.token;
	}

	private rejectLogout(currentRoute?: string): void {
		let extra = undefined;
		// Utiliza a pagina atual se o parametro nao foi informado
		// Null garante que o redirecionamento apos o login sera feito para a home e nao a rota atual
		if (!currentRoute && currentRoute !== null) {
			currentRoute = this.currentUrl;
		}
		// Prepara a queryString para a url de retorno
		// @angular/router apenas navega para paginas internas
		if (currentRoute) {
			extra = {queryParams: {returnTo: currentRoute}}
		}
		this.routerStateService.redirect(['/usuario/login'], extra);
	}

	public validateToken(user?: User): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			if (this.isLogged(user)) {
				setTimeout(() => resolve(user));
			} else {
				this.rejectLogout();
				reject();
			}
		});
	}

	private _emitStatusChange(user: User) {
		this.informer.next(user);
	}

	public get userFuture(): Future<User> {
		const future = FutureFactory.from<User>(this.informer);
		future.inform();
		return future;
	}

	public onUserChange(callback: (user: User, isLogged: boolean) => void, onLogout?: (redirect: (returnTo?: string) => void) => void): Destroyable {
		return this.userFuture.cycle((user: User) => {
			if (!user) {
				user = this.user;
			}
			const isLogged = this.isLogged(user);
			if (isLogged) {
				this._readLoginToken(user.token, true).then(result => {
					user = result;
					if (onLogout && !this.isLogged(user)) {
						setTimeout(() => onLogout(() => this.rejectLogout()));
					} else {
						setTimeout(() => callback(user, isLogged));
					}
				}).catch(err => {
					if (onLogout) {
						setTimeout(() => onLogout(() => this.rejectLogout()));
					}
				});
			} else if (onLogout) {
				setTimeout(() => onLogout(() => this.rejectLogout()));
			} else {
				setTimeout(() => callback(user, false));
			}
		});
	}

	public updateOneSignalPlayerId() {

		const oneSignalPlayerId = this.getOneSignalId();

		if(oneSignalPlayerId == "adblocker") return ;
		this.connector.api.post("insertOrUpdateObjectId", {
			'objectId': oneSignalPlayerId,
			'appVersion': "2.5", //appVersion
			'platform': "web"
		});

	}

	public insertOneSignalPlayerId(token, apple?){
		const oneSignalPlayerId = this.getOneSignalId();

		if(oneSignalPlayerId == 'adblocker' || !oneSignalPlayerId) return "adblocker";
		if(apple){
			let headers: object = {
				'Token': `${token}`,
				'login-type': 'apple',
				'source': 'angular'		
			}
			this.connector.api.patch("userWithNoObjectId", {
				'one_signal_id': oneSignalPlayerId,
			}, headers);
		}
		else{
			let headers: object = {
				'Token': `${token}`,
			}
			this.connector.api.patch("userWithNoObjectId", {
				'one_signal_id': oneSignalPlayerId,
			}, headers);
		}
	}

	public checkUserOneSignalPlayerId(token?){
		if(token){
			let headers: object = {
				'Token': `${token}`,
			}
			return this.connector.api.get("userWithNoObjectId",headers);
		}
		else return ;
	}
	public checkUserCaptcha(){
		return new Promise<void>((resolve, reject)=>{
			this.reCaptchaV3Service.execute(this.constants.recaptcha, 'homepage', (token) => {
				this.localStorage.setItem('g-recaptcha', token);
				resolve();
			})
		})
	}

	private getOneSignalId(){
		return localStorage.getItem('objectId') ? localStorage.getItem('objectId').replace(/"/g, '') : 'adblocker';
	}
}