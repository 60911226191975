import {OnInit, AfterViewInit, Component, Input, Output, ViewChild, EventEmitter, ElementRef, ViewEncapsulation} from '@angular/core';
import { MatDatepickerInput, MatDatepicker } from '@angular/material/datepicker';
import { Dth } from '../../utils/dth';

export type Datepicker = MatDatepicker<Date>;
export type DatepickerInput = MatDatepickerInput<Date>;
export interface DatepickerInputEvent {
    targetElement: HTMLElement,
    value: Date | null;
}

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})
export class DatepickerComponent implements OnInit, AfterViewInit {
    @ViewChild("datepicker", {static: true}) datepicker: Datepicker;
    @ViewChild("datepickerInput", {static: true}) datepickerInput: ElementRef;
    @Input() placeholder: string = "";
    @Input() disabled: any = false;
    @Input() startAt: Date = null;
    @Input() max: Date = null;
    @Input() min: Date = null;
    @Input() default: Date = null;
    @Input() required: boolean;
    @Input() prefix: any;
    @Input() suffix: any;
    @Input() darkMode: boolean;
    @Input("dateModel") model: Date;
    @Input("mask") masked: any;
    @Output("dateModel") modelChange: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() valueChange: EventEmitter<DatepickerInputEvent> = new EventEmitter<DatepickerInputEvent>();
    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.model && this.default)
                this.value = this.default;
            if (!this.startAt)
                this.startAt = this.value;
        });
    }

    ngOnChange(unused) {

    }

    get prefixIcon(): string {
        if (this.prefix === undefined)
            return 'event';
        return this.prefix;
    }

    get suffixIcon(): string {
        return this.suffix || 'arrow_drop_down';
    }

    get value(): Date {
        return this.model;
    }

    set value(value: Date) {
        if (value < this.min && this.min)
            value = this.min;
        else if (value > this.max && this.max)
            value = this.max;
        this.model = value;
        if (!value) {
            this.datepickerInput.nativeElement.value = "";
        } else if (value.getTime instanceof Function && isNaN(value.getTime())) {
            this.datepickerInput.nativeElement.value = "";
        } else {
            this.datepickerInput.nativeElement.value = Dth.from(value).toString();
        }
    }

    get opened(): boolean {
        return this.datepicker.opened;
    }

    open($event?: Event): void {
        if ($event) {
            const src = $event.srcElement || $event.target || (<any>$event).originalTarget || {};
            if ((<HTMLElement>src).blur) (<HTMLElement>src).blur();
        }
        this.datepicker.open();
    }

    close(): void {
        this.datepicker.close();
    }
    
    toggle($event?: Event): boolean {
        const opened = this.opened;
        if (opened) {
            this.datepicker.close();
        }
        this.datepicker.open();
        if ($event) {
            $event.cancelBubble = true;
            $event.preventDefault();
        }
        return opened;
    }

    onValueChange(event: any): void {
        const prev = this.value;
        if (event.value) {
            let date = new Date(event.value);
            this.value = date;
        } else {
            this.value = Dth.from(this.datepickerInput.nativeElement.value).date;
        }
        if (this.value < this.min && this.min) {
            this.value = new Date(this.min.getTime());
        } else if (this.value > this.max && this.max) {
            this.value = new Date(this.max.getTime());
        }
        this.valueChange.emit({
            value: this.value, targetElement: event.targetElement
        });
        this.modelChange.emit(this.value);
    }
}
