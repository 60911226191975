import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CustomFormsModule} from 'ng2-validation';
import { HttpClientModule} from '@angular/common/http';
import {DirectivesModule} from './../directives/directives.module';
import {ProvidersModule} from './../providers/providers.module';
import {RequestLoaderModule} from './../components/request.loader/request.loader.module';

import { AngularMaterialModule } from '../common/angular-material.module';
import { FileUploadModule } from './file-upload/file-upload.module';
import { RangeSliderComponent } from './range-toggle/range-slider/range-slider.component';
import { IndicatorsOverlayComponent } from './indicators/indicators-overlay/indicators-overlay.component';
import { HelpMeModule } from './help-me/help-me.module';
import { AnimatedBannerComponent } from './animated-banner/animated-banner.component';
import { BackLinkComponent } from './backlink/backlink.component';
import { CardsTituloDestaqueComponent } from './cards-titulo-destaque/cards-titulo-destaque.component';
import { CardTituloComponent } from './card-titulo/card-titulo.component';
import { CarouselComponent } from './carousel/carousel.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { HighchartsComponent } from './grafico/grafico.map';
import { FooterComponent } from './footer/footer.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TimeAgoComponent } from './time-ago.component';
import { SignatureCanvasComponent } from './signature-canvas/signature-canvas.component';
import { RangeToggleComponent } from './range-toggle/range-toggle.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { GraficoTaxasComponent } from './grafico-taxas/grafico-taxas.component';
import { SidemenuModule } from './sidemenu/sidemenu.module';
import { EmbededVideoComponent } from './embeded-video/embeded-video.component';
import { AssessorModule } from './assessor/assessor.module';
import { RiskMeterComponent } from './risk-meter/risk-meter.component';
import { FundosChartComponent } from './graficos-fundos/graficos-fundos.component'
import { ShimmerComponent } from './shimmers/shimmer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MasksModule } from '../directives/textmask/textmask.module';
import { DialogPoliticaPrivacidadeComponent } from './dialog-politica-privacidade/dialog-politica-privacidade.component';
import { DialogPoliticaPrivacidadeConcordarComponent } from './dialog-politica-privacidade-concordar/dialog-politica-privacidade-concordar.component';
import { SocialShareDialogComponent } from './social-share-dialog/social-share-dialog.component';
import { InvestFillDataDialogComponent } from './invest-fill-data-dialog/invest-fill-data-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { AdvisoryCallCardComponent } from './advisory-call-card/advisory-call-card.component';

@NgModule({
    declarations: [
        ShimmerComponent,
        AnimatedBannerComponent,
        BackLinkComponent,
        CardsTituloDestaqueComponent,
        CardTituloComponent,
        CarouselComponent,
        DatepickerComponent,
        ExpandableComponent,
        EmbededVideoComponent,
        FooterComponent,
        GraficoTaxasComponent,
        FundosChartComponent,
        HighchartsComponent,
        IndicatorsComponent,
        MultiselectComponent,
        RangeSliderComponent,
        RangeToggleComponent,
        SignatureCanvasComponent,
        TimeAgoComponent,
        ToolbarComponent,
        IndicatorsOverlayComponent,
        RiskMeterComponent,
        DialogPoliticaPrivacidadeComponent,
        DialogPoliticaPrivacidadeConcordarComponent,
        SocialShareDialogComponent,
        InvestFillDataDialogComponent,
        AdvisoryCallCardComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        AngularMaterialModule,
        SidemenuModule,
        PerfectScrollbarModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        CustomFormsModule,
        HttpClientModule,
        RouterModule,
        ProvidersModule,
        RequestLoaderModule,
        DirectivesModule,
        FileUploadModule,
        HelpMeModule,
        AssessorModule,
        MatFormFieldModule,
        MasksModule,
        NgxMaskModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        AngularMaterialModule,
        SidemenuModule,
        PerfectScrollbarModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        CustomFormsModule,
        HttpClientModule,
        RouterModule,
        RequestLoaderModule,
        FileUploadModule,
        HelpMeModule,
        AssessorModule,
        AnimatedBannerComponent,
        BackLinkComponent,
        CardsTituloDestaqueComponent,
        CardTituloComponent,
        CarouselComponent,
        DatepickerComponent,
        EmbededVideoComponent,
        ExpandableComponent,
        FooterComponent,
        GraficoTaxasComponent,
        FundosChartComponent,
        HighchartsComponent,
        IndicatorsComponent,
        MultiselectComponent,
        RangeSliderComponent,
        RangeToggleComponent,
        RiskMeterComponent,
        SignatureCanvasComponent,
        TimeAgoComponent,
        ToolbarComponent,
        ShimmerComponent,
        AdvisoryCallCardComponent
    ],
    providers: []
})
export class ComponentsModule {
}

