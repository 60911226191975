import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TextMaskModule as Lib } from 'angular2-text-mask';
import { TextMaskDirective } from './textmask.directive';

@NgModule({
    imports: [
        FormsModule,
        Lib
    ],
    declarations: [TextMaskDirective],
    exports: [
      Lib,
      TextMaskDirective
    ]
})
export class MasksModule {}