import {Component, OnInit, OnChanges, Input, ViewChild, ElementRef, NgZone} from '@angular/core';
import {Corretora} from '../../model/corretora';
import {FeaturedBanner} from './../../providers/rendafixa.service';
import { CorretorasService } from '../../providers/corretoras.service';


export interface Banner {
	url: string,
	src: string
}

''
@Component({
	selector: 'app-animated-banner',
	templateUrl: './animated-banner.component.html',
	styleUrls: ['./animated-banner.component.scss']
})
export class AnimatedBannerComponent implements OnChanges, OnInit {
	@Input("items") items: FeaturedBanner[];
	private _banners: Banner[];
	public carouselConfig = {
		autoplay: true, 
		autoplayHoverPause: false,
		autoplayTimeout:4000, 
		autoplaySpeed:1000, 
		dotSpeed: 500,
		loop: true
	};
	constructor(private corretorasService: CorretorasService, private $zone: NgZone) {
		
	}

	ngOnChanges(unused: any) {
		this.refreshBanners();
	}

	ngOnInit() {
		this.refreshBanners();
	}

	get banners() {
		return this._banners || [];
	}

	private refreshBanners() {
		const newbanners = Array.isArray(this.items) ? this.items : [];
		this._banners = this.$zone.runOutsideAngular(() => {
			const output: Banner[] = [];
			newbanners.forEach(element => {
				let src: string = element.image_url;
				let url: string = element.action_url;
				if (!src) {
					src = this.corretorasService.getCorretoraLogo(element.sponsor, true);
				}
				if (element.type === 'route') {	
					const category = element.category || "rendafixa";
					let query = "/?";
					query += url || (element.sponsor ? "corretora=" + element.sponsor : "");
					query += element.asset ? "&tipo=" + element.asset : ""
					url = "/app/investimentos/" + category + query;
				} else {
					if (!url) {
						url = this.corretorasService.getCorretora(element.sponsor).url;
					}
					url = url.indexOf("http") < 0 ? "http://" + url : url;
				}
				output.push({
					src: src,
					url: url
				});
			});
			return output;
		});
	}

	get valid(): boolean {
		if (!this.banners)
			return false;
		return this.banners.length > 0;
	}
}
