<section class="banner-is">
    <div class="banner">
        <div class="b-container">
            <div class="b-content">
                <div class="b-line">
                    <div class="b-col-7">
                        <div class="b-title">
                            <span>{{title}}</span>
                        </div>
                        <div id="invest-banner"></div>
                        <div class="b-subtitle">
                            <span>{{subtitle}}</span>
                            <br>
                            <br>
                            <span>{{subtitle2}}</span>
                        </div>
                    </div>
                    <div class="b-col-4">
                        <app-mini-cards></app-mini-cards>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner responsive">
        <mat-card style="margin-top: 60px;">
            <div class="content">
                <div class="line">
                    <div class="b-col-7">
                        <div class="br-title">
                            <span>Todos os investimentos mostrados aqui são seguros e autorizados pelos órgãos regulamentadores.</span>
                        </div>
                    </div>
                    <div class="b-col-4">
                        <div class="br-image">
                            <img id="invest-banner-mobile" src="/assets/background-images/org-regulamentadores.webp">
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</section>