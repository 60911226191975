<div *ngIf="type == 'talk-to-expert'" class="dialog-wrapper">
  <button [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="btn-dialog-close" mat-button mat-dialog-close="ok">X</button>
  <div [ngClass]="isDarkMode ? 'dark-secondary-color' : ''" mat-dialog-content class="dialog-container">
    <div class="dialog-content">
      <div class="dialog-content-texts">
        <h2 [ngClass]="isDarkMode ? 'dark-primary-font' : ''" mat-dialog-title>Quem te atende é um especialista!</h2>
        <P [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="dialog-paragraph web">
          <strong style="margin: 14px 0; font-size: 16px; display: block">
            Temos uma equipe muito bem preparada para te auxiliar no mundo dos investimentos.
          </strong>
          Como você prefere falar com a gente?
        </P>
        <div class="mobile">
          <p [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="dialog-paragraph mobile">
            Um especialista consegue enxergar oportunidades do cenário macro e
            transformá-las na escolha dos melhores investimentos.
            <br>
            <br>
            Como você prefere falar com a gente?
          </p>
        </div>
      </div>
      <div class="dialog-content-cards">
        <div class="col-6">
        <div class="dialog-card">
          <a class="href-button" target="_blank" rel="noopener" href="mailto:{{email}}">
            <div class="img">
              <img [src]="emailDialogIcon" alt="E-mail">
              <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''">E-mail</span>
            </div>
            <button mat-button class="btn-dialog-talk color-email">Conversar</button>
          </a>
        </div>
        <div class="dialog-card">
          <a class="href-button" target="_blank" rel="noopener" [href]="whatsapp">
            <div class="img">
              <img [src]="whatsappDialogIcon" alt="whatsapp">
              <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''">Whatsapp</span>
            </div>
            <button mat-button class="btn-dialog-talk color-whats">Conversar</button>
          </a>
        </div>
        <div class="dialog-card">
            <a class="href-button" target="_blank" rel="noopener" [href]="telegram">
              <div class="img">
                <img [src]="telegramDialogIcon" alt="telegram">
                <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''">Telegram</span>
              </div>
              <button mat-button class="btn-dialog-talk color-telegram">Conversar</button>
            </a>
          </div>
        </div>
        <p [ngClass]="isDarkMode ? 'dark-primary-font' : ''" style="margin: 0;">ou se preferir ligar</p>
        <div class="col-12">
          <div class="img">
            <img [src]="phoneDialogIcon" alt="telefone">
            <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''">Telefone</span>
          </div>
          <small [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="web">(11) 98882-9851</small>
          <div class="mobile">
            <div class="tel" (click)="toTel()">
              <p [ngClass]="isDarkMode ? 'dark-primary-font' : ''">(11) 98882-9851</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>