 <div class="nh-new-face">
    <div class="nh-welcome">
        <div class="nh-container">
            <div class="nh-toolbar">
            </div>
            <div class="nh-texts">
                <div class="nh-title">
                    <span>{{welcome.title}}</span>
                </div>
                <div class="nh-desc">
                    <div class="responsive-divider"></div>
                    <span>{{welcome.desc}}</span>
                    <span class="responsive">{{welcome.descMobile}}</span>
                </div>
                <div class="nh-subdesc">
                    <span>{{welcome.subdesc}}</span>
                </div>
            </div>
        </div>
        <div id="next" class="nh-btn-start">
            <button (click)="goDown()" mat-raised-button color="accent">COMEÇAR</button>
        </div>
    </div>
</div>