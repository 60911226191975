import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-indicators-overlay',
  templateUrl: './indicators-overlay.component.html',
  styleUrls: ['./indicators-overlay.component.scss']
})
export class IndicatorsOverlayComponent {
  public title: string;
  public _options: {[key: string]: boolean};
  public options: any[];
  public isDarkMode: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<IndicatorsOverlayComponent>,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(MAT_DIALOG_DATA) data: {name: string, options: any, labels: any}
  ) {
    this.title = data.name;
    const options = Object.assign({}, data.options);
    const labels: {[key: string]: {label: string, name: string, provider: string}} = data.labels;
    this._options = options;
    this.options = Object.keys(this._options).reduce((arr, key) => {
    if(isPlatformBrowser(this.platformId)){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.isDarkMode = true;
      else this.isDarkMode = false;  
    }
    else this.isDarkMode = false;

      arr.push({
        get name() {return labels[key].name || "";},
        get label() {return labels[key].label || "";},
        get provider() {return labels[key].provider || "";},
        get checked() {return options[key];},
        set checked(value: boolean) {options[key] = value;},
      });
      return arr.sort((a, b) => {
        const name_a = a.provider + a.name + a.label;
        const name_b = b.provider + b.name + b.label;
        if (name_a === name_b) return 0;
        return name_a > name_b ? 1 : -1;
      });
    }, []); 
  }

  public get canSave(): boolean {
    for (const key in this._options) {
      if (this._options[key]) {
        return true;
      }
    }
    return false;
  }

  public save(): void {
    this.dialogRef.close(this._options);
  }
}
