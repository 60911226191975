<div *ngIf="valid" class="container">
  <app-carousel class="carousel-container"
    [view]="bannersView" [items]="banners" [config]="carouselConfig">
  </app-carousel>
  <ng-template #bannersView let-item let-i="index">
    <div class="banner-container">
      <a [href]="item.url" target="_blank" rel="noopener">
        <img style="border-radius: 8px;" class="img" [src]="item.src" alt="publicidade"/>
      </a>
    </div>
  </ng-template>
  <div *ngIf="banners.length === 1" class="blank"></div>
</div>
