import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {progress: number, abort: () => void, file: File}
  ) { 

  }

  public get progress(): string {
    return (this.data.progress * 100).toFixed(2).replace('.', ',') + '%';
  }

  public get fileName(): string {
    return this.data.file.name;
  }

  public close(): void {
    this.data.abort();
    this.dialogRef.close();
  }
}
