<section class="sabe-como-investir">
    <div class="sci-container">
        <div class="sci-header">
            <mat-card class="sci-card">
                <div class="sci-title">
                    <span>Já sabe como investir? Comece por aqui</span>
                </div>
                <div class="sci-search">
                    <mat-form-field class="sci-form" appearance="outline">
                        <mat-icon style="margin-top: -8px" matPrefix>monetization_on_outlined</mat-icon>
                        <input
                            maxlength="16"
                            currencyMask
                            [options]="{prefix:'R$ ', suffix:' ', align:'left'}" 
                            matInput
                            [(ngModel)]="valinv"
                            placeholder="Quanto você quer investir?">
                    </mat-form-field>
                    <button class="sci-btn-search" (click)="invest(valinv)" mat-raised-button color="accent">
                        Pesquisar
                        <mat-icon style="font-size: 20px; margin-right: -10px; margin-top: 2px;">arrow_forward</mat-icon>
                    </button>
                </div>
            </mat-card>
        </div>

        <div class="sci-body">
            <div class="sci-line">
                <div *ngIf="loaded" class="col-5">
                    <owl-carousel-o [options]="carouselOptionsImageMobile">
                        <ng-container *ngFor="let item of mobileSlide" class="sci-img">
                            <ng-template carouselSlide>
                                <img [src]="item.image">
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <div class="col-7">
                    <div class="sci-texts">
                        <div class="sci-title">
                            <span>{{sciBodyTitle}}</span>
                        </div>
                        <div class="sci-desc">
                            <span>{{sciBodySubTitle}}</span>
                        </div>
                        <div class="sci-stores">
                            <span>BAIXE O APP</span>
                            <div class="sci-play">
                                <a href="http://bit.ly/apprendafixaios"><img src="/assets/icons/app-store.svg" alt="apple store"></a>
                                <a href="http://bit.ly/apprendafixaandroid"><img src="/assets/icons/play-store.svg" alt="play store"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- PATROCINADORES CAROUSEL-->
        <div class="new-title">
            <span class="new-title-description">Nossos parceiros</span>
            <span class="new-title-description">em destaque</span>
            <div class="blue-line"></div>
        </div>
        <div class="fix-responsive">
            <owl-carousel-o *ngIf="loaded" [options]="carouselOptions">
                <ng-container *ngFor="let card of cardsParceiros">
                    <ng-template carouselSlide [width]="240">
                        <mat-card class="cards-patrocinado">
                            <img [src]="card.imagem">
                        </mat-card>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</section>