import { CanActivateChild, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Inject} from '@angular/core';

import { UserService } from './user.service';
import { RouterStateService } from './router-state.service';

@Injectable()
export class IsUserLoggedGuardService implements CanActivateChild {
    constructor(
        private userService: UserService,
        private router: Router,
        private routerStateService: RouterStateService,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private document: any
        ) {   
        }

    canActivateChild (e) {
        const userToken = this.userService.token;
        if (typeof userToken === "string") {
            return true;
        } else {
            this.redirectByPath(e);
            return false;
        }
    }

    private redirectByPath(obj){
        if(obj && obj._routerState && obj._routerState.url) this.router.navigateByUrl('/usuario/login?returnTo=' + obj._routerState.url);
        else this.router.navigate(['/usuario/login'])
    }
}
