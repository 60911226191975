<li class="menu-level-0" *ngIf="!isHidden">
  <div class="item" [class.open]="!isClosed()">
    <div *ngIf="routerLinkUrl; then routerLinkA else hrefA"></div>

    <ng-template #routerLinkA>
      <a 
        mat-button 
        [routerLink]="routerLinkUrl" 
        (click)="closeSidemenu(title)"
        [class.active]="routerLinkUrl === location.path()" 
        [class.no-route]="!routerLinkUrl" 
        [class.subitem]="submenuHandle">
        <div class="icon">
          <ng-content select="[item-icon]"></ng-content>
        </div>
        <div class="text">{{title}}</div>
      </a>
    </ng-template>

    <ng-template #hrefA>
      <a 
        style="cursor: pointer;" 
        [target]="action ? '_self' : '_blank'" 
        rel="noopener" 
        mat-button
        [class.active]="href === location.path()" 
        [class.no-route]="!href" 
        [class.subitem]="submenuHandle"
        (click)="closeSidemenu(title)">
        <div class="icon">
          <ng-content select="[item-icon-href]"></ng-content>
        </div>
        <div class="text">{{title}}</div>
        <i *ngIf="isMenu" class="material-icons mat-dark dropdown">arrow_drop_down</i>
      </a>

    </ng-template>
  </div>

  <div class="badge">
    <ng-content select="[item-attribute]" #badge></ng-content>
  </div>

  <!-- <ul [@closeState]="closeState" class="children" #children>
    <ng-content select="px-gdn-sideitem"></ng-content>
  </ul> -->
</li>
