import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';

@Component({
  selector: 'app-assista-video',
  templateUrl: './assista-video.component.html',
  styleUrls: ['./assista-video.component.scss']
})
export class AssistaVideoComponent {

  public carouselCommits: OwlOptions = {
    dots: true,
    nav: false,
    items: 1,
  }
  private path: string = '/assets/background-images/';
  public loaded: boolean = false;
  public iconStar: string = this.path + 'icon-star.svg';
  public iconQuot: string = this.path + 'icon-quot.svg';
  public cardsPortaisNoticias = [
    {
      imagem: './../../../../assets/icons/new-home-icons/logo_valor.png', // changed to black friday
      titulo: 'Valor Econômico Para quem quer aplicar ao invés de gastar.',
      link:'https://valor.globo.com/financas/noticia/2018/11/29/plataforma-on-line-ajuda-a-garimpar-melhores-opcoes.ghtml'
    },
    {
      imagem: './../../../../assets/icons/new-home-icons/logo_yahoo.png', // changed to black friday
      titulo: 'Yahoo Finanças Com a chegada do fim de ano.',
      link: 'https://br.financas.yahoo.com/noticias/como-equilibrar-os-gastos-de-natal-com-despesas-de-inicio-de-ano-153431632.html'
    },
    {
      imagem: './../../../../assets/icons/new-home-icons/logo_hed.png', // changed to black friday
      titulo: 'Hoje em dia início de ano é o momento ideal.',
      link: 'https://www.hojeemdia.com.br/primeiro-plano/turbine-seu-dinheiro-consumidor-deve-estar-atento-%C3%A0-incid%C3%AAncia-do-ir-e-de-taxas-antes-de-investir-1.691070'
    },
    {
      imagem: './../../../../assets/icons/new-home-icons/logo_uol.png', // changed to black friday
      titulo: 'UOL O Ibovespa chegou a acumular alta de 11,8% neste ano até.',
      link: 'https://economia.uol.com.br/financas-pessoais/noticias/redacao/2019/02/06/onde-investir-em-2019-para-ganhar-mais.htm'
    }
  ]
  public carouselOptions: OwlOptions = {
    dots:false,
    nav:false,
    items: 4
  }
  public commits: any[] = [
    {
      "descTitle":'DÁ UMA OLHADA NO QUE OUTRAS PESSOAS FALARAM DA GENTE',
      "description":'Ótimo aplicativo, ele faz a importação automática do cei, e te da uma demonstração aproximada, devido a algumas variações e divulgações de fundos que não e diária. Agora de vez em quando da uns bugs que a equipe sempre corrige. Meu Bug agora é que toda vez que abro ele importa uma transação que não existe de 2018 em cima de tesouro direto. Eu exclui a importação, porém o aplicativo volta a importar e notificar uma novidade constantemente.',
      "descAuthor":'João Filho Abreu',
      "descSource":'via google play',
      "iconQuot": this.path + 'icon-quot.svg',
      "iconCloseQuot": this.path + 'close-quot.svg',
      "iconStars": [0,1,2,4,5]
    },
    {
      "descTitle":'DÁ UMA OLHADA NO QUE OUTRAS PESSOAS FALARAM DA GENTE',
      "description":'Realmente indica os melhores investimentos em rendafixa, tem o melhor buscador de investimento do Brasil',
      "descAuthor":'Ademir Cavalcanti',
      "descSource":'via google play',
      "iconQuot": this.path + 'icon-quot.svg',
      "iconCloseQuot": this.path + 'close-quot.svg',
      "iconStars": [0,1,2,4,5]
    }
  ]
  constructor() { }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.loaded = true;
    }, 500)
  }
}
