import {Indexable} from './indexable';

export interface ConsolidadoBase {
    valinv: number;
    valbrt?: number;
    valliq: number;
    ir: number;
    iof: number;
    irp: number;
    iofp: number;
    qtd: number;
    tt?: any;
}

export interface Consolidado extends ConsolidadoBase {
    diascorridos: number;
    diasuteis: number;
    rentDia: number;
    rentMes: number;
    rentAno: number;
    rent12Mes?: number;
    rentAcu?: number;
    rentLiqDia: number;
    rentLiqMes: number;
    rentLiqAno: number;
    rentLiq12Mes?: number;
    rentLiqAcu?: number;
}

export interface Dados {
    corretora: string;
    emissor: string;
    nome: string;
    tipo: string;
    // liquidez: boolean;
    liquidez: string;
    incentivado: boolean;
    vencto: Date;
    taxa: number;
    idx: string;
    pu: number;
}

export class Carteira {
    static get Privados() {return "privados";}
    static get Td() {return "tesourodireto";}
    static isTesourodireto(titulo: {carteira: string}) {
        return titulo.carteira === Carteira.Td;
    }
    static isRendafixa(titulo: {carteira: string}) {
        return titulo.carteira !== "robo";
    }
    static get Robo() {return "robo";}
}

export class Titulo implements Dados, Consolidado, Indexable {
    public _id: string;
    public carteira: string;
    public corretora: string;
    public emissor: string;
    public nome: string;
    public tipo: string;
    // public liquidez: boolean;
    public liquidez: string;
    public incentivado: boolean;
    public vencto: Date;
    public taxa: number;
    public idx: string;
    public pu: number;
    public valinv: number;
    public valbrt?: number;
    public valliq: number;
    public ir: number;
    public iof: number;
    public irp: number;
    public iofp: number;
    public tx_prog?: boolean
    public get qtd(): number {
        return this.valinv / this.pu;
    }
    public set qtd(value: number) {
        this.valinv = this.pu * value;
    }
    public diascorridos: number;
    public diasuteis: number;
    public rentDia: number;
    public rentMes: number;
    public rentAno: number;
    public rent12Mes?: number;
    public rentAcu?: number;
    public rentLiqDia: number;
    public rentLiqMes: number;
    public rentLiqAno: number;
    public rentLiq12Mes?: number;
    public rentLiqAcu?: number;
    public nm_url_amigavel?: string;
    public imagem?: string;
    public detalhes?: string;
    public enable_popup?: boolean;
    public url?: string;
    public tt?: any;
    public rentabilidades?: any;
    public escalonado?: any;
    public id_titulo?: Number;
}
