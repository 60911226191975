import { Component, OnDestroy } from '@angular/core';
import { routerAnimation } from '../../utils/page.animation';
import { RequestLoaderService } from './request.loader.service';

@Component({
    selector: 'request-loader',
    templateUrl: './request.loader.html',
    styleUrls: ['./request.loader.scss'],
    animations: [routerAnimation]
})
export class RequestLoaderComponent implements OnDestroy {
    private _trackingProgress: boolean;
    private _value: number;
    private subscription;
    private abortRef: () => void;
    constructor(private spinner: RequestLoaderService) {
        this.subscription = this.spinner.subscribeToTracking(tracking => {
            if (this._trackingProgress != tracking.isTracking)
                this.abortRef = tracking.abort;
            this._value = tracking.progress;
            this._trackingProgress = tracking.isTracking;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    show() {
        this.spinner.show();
    }

    hide(result?: string, action?: string) {
        this.spinner.hide(result, action);
    }

    get loading(): boolean {
        return this.spinner.loading;
    }

    get spinnerMode(): string {
        return this._trackingProgress ? "determinate" : "indeterminate";
    }

    get value(): number {
        return this._value ? this._value * 100 : undefined;
    }

    get canAbort(): boolean {
        return !!this.abortRef;
    }

    abort(): void {
        if (this.canAbort) {
            this.abortRef();
        }
    }
}