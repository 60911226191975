import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CustomFormsModule} from 'ng2-validation';
import {HttpClientModule} from '@angular/common/http';
import {DirectivesModule} from './../directives/directives.module';
import {ProvidersModule} from './../providers/providers.module';
import {CarouselModule} from 'ngx-owl-carousel-o';

import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {ComponentsModule} from '../components/components.module';
import {VirtualScrollModule} from 'angular2-virtual-scroll';
import { PipesModules } from '../pipes/pipes.modules';
import { MainPageComponent } from './main-page/main-page.component';

import { HomeComponent } from './home/home.component';
import { ButtonTemplateComponent } from './home/index-component/button-template/button-template.component';
import { NewIndicatorsComponent } from './home/index-component/new-indicators/new-indicators.component';
import { GraphicChartComponent } from './home/index-component/graphic-chart/graphic-chart.component';
import { CalculatorPopUpDialogComponent } from './home/index-component/calculator-pop-up-dialog/calculator-pop-up-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { BemVindoComponent } from './bem-vindo/bem-vindo.component';
import { AssistaVideoComponent } from './bem-vindo/components/assista-video/assista-video.component';
import { BannerInvestimentoSeguroComponent } from './bem-vindo/components/banner-investimento-seguro/banner-investimento-seguro.component';
import { CarouselOngComponent } from './bem-vindo/components/carousel-ong/carousel-ong.component';
import { DialogWhatisComponent } from './bem-vindo/components/dialog-whatis/dialog-whatis.component';
import { FormSimulateComponent } from './bem-vindo/components/form-simulate/form-simulate.component';
import { HowtoComponent } from './bem-vindo/components/howto/howto.component';
import { MainWelcomeComponent } from './bem-vindo/components/main-welcome/main-welcome.component';
import { MiniCardsComponent } from './bem-vindo/components/mini-cards/mini-cards.component';
import { SabeComoInvestirComponent } from './bem-vindo/components/sabe-como-investir/sabe-como-investir.component';


registerLocaleData(localePt, 'pt-BR');

@NgModule({
    declarations: [
        MainPageComponent,
        HomeComponent,
        BemVindoComponent,
        ButtonTemplateComponent,
        NewIndicatorsComponent,
        GraphicChartComponent,
        CalculatorPopUpDialogComponent,
        AssistaVideoComponent,
        BannerInvestimentoSeguroComponent,
        CarouselOngComponent,
        DialogWhatisComponent,
        FormSimulateComponent,
        HowtoComponent,
        MainWelcomeComponent,
        MiniCardsComponent,
        SabeComoInvestirComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        CustomFormsModule,
        HttpClientModule,
        RouterModule,
        ProvidersModule,
        DirectivesModule,
        ComponentsModule,
        VirtualScrollModule,
        PipesModules,
        MatIconModule,
        CarouselModule
    ],
    exports: [
        ComponentsModule,
        MainPageComponent,
    ],
    providers: []
})
export class PagesModule {
}
