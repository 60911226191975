import { Component, HostBinding, OnInit, NgZone, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RendafixaService } from './providers/rendafixa.service';
import { CorretorasService } from './providers/corretoras.service';
import { EmissoresService } from './providers/emissores.service';
import { FacebookService } from './providers/facebook.service';
import { LocalStorageService } from './providers/localstorage.service';
import { OnesignalService } from './providers/onesignal.service';
import { HelpMeService } from './components/help-me/help-me.service';
import { Constants } from './providers/constants';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @HostBinding('class.loading') loading = true;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private rendafixaService: RendafixaService,
    private corretorasService: CorretorasService,
    private emissoresService: EmissoresService,
    private facebookService: FacebookService,
    private localStorageService: LocalStorageService,
    private onesignalService: OnesignalService,
    private helpMeService: HelpMeService,
    private constants: Constants,
    private $zone: NgZone,
    private ngZone: NgZone,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: string
  ) {

    this.$zone.runOutsideAngular(() => {
      this.injectIcons();
    });
    this.loading = false;
    this.matIconRegistry.addSvgIcon(
      "posicao",
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/badge-10.svg')
    );
  }

  ngOnInit(): void {
    this.$zone.runOutsideAngular(() => {
      console.group('%cBem-vindo ao aplicativo Renda Fixa', "color: #2196F3;");
      console.log('O seu assessor de investimentos na ponta dos dedos.');
      if (!this.constants.env.production) console.log('Ambiente de teste');
      console.group('Links:');
      console.log('%cFacebook:', 'font-weight: bold;', 'https://www.facebook.com/apprendafixa');
      console.log('%cInstagram:', 'font-weight: bold;', 'https://www.instagram.com/rendafixa');
      console.log('%cYoutube:', 'font-weight: bold;', 'https://www.youtube.com/apprendafixa');
      console.groupEnd();
      console.log('Criado por %cWatermelon Agency.', "color: white; font-style: italic; background-color: #E91E63;padding: 2px", 'Todos os direitos reservados.');
      console.groupEnd();
    });

    this.$zone.runOutsideAngular(() => {
      this.localStorageService.setItem('origin', 'web');
      this.rendafixaService.getInitObject().then((result) => {
        this.corretorasService.__getCorretoras(result);
        this.emissoresService.__getEmissores(result);
      });
      if(isPlatformBrowser(this.platformId)){
        this.facebookService.inject();
        this.onesignalService.inject();
      }
    });
  }

  private injectIcons(): void {
    this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    this
      .addSvgIcon("acoes")
      .addSvgIcon("adicionarInvestimento")
      .addSvgIcon("adicionarInvestimentoAzul")
      .addSvgIcon("adicionarInvestimentoMain")
      .addSvgIcon("automatizarInvestimento")
      .addSvgIcon("alerta")
      .addSvgIcon("alerta-mute")
      .addSvgIcon("android")
      .addSvgIcon("automatizarInvestimentoMaior")
      .addSvgIcon("ativo real")
      .addSvgIcon("bate-papo")
      .addSvgIcon("blog")
      .addSvgIcon("caixaentrada")
      .addSvgIcon("calculadoracidadao", "cidadao", "calc")
      .addSvgIcon("calculadoraconversordetaxas", "taxas", "calc")
      .addSvgIcon("calculadoramilhao", "milhao", "calc")
      .addSvgIcon("calculadoraprevspos", "prepos", "calc")
      .addSvgIcon("calculadorarendafixa", "rf", "calc")
      .addSvgIcon("calculadoras")
      .addSvgIcon("calc-main-icon")
      .addSvgIcon("calculadorataxasequivalentes", "taxaseq", "calc")
      .addSvgIcon("calculadoratesourodireto", "tesouro", "calc")
      .addSvgIcon("cambio")
      .addSvgIcon("carteira")
      .addSvgIcon("carteiraFiltro")
      .addSvgIcon("criptomoedas")
      .addSvgIcon("cdb")
      .addSvgIcon("ccb")
      .addSvgIcon("closeDialog")
      .addSvgIcon("coe")
      .addSvgIcon("comparador-de-fundos")
      .addSvgIcon("completed")
      .addSvgIcon("configuracoes")
      .addSvgIcon("cra")
      .addSvgIcon("cri")
      .addSvgIcon("criarObjetivo")
      .addSvgIcon("debenture")
      .addSvgIcon("deletarObjetivo")
      .addSvgIcon("desconectar")
      .addSvgIcon("dpge")
      .addSvgIcon("duvidasfrequentes")
      .addSvgIcon("email")
      .addSvgIcon("email-color")
      .addSvgIcon("editarObjetivo")
      .addSvgIcon("exportar")
      .addSvgIcon("facebook")
      .addSvgIcon("falecomumassessor")
      .addSvgIcon("fiis")
      .addSvgIcon("filtrar")
      .addSvgIcon("filtrar-white")
      .addSvgIcon("fidc")
      .addSvgIcon("forum")
      .addSvgIcon("funcoesCarteira")
      .addSvgIcon("fundosDeInvestimento")
      .addSvgIcon("icon-avatar-88")
      .addSvgIcon("icone-ranking")
      .addSvgIcon("indicadores")
      .addSvgIcon("instagram")
      .addSvgIcon("instituicoes")
      .addSvgIcon("investimentoProgramado")
      .addSvgIcon("investimentoP2p")
      .addSvgIcon("investimentos")
      .addSvgIcon("investirpeloapp")
      .addSvgIcon("planejamentofinanceiro")
      .addSvgIcon("ios")
      .addSvgIcon("lc")
      .addSvgIcon("lca")
      .addSvgIcon("lci")
      .addSvgIcon("lig")
      .addSvgIcon("lf")
      .addSvgIcon("limpar")
      .addSvgIcon("local_grocery_store-24px")
      .addSvgIcon("logodeitadoazul")
      .addSvgIcon("logodeitadobranco")
      .addSvgIcon("loja")
      .addSvgIcon("meliuz-badge")
      .addSvgIcon("minhacarteira")
      .addSvgIcon("notCompleted")
      .addSvgIcon("noticias")
      .addSvgIcon("pequeno_rufino")
      .addSvgIcon("percent_pink")
      .addSvgIcon("perfilvazio")
      .addSvgIcon("perfil-basico", "basico", "perfil")
      .addSvgIcon("perfil-completo", "completo", "perfil")
      .addSvgIcon("perfil-interesses", "interesses", "perfil")
      .addSvgIcon("perfil-suitability", "suitability", "perfil")
      .addSvgIcon("perfil-de-investidor")
      .addSvgIcon("politicadeprivacidade")
      .addSvgIcon("poupanca")
      .addSvgIcon("previdencia")
      .addSvgIcon("rdb")
      .addSvgIcon("rendaFixaPrivada")
      .addSvgIcon("rendaFixaPublicos")
      .addSvgIcon("robosDeInvestimento")
      .addSvgIcon("school")
      .addSvgIcon("servicos")
      .addSvgIcon("star", "patrocinado")
      .addSvgIcon("startUp")
      .addSvgIcon("termosdeuso")
      .addSvgIcon("tesourodireto")
      .addSvgIcon("titulo-badge-tx_prog")
      .addSvgIcon("titulo-badge-lft")
      .addSvgIcon("titulo-badge-imovel")
      .addSvgIcon("titulo-badge-qualificado")
      .addSvgIcon("titulo-badge-liquidez-diaria")
      .addSvgIcon("titulo-badge-isento-de-ir")
      .addSvgIcon("titulo-badge-fgc")
      .addSvgIcon("titulo-badge-patrocinado")
      .addSvgIcon("todos")
      .addSvgIcon("whatsapp")
      .addSvgIcon("whatsapp-color")
      .addSvgIcon("local_grocery_store-24px")
      .addSvgIcon("loja")
      .addSvgIcon("icon-avatar-88")
      .addSvgIcon("youtube")
      .addSvgIcon("four-squares")
      .addSvgIcon("green-totem")
      .addSvgIcon("pink-interwined")
      .addSvgIcon("pink-wave")
      .addSvgIcon("shockwave")
      .addSvgIcon("prize")
      .addSvgIcon("b-symbol");
  }

  private addSvgIcon(name: string, alias?: string, namespace?: string): this {
    const domain = isPlatformBrowser(this.platformId)? '' : 'http://127.0.0.1:4000/'
    const path = this.sanitizer.bypassSecurityTrustResourceUrl(domain + "assets/icons/" + name + ".svg");
    alias = alias ? alias : name;
    if (namespace)
      this.matIconRegistry.addSvgIconInNamespace(namespace, alias, path);
    else
      this.matIconRegistry.addSvgIcon(alias, path);
    return this;
  }
}
