import {Injectable} from '@angular/core';
import {Subject, Subscription, Observer} from 'rxjs';
import {Router, NavigationEnd, GuardsCheckEnd} from '@angular/router';
import { Location } from '@angular/common';


export interface NavigationEndState extends NavigationEnd {
    previousUrl?: string;
}

@Injectable()
export class RouterStateService {
    private static _navigationEnd = new Subject<NavigationEndState>();
    private static _url: string[] = [];
    private static _currentUrl: string = null;
    private static redirected = false;
    private pathName = '';
    constructor(
        private _router: Router,
        private location: Location
    ) {
        this.router.events.subscribe(event => { 
            if (event instanceof NavigationEnd) {
                if (!RouterStateService.redirected) {
                    RouterStateService._url.push(RouterStateService._currentUrl);
                }
                RouterStateService.redirected = false;
                RouterStateService._currentUrl = event.url;
                RouterStateService._navigationEnd.next(Object.assign({previousUrl: this.previousUrl}, event));
            }
        });
    }

    public updatePathName (newPathName) {
        this.pathName = newPathName;
    }

    public getPathName () {
        return this.pathName
    }

    public get router(): Router {
        return this._router;
    }

    public static get history(): string[] {
        return RouterStateService._url || [];
    }

    private static get _previousUrl(): string | null {
        const history = RouterStateService.history;
        const p = history.length - 1;
        return p < 0 ? null : history[p] || null;
    }

    private extractPreviousUrl(): string | null {
        const url = RouterStateService.history.pop() || null;
        RouterStateService._currentUrl = this.previousUrl;
        return url;
    }

    public get currentUrl(): string {
        return RouterStateService._currentUrl;
    }

    public get previousUrl(): string {
        return RouterStateService._previousUrl;
    }

    public static get urlState() {
        return {current: RouterStateService._currentUrl, previous: RouterStateService._previousUrl};
    }

    public back(url?: string): string {
        if (url && url.length) {
            this.router.navigateByUrl(url);
        } else {
            url = this.extractPreviousUrl();
            if (!url) {
                this.location.back();
            } else {
                this.router.navigateByUrl(url);
            }
        }
        return url || null;
    }

    public redirect(url: string | any[], extra?: any): Promise<boolean> {
        RouterStateService.redirected = true
        if (Array.isArray(url)) {
            return this.router.navigate(url, extra);
        } else {
            return this.router.navigateByUrl(url, extra);
        }
    }

    public static parseUrl(url: string): {
        href: string,
        protocol: string,
        hostname: string,
        host: string,
        port: string,
        pathname: string,
        search: string,
        hash: string
    } {
        const l = document.createElement("a");
        l.href = url;
        return l;
    }

    public static isLoginPage(url: string): boolean {
        const path = RouterStateService.parseUrl(url).pathname || "";
        const search = path.endsWith("/") ? "/login/" : "/login";
        return path.endsWith(search);
    }

    public navigationEndSubscribe(callback: (event: NavigationEndState) => void | Observer<NavigationEndState>) {
        return RouterStateService.navigationEndSubscribe(callback);
    }

    public static navigationEndSubscribe(callback: (event: NavigationEndState) => void | Observer<NavigationEndState>) {
        return this._navigationEnd.subscribe(callback);
    }
}
