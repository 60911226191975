
<div class="new-graphic-template">
  <div [ngClass]="darkMode ? 'dark-secondary-color' : ''" class="graphic-box-content">
    <div class="graphic-header">
      <div class="left-info">
        <span [ngClass]="darkMode ? 'dark-primary-font' : ''" class="new-title-description">Índices</span>
        <div class="blue-line"></div>
      </div>
      <div class="right">
        <app-datepicker [disabled]="!isReady" required [darkMode]="darkMode"
        [max]="maxStartDate" [min]="minDate" [default]="startDate" (valueChange)="onMinDateChange($event)"
        [placeholder]="'Data de início'" #datepickerStart>
        </app-datepicker>
        <app-datepicker [disabled]="!isReady" required [darkMode]="darkMode"
        [max]="endDate" [min]="minEndDate" [default]="endDate" (valueChange)="onMaxDateChange($event)"
        [placeholder]="'Data de fim'" #datepickerEnd>
        </app-datepicker>
      </div>
    </div>
    <app-highchart *ngIf="isHighcharts" class="line-chart" [chartOptions]="options" (onRefresh)="push($event)"></app-highchart>
  </div>
</div>