import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MongeralService {
  private api = "https://api-test.apprendafixa.com.br";
  constructor(
    private http: HttpClient,
    private constant: Constants
  ) { }

  public sendLeadsToServer(leads){
    return this.http.post(this.api + '/previdencia/send_form_data', leads).toPromise()
  }
}
