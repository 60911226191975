import { isPlatformBrowser } from '@angular/common';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  if (isPlatformBrowser) {
    if(window) window.console.log = window.console.warn = window.console.info = function () { };
  }
}



function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => {
    console.log(err);
  });
};

if(isPlatformBrowser){
  if (document.readyState === 'complete') {
    bootstrap();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrap);
  }
}


