import {Component, OnInit, Input, AfterViewInit, HostListener, Inject, PLATFORM_ID} from '@angular/core';
import { SidenavService } from '../sidemenu/sidenav.service';
import { AssessorService } from '../assessor/assessor.service';
import { UserService } from '../../providers/user.service';
import { Platform } from '@angular/cdk/platform';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {fromEvent} from 'rxjs';
8	
import {distinctUntilChanged, map, shareReplay, startWith} from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Connector } from 'app/providers/connector';

export interface ToolbarButton {
  onClick: (Event) => void,
  text: string,
  svgIcon?: string,
  svgClass?: any,
  mdIcon?: string,
  disabled?: boolean
}

export interface NoToolbar {
	noToolbarFlag: boolean;
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, AfterViewInit {;
  @Input("buttons") _buttons: ToolbarButton[];
  @Input("menuHidden") _areHidden: boolean;
  @Inject(DOCUMENT) private document: Document
  private static _fixedPopupWasShow: boolean = false;
  private static _fixedPopupIsHidden: boolean = true;
  public activeInvest: boolean;
  public activeEducations: boolean;
  public activeFerramentas: boolean;
  public menuHeader: boolean = true;
  public isLogin: boolean = false;
  public linkActivate: string = 'link-activate';
  public activeClass: boolean;
  public showToolBarBackground = false;
  public showStores = true;
  public isDarkMode: boolean = false;
  public subs: any;
	public windowScroll$;
  constructor(
    private sidenavService: SidenavService,
    private assessorService: AssessorService,
    private userService: UserService,
    private router: Router,
    private connector: Connector,
    public platform: Platform,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if(isPlatformBrowser(this.platformId)){
      this.windowScroll$ = fromEvent(window, 'scroll')
      .pipe(
        map(x => window.scrollY),
        startWith(0),
        distinctUntilChanged(),
        shareReplay(1)
      );
      this.isLogin = this.userService.user.logged;
      if (this.router.url == '/app/bem-vindo') {
        this.activeClass = true;
        this.showStores = false;
        this.windowScroll$.subscribe(scroll => {
          if(window.screen.width < 600){
            if (scroll >= 30) {
              this.showToolBarBackground = true;
            } else {
              this.showToolBarBackground = false;
            }
          }
          else{
            if (scroll >= 100) {
              this.showToolBarBackground = true;
            } else {
              this.showToolBarBackground = false;
            }
          }
        })
      } else {
        this.showToolBarBackground = true;
      }
    }
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.isDarkMode = true;
      else this.isDarkMode = false;  
    }
    else this.isDarkMode = false;
    this.router.events.subscribe(event => {
      this.showToolBarBackground = true;
      this.showStores = true;
    })
  }

  ngAfterViewInit(): void {
    if (!ToolbarComponent._fixedPopupWasShow) {
      ToolbarComponent._fixedPopupWasShow = true;
      setTimeout(() => {
        this.showFixedPopup(12000);
      }, 60);
    }
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public toggleSidenav($event) {
    try{
      $event.srcElement.parentElement.blur();
    } catch(err) { }
    return this.sidenavService.toggle();
  }
  public get buttons(): ToolbarButton[] {
    return this._buttons || [];
  }
  public get shouldShowButtons(): boolean {
    return this.buttons.length && this._areHidden !== true;
  }
  public get fixedPopupIsHidden(): boolean {
    return ToolbarComponent._fixedPopupIsHidden;
  }
  public showFixedPopup(fadoutAtMs?: number): void {
    if (!ToolbarComponent._fixedPopupIsHidden) return;
    ToolbarComponent._fixedPopupIsHidden = false;
    if (!fadoutAtMs) fadoutAtMs = 7000;
    setTimeout(() => {
      this.closeFixedPopup();
    }, fadoutAtMs);
  }
  public closeFixedPopup(): void {
    ToolbarComponent._fixedPopupIsHidden = true;
  }
  public isOpened(){
    this.activeInvest = true;
  }
  public isClosed(){
    this.activeInvest = false;
  }
  public isActiveEducations(){
    this.activeEducations = true;
  }
  public disactiveEducations(){
    this.activeEducations = false;
  }
  public isActiveFerramentas(){
    this.activeFerramentas = true;
  }
  public disactiveFerramentas(){
    this.activeFerramentas = false;
  }
  public openAssessorDialog() {
    this.assessorService.openDialog()
  }

  public openExternally(url): void {
    this.connector.openInNewTab(url);
  }

  goHome(){
    this.router.navigateByUrl('/')
  }
}
