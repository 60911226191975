import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountriesService } from 'app/providers/corretoras.service';
import { UserService } from 'app/providers/user.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RequestLoaderService } from '../request.loader/request.loader.service';

@Component({
  selector: 'app-advisory-call-card',
  templateUrl: './advisory-call-card.component.html',
  styleUrls: ['./advisory-call-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdvisoryCallCardComponent implements OnInit {

  /**
   * Instrução do preenchimento dos campos medium e campaign:
   * 
   * Valores possíveis para o utm_medium: "Card Investimento", "Calculadora", "Suitability", "LP", "Home", "Ranking", "Fundos"
   * Valores possíveis para o utm_campaign quando o utm_medium for "Card Investimento": "Renda Fixa", "Renda Variável", "Fundos"
   * Valores possíveis para o utm_campaign quando o utm_medium for "Calculadora": "DI", "Tesouro Direto", "Cidadão", "Milhão", "Renda Fixa", "Pré-Pós"
   * Valores possíveis para o utm_campaign quando o utm_medium for "Suitability": "Suitability"
   * Valores possíveis para o utm_campaign quando o utm_medium for "LP": "Assessoria Gratuita"
   * Valores possíveis para o utm_campaign quando o utm_medium for "Home": "Home"
   * Valores possíveis para o utm_campaign quando o utm_medium for "Ranking": "Ranking"
   * Valores possíveis para o utm_campaign quando o utm_medium for "Fundos": "Comparador"
   * 
   */

  @Input() darkMode: boolean = false;
  @Input() medium: string;
  @Input() campaign: string;
  public form: FormGroup;
  public countries
  public errorMessage = "Telefone inválido";
  public countryMask = '(00) 00000-0000||(00) 0000-0000';
  public phoneVerified = false;
  public phoneTouched = false;
  public verifyingPhone = false;
  public countryLength = 10;
  public answerGiven = false;
  public matcher = {
    isErrorState: () => {
      return this.phoneTouched && !this.phoneVerified;
    }
  }
  constructor(
    private _countriesService: CountriesService,
    private _spinner: RequestLoaderService,
    private _userService: UserService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      nome: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      ddi: new FormControl(55, [Validators.required]),
      pais: new FormControl("Brasil", [Validators.required]),
      telefone: new FormControl('', [Validators.required]),
      utm_medium: new FormControl(this.medium, [Validators.required]),
      utm_campaign: new FormControl(this.campaign, [Validators.required]),
    });
    let localCountries = localStorage.getItem('listaPaises');
    if(localCountries === null){
      this._countriesService.getDdi().subscribe((val) => {
        this.countries = val["ddi"];
        this.countries.sort((a,b) => a.ddi > b.ddi ? 1 : -1);
        localStorage.setItem("listaPaises", JSON.stringify(this.countries));
      }, err => {
        this._spinner.hide("Erro ao carregar os códigos dos países.");
      });
    }
    else{
      this.countries = JSON.parse(localCountries);
    }
    if(!this.countries) this._spinner.hide("Erro: os códigos dos países não carregaram.");

    this.form.get('telefone')
    .valueChanges.pipe().subscribe(val => {
      this.phoneVerified = false;
      this.phoneTouched = true;
    })
    this.form.get('telefone')
    .valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(val => {
      if(!val || val['length'] < this.countryLength || !this.form.get('pais').value) return;
      else this.initiatePhoneVerification(val, this.form.get('pais').value);
    });
  }

  ngOnChanges(){
    if(this.form && this.form.value['utm_campaign'] != this.campaign) this.form.get("utm_campaign").setValue(this.campaign);
  }

  setThis(e, country){
    this.phoneVerified = false;
    this.verifyingPhone = false;
    this.phoneTouched = false;
    this.countryMask = country['ddi'] != 55 ?'0000000000' : '(00) 00000-0000||(00) 0000-0000';
    this.countryLength = country['ddi'] != 55 ? 8 : 10;
    this.form.get("pais").setValue(country['pais']);
    this.form.get("ddi").setValue(country['ddi']);
    this.form.get("telefone").setValue(undefined);
  }

  initiatePhoneVerification(phoneNumber: string, country: string){
    this.verifyingPhone = true;
    this.form.get("telefone").disable();
    this.form.get("ddi").disable();
		this._userService.checkUserCaptcha().then(()=>{
      this._userService.verifyPhone(phoneNumber, country).then(val => {
        /**
         * O timeout é pra evitar spam de verificação de telefone.
         */
        setTimeout(() => {
          this.form.get("telefone").enable();
          this.form.get("ddi").enable();
          this.verifyingPhone = false;
          this.phoneVerified = true;
        }, 3000);
      }).catch(err => {
        if(err && err['error'] && err['error']['Message']){
          this.form.get("telefone").enable();
          this.form.get("ddi").enable();
          this.phoneVerified = false;
          this.verifyingPhone = false;
          this.errorMessage = err['error']['Message'];
        }
        else {
          this.form.get("telefone").enable();
          this.form.get("ddi").enable();
          this.phoneVerified = false;
          this.verifyingPhone = false;
          this._spinner.hide("Erro ao verificar o telefone.");
        }
      })
    })
  }

  invest(){
    this._spinner.show();
    this._userService.investPremium(this.form.value).then(resp => {
      this.answerGiven = true;
      this._spinner.hide();
    }).catch(err => {
      this._spinner.hide("Erro ao enviar informações: " + err['error']['Message'], "OK");
    });
  }
}
