import {Injectable} from '@angular/core';

export type JsonValid = object | Array<any> | string | null | number | boolean;

@Injectable()
export class LocalStorageService {
    getItem (key: string) : string {
        return localStorage.getItem(key);
    }

    setItem (key: string, data: string) : void {
        return localStorage.setItem(key, data);
    }

    get (key: string, isCache?: boolean) : any {
        if (isCache) {
            key = "$cache$." + key;
        }
        let tmp = this.getItem(key);
        if (tmp === undefined || tmp === "undefined") {
            return undefined;
        }
        try {
            return JSON.parse(tmp);
        } catch (err) {
            return undefined;
        }
    }

    set (key: string, data: JsonValid, isCache?: boolean): any {
        let _data;
        if (isCache) {
            key = "$cache$." + key;
        }
        try {
            _data = JSON.stringify(data);
        } catch (err) {
            _data = data;
        }
        this.setItem(key, _data);
        return data;
    }

    clear () {
        return localStorage.clear();
    }

    delete (key: string) {
        this.setItem(key, "null");
    }
}
