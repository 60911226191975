<div class="align-in-center" [ngClass]="isDarkMode ? 'dark-secondary-color' : ''" mat-dialog-content>
    <h3 [ngClass]="isDarkMode ? 'dark-primary-font' : ''" mat-dialog-title>{{title}}</h3>
    <div fxLayout="column">
      <mat-checkbox [ngClass]="isDarkMode ? 'dark-primary-font' : ''" color="accent" class="checkbox" [(ngModel)]="option.checked" *ngFor="let option of options">
        <span class="provider" *ngIf="option.provider">{{option.provider}}: </span>
        {{option.name}} {{option.label}}
      </mat-checkbox>
    </div>
</div>
<div [ngClass]="isDarkMode ? 'dark-secondary-color' : ''" mat-dialog-content class="footer align-in-center">
    <div mat-dialog-actions>
        <button mat-button [ngClass]="isDarkMode ? 'dark-primary-font' : ''" mat-dialog-close>Fechar</button>
        <button mat-button [ngClass]="isDarkMode ? 'dark-primary-font' : ''" (click)="save()" [disabled]="!canSave">Salvar</button>
    </div>
</div>