import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-investimento-seguro',
  templateUrl: './banner-investimento-seguro.component.html',
  styleUrls: ['./banner-investimento-seguro.component.scss']
})
export class BannerInvestimentoSeguroComponent implements OnInit {
  public title: string = 'Apenas investimentos seguros';
  public subtitle: string = 'O App Renda Fixa segue as regulamentações da CVM e nossos economistas são especialistas em investimentos.';
  public subtitle2: string = 'Os investimentos mostrados aqui são seguros e autorizados pelos órgãos regulamentadores.'
  
  constructor() { }

  ngOnInit() {
  }

}
