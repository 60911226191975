import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InvestService } from './invest.service';
import { RouterStateService } from './router-state.service';
import { Api, Connector } from './connector';
import { CalculatorService } from './calculator.service';
import { Constants } from './constants';
import { CorretorasService, CountriesService } from './corretoras.service';
import { EmissoresService } from './emissores.service';
import { FacebookService } from './facebook.service';
import { FutureService } from './future.service';
import { IndicatorsService } from './indicators.service';
import { InvestmentsService } from './investments.service';
import { LocalStorageService } from './localstorage.service';
import { OnesignalService } from './onesignal.service';
import { RendafixaService } from './rendafixa.service';
import { ScopeService } from './scope.service';
import { SidenavService } from '../components/sidemenu/sidenav.service';
import { UserService } from './user.service';
import { CidadesService } from '../providers/corretoras.service';
import { FundosService } from '../providers/fundos.service';
import { ChartService } from '../providers/chart.service';
import { RankingService } from '../providers/ranking.service';
import { SeoService } from './seo.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    declarations: []
})
export class ProvidersModule {
    static forRoot(): ModuleWithProviders<ProvidersModule> {
        return {
            ngModule: ProvidersModule,
            providers: [
                ChartService,
                FundosService,
                RankingService,
                Api,
                CalculatorService,
                Connector,
                Constants,
                CorretorasService,
                EmissoresService,
                CountriesService,
                FacebookService,
                FutureService,
                IndicatorsService,
                InvestService,
                InvestmentsService,
                LocalStorageService,
                OnesignalService,
                RendafixaService,
                RouterStateService,
                ScopeService,
                SidenavService,
                UserService,
                CurrencyPipe,
                DatePipe,
                CidadesService,
                SeoService
            ]
        };
    }
}

