import {Subscription, Subject} from 'rxjs';

interface Subscrable {
    subscribe(next?: callback<any>, error?: callback<any>, complete?: () => void): Subscription;
}

type callback<T> = (T) => void;

export interface Destroyable {
    destroy(): void;
}

export interface Future<T> extends Destroyable {
    destroy(): Future<T>,
    cycle(callback: callback<T>): Future<T>,
    error(callback: callback<Error>): Future<T>,
}

export class FutureFactory<T> implements Future<T> {
    private _subscription: Subscription;
    private __callmeback: boolean = false;
    private __callmebackParam: any;
    constructor(
        _subject: Subscrable,
        private _cycle?: callback<T>,
        private _error?: callback<Error>,
        complete?: () => void
    ) {
        this._subscription = _subject.subscribe(this.__next, this.__error, complete);
    }

    private __error(error: any): void {
        if (!this._error)
            return;
        if (error === null || error === undefined)
            error = "";
        if (!(error instanceof Error))
            error = new Error(error.toString());
        this._error(error);
    }

    private __next = (value: any): void => {
        if (this._cycle) {
            this._cycle(value);
            this.__callmeback = false;
        } else {
            this.__callmeback = true;
            this.__callmebackParam = value;
        }
    }

    private __callback(): void {
        if (this.__callmeback) {
            this.__next(this.__callmebackParam);
        }
    }

    public static from<T>(
        subject: Subscrable, 
        cycle?: callback<T>, 
        error?: callback<Error>, 
        complete?: () => void
    ) {
        return new FutureFactory(subject, cycle, error, complete);
    }

    public cycle(callback: callback<T>): Future<T> {
        this._cycle = callback;
        this.__callback();
        return this;
    }

    public error(callback: callback<Error>): Future<T> {
        this._error = callback;
        return this;
    }

    public destroy(): Future<T> {
        this._subscription.unsubscribe();
        return this;
    }

    public inform(value?: T): void {
        if (value)
            this.__callmebackParam = value;
        this.__callmeback = true;
    }

    // Alias
    public later(callback: callback<T>): Future<T> {
        return this.cycle(callback);
    }
}
