import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Constants } from '../../../../providers/constants';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { RequestLoaderService } from '../../../../providers/request-loader.service';
import { UserService } from '../../../../providers/user.service'
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-calculator-pop-up-dialog',
  templateUrl: './calculator-pop-up-dialog.component.html',
  styleUrls: ['./calculator-pop-up-dialog.component.scss']
})
export class CalculatorPopUpDialogComponent implements OnInit {

  form: FormGroup;
  public sentEmail;
  resultado;
  resultado_anos;
  	public isDarkMode: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CalculatorPopUpDialogComponent>,
    private formBuilder: FormBuilder,
    private constants: Constants,
    private http: HttpClient,
    private spinner: RequestLoaderService,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: string
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
    if (this.userService && this.userService.user && this.userService.user.email) {
      this.sentEmail = true;
      this.sendEmail(); 
    }
    if (localStorage.getItem('mandouEmailPorCalculadoraHome') == 'mandou') {
      this.sentEmail = true;
      this.sendEmail(); 
    }
    if(isPlatformBrowser(this.platformId)){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.isDarkMode = true;
      else this.isDarkMode = false;  
    } 
    else this.isDarkMode = false;
  }

  sendEmail() {
    if (
      ((this.form && this.form.value && this.form.value.email) 
      || this.sentEmail)
      && this.data && this.data.opcao) {
      this.spinner.show();
      this.http.post(this.constants.getUrl() + '/vx/milhao', {pv: this.data.opcao.milMoney, aporte: this.data.opcao.milCalendar, tipo: parseInt(this.data.opcao.milInvest), email: this.form.value.email}, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
      })
    })
    .subscribe((rsp: any) => {
      this.resultado = Math.floor(rsp.resultado)
      this.resultado_anos = Math.floor(rsp.resultado / 12)
      this.spinner.hide();
      this.sentEmail = true;
      localStorage.setItem('mandouEmailPorCalculadoraHome', 'mandou')
    }, (e) => {
      this.spinner.hide(e);
    })
    }
  }

  close(option?: string): void {
    this.dialogRef.close(option);
  }

}
