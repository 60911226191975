<div *ngIf="indices" class="new-indicator-template">
  <div [ngClass]="darkMode ? 'dark-secondary-color' : ''" class="new-indicator-section with-bottom-line-for-mobile">
    <div class="new-indicator-header">
      <div class="left-header">
        <span [ngClass]="darkMode ? 'dark-primary-font' : ''">Índices</span>
        <div class="blue-line"></div>
      </div>
      <button mat-stroked-button color="accent" (click)="viewOptionsIndices($event)">EDITAR</button>
    </div>
    <div class="new-indicator-body" fxLayout="row" fxLayoutAlign="space-between start">
      <div class="new-indicator-node" *ngFor="let indice of indices; let i = index" [ngClass]="getBottomLineIndex(i, indices.length)">
        <div class="col-left">
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="indicator-name">{{indice.name}}</div>
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="indicator-description">{{indice.label}}</div>
        </div>
        <div class="col-right">
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="indicator-label">TAXA ATUAL</div>
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="indicator-value">{{ indice.value | number:'1.2-2' }}%</div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="darkMode ? 'dark-secondary-color' : ''" class="new-indicator-section with-bottom-line-for-mobile with-sidelines">
    <div class="new-indicator-header">
      <div class="left-header">
        <span [ngClass]="darkMode ? 'dark-primary-font' : ''">Câmbio</span>
        <div class="blue-line"></div>
      </div>
      <button mat-stroked-button color="accent" (click)="viewOptionsCambios($event)">EDITAR</button>
    </div>
    <div class="new-exchange-body">
      <div class="new-exchange-row" *ngFor="let cambio of cambios; let j = index" [ngClass]="getBottomLineExchange(j, cambios.length)">
        <div class="new-exchange-label">
          <div class="left">
            <div class="main-label"><div [ngClass]="darkMode ? 'dark-primary-font' : ''">{{cambio.name}}</div><span class="variation" [ngClass]="getCambioColorFor(cambio.variation)">{{cambio.variation | number:'1.2-2'}}%</span></div>
            <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="sub-label">{{cambio.label}}</div>
          </div>
        </div>
        <div class="new-exchange-buy">
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="buy-label">COMPRA</div>
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="value-label">{{cambio.buy  | currency:'BRL':'symbol':'1.4-4'}}</div>
        </div>
        <div class="new-exchange-sell">
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="sell-label">VENDA</div>
          <div [ngClass]="darkMode ? 'dark-primary-font' : ''" class="value-label">{{cambio.sell  | currency:'BRL':'symbol':'1.4-4'}}</div>
        </div>
      </div>
    </div>
  </div>
  
  <div [ngClass]="darkMode ? 'dark-secondary-color' : ''" class="new-indicator-section">
    <div class="new-indicator-header">
      <div class="left-header">
        <span [ngClass]="darkMode ? 'dark-primary-font' : ''">Criptomoedas</span>
        <div class="blue-line"></div>
      </div>
      <button mat-stroked-button color="accent" (click)="viewOptionsCryptos($event)">EDITAR</button>
    </div>
    <div class="new-exchange-body">
      <div class="new-exchange-row" *ngFor="let crypto of cryptos; let k = index" [ngClass]="getBottomLineExchange(k, cryptos.length)">
        <div class="new-exchange-label">
          <div class="left">
            <div class="main-label">
              <div [ngStyle]="{'font-size': crypto.id && crypto.id.length > 10 ? '10px' : ''}"  [ngClass]="darkMode ? 'dark-primary-font' : ''">
                {{crypto.id}}
              </div>
              <span class="variation" [ngClass]="getCambioColorFor(crypto.variation)">{{crypto.variation | number:'1.2-2'}}%</span>
            </div>
            <div class="sub-label" [ngClass]="darkMode ? 'dark-primary-font' : ''">{{crypto.provider}}</div>
          </div>
        </div>
        <div class="new-exchange-buy">
          <div class="buy-label" [ngClass]="darkMode ? 'dark-primary-font' : ''">COMPRA</div>
          <div class="value-label" [ngClass]="darkMode ? 'dark-primary-font' : ''">{{crypto.buy  | currency:'BRL':'symbol':'1.2-2'}}</div>
        </div>
        <div class="new-exchange-sell">
          <div class="sell-label" [ngClass]="darkMode ? 'dark-primary-font' : ''">VENDA</div>
          <div class="value-label" [ngClass]="darkMode ? 'dark-primary-font' : ''">{{crypto.sell  | currency:'BRL':'symbol':'1.2-2'}}</div>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!indices" class="new-indicator-loading">
  <mat-progress-spinner
      class="example-margin"
      color="primary"
      mode="indeterminate">
  </mat-progress-spinner>
  <span class="new-indicator-loading-text dark-primary-font">Carregando índices...</span>
</div>