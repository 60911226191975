import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SeoService {
    
    public readonly paths = {
        home: {
            title: 'Investimentos de Alta Performance - App Renda Fixa',
            description: 'Descubra nessa plataforma de investimentos de renda fixa com as maiores rentabilidades, vantagens e dicas preciosas para montar sua carteira.'
        },
        main: {
            title: 'Encontre os melhores investimentos - App Renda Fixa',
            description: 'O Aplicativo Renda Fixa é o único que ajuda pequenos e médios investidores a investir através de uma plataforma completa com busca e comparação, acesso à especialistas, ferramentas e curso para a escolha do seu melhor investimento.'
        },
        calculadoras: {
            title: 'Simuladores de Investimentos: Invista! - App Renda Fixa',
            description: 'Descubra qual o melhor investimento de renda fixa para você com o que tem no bolso! Simule e comece a investir com segurança.'
        },
        faq: {
            title: 'Dúvidas Frequentes de Investimentos - App Renda Fixa',
            description: 'Tire suas dúvidas sobre investimentos de renda fixa, termos financeiros, indicadores do mercado financeiro no nosso FAQ.'
        },
        feed: {
            title: 'Como a economia impacta nos seus investimentos? - App Renda Fixa',
            description: 'Acompanhe tudo sobre o mercado financeiro e aprenda a investir com os melhores especialistas em investimentos.'
        },
        comparador: {
            title: 'Compare e busque os melhores fundos - App Renda Fixa',
            description: 'Compare diferentes fundos de investimentos e descubra qual a melhor opção para você. São 11.266 fundos para comparar e 9 indicadores do mercado financeiro.'
        },
        fundos: {
            title: 'Fundos de Investimentos - App Renda Fixa',
            description: 'Compare e analise qual o melhor fundo de investimento de acordo com seus objetivos. São mais de 11.266 fundos disponíveis.'
        },
        p2p: {
            title: 'Peer-to-peer: Novo investimento - App Renda Fixa',
            description: 'Peer-to-peer Lending o investimento com altas taxas e que está crescendo no mercado financeiro a cada dia. Confira as melhores ofertas no App Renda Fixa.'
        },
        rendafixa: {
            title: 'Títulos de Renda Fixa: Busca e comparação!',
            description: 'Busca e comparação de investimentos de renda fixa com maiores rentabilidade, prazos, vencimentos e valor mínimo. Confira!'
        },
        partner: {
            'AVISTA FINANCEIRA': {
                title: 'Avista Financeira - RDB, CDB e LC - App Renda Fixa',
                description: 'Produtos disponíveis para investir com apenas um clique. Confira as melhores oportunidades ao investir seu dinheiro na Avista com a segurança do FGC.'
            },
            'Portocred': {
                title: 'Portocred - Letras de Câmbio - App Renda Fixa',
                description: 'Financeira com mais de 20 anos atuando no mercado com títulos de renda fixa que corresponde até 302% do CDI e FGC. Confira as opções!'
            },
            'Banco Teg': {
                title: 'Banco Teg - Confira no App Renda Fixa',
                description: 'O primeiro banco imobiliário com Investimentos em CCBs com alta perfomance e garantia de imóveis. Confira as melhores opções do mercado!'
            },
            'Caruana Financeira': {
                title: 'Caruana Financeira - Confira no App Renda Fixa',
                description: 'Instituição Financeira autorizada pelos órgãos regulamentadores, especializada no segmento de transporte de passageiros.'
            },
            'Creditá Financeira': {
                title: 'Creditá Investimentos - Opções no App Renda Fixa',
                description: 'Primeira financeira gaúcha e consolidada no mercado que oferece produtos com taxas atrativas em LCs e com a segurança do FGC. Confira suas opções!'
            },
            'Bancoin': {
                title: 'Bancoin Investimentos - Confira no App Renda Fixa',
                description: 'O Bancoin tem como missão a inclusão financeira e social da população. Confira opções de até 200% do CDI e invista com segurança.'
            },
            'Lobby':{
                title: 'Invista com a segurança do FGC - App Renda Fixa',
                description: 'Investimentos de renda fixa com até 17% pré e com diversas garantias. Busque e compare os melhores investimentos do mercado financeiro.'
            }
        },
        perfil: {
            title: 'Meu Perfil - App Renda Fixa',
            description: 'Saiba seu Perfil de Investidor - App Renda Fixa.'
        },
        ranking: {
            title: 'Qual a melhor corretora para investir? - App Renda Fixa',
            description: 'Opiniões de pessoas reais para te ajudar a escolher a corretora mais confiável do mercado financeiro para investir seu dinheiro. Confira o que todo mundo fala!'
        },
        login: {
            title: 'Login - App Renda Fixa',
            description: 'Faça seu login para participar do melhor da Renda Fixa.'
        },
        cadastro: {
            title: 'Cadastre-se! - App Renda Fixa',
            description: 'Seja parte da comunidade que entende de Renda Fixa.'
        },

    }

    constructor(
        private readonly meta: Meta,
        private readonly title: Title
    ) {
    }

    initDefaultMetaInformation(){
        this.title.setTitle('Investimentos de Alta Performance - App Renda Fixa');
        this.meta.addTags([
            { name: 'description', content: 'Descubra nessa plataforma de investimentos de renda fixa com as maiores rentabilidades, vantagens e dicas preciosas para montar sua carteira.'},
            { name: 'Author', content: 'Watermelon' }
        ])
    }

    updateMetaInformation(page: string, partner?: string){
        if(!partner){
            this.title.setTitle(this.paths[page].title);
            this.meta.updateTag(
                {name: 'description', content: this.paths[page].description}
            )
        } else {
            this.title.setTitle(this.paths[page][partner].title);
            this.meta.updateTag(
                {name: 'description', content: this.paths[page][partner].description}
            )
        }
    }

    updateCustomFundoMetaInformation(title, nomeFundo: string){
        this.title.setTitle(title);
        this.meta.updateTag(
            {
                name: 'description', 
                content: 'Confira tudo sobre ' + nomeFundo + ' dentro do maior aplicativo de Renda Fixa do Brasil.'
            }
        )
    }

    updateCustomRendaFixaInformation(title, corretora?){
        this.title.setTitle(title);
        if(corretora){
            this.meta.updateTag(
                {
                    name: 'description', 
                    content: 'Confira mais investimentos da corretora ' + corretora + ' dentro do maior aplicativo de Renda Fixa do Brasil.'
                }
            )
        } else {
            this.meta.updateTag(
                {
                    name: 'description', 
                    content: this.paths['rendafixa']['description']
                }
            )
        }
    }
}
