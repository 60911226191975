<section class="nh-home">
   
   <!-- SECTION MAIN WELCOME -->
   <app-main-welcome></app-main-welcome>
    
   <!-- SECTION HOWTO -->
   <app-howto></app-howto>

   <!-- BANNER INVESTIMENTO SEGURO -->
   <app-banner-investimento-seguro></app-banner-investimento-seguro>

   <!-- SABE COMO INVESTIR -->
   <app-sabe-como-investir></app-sabe-como-investir>
   
   <!-- CAROUSEL ONG -->
   <app-carousel-ong></app-carousel-ong>

   <!-- ASSISTA NOSSO VIDEO -->
   <app-assista-video></app-assista-video>
</section>