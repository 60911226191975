<section class="assista-video">
    <span class="responsive">Como investir através do App Renda Fixa</span>
    <div class="av-container">
        <div class="av-carousel">
            <owl-carousel-o *ngIf="loaded" [options]="carouselCommits">
                <ng-container *ngFor="let item of commits">
                    <ng-template carouselSlide>
                        <div class="av-description">

                            <div class="av-title">
                                <span>{{item.descTitle}}</span>
                            </div>
                  
                            <div class="av-desc">
                                <div class="av-quot left">
                                    <img [src]="item.iconQuot">
                                </div>
                                <span>{{item.description}}</span>
                                <div class="av-quot right">
                                    <img [src]="item.iconCloseQuot">
                                </div>
                            </div>
                            <div class="av-stars">
                                <img *ngFor="let i of item.iconStars" [src]="iconStar">
                            </div>
                            <div class="av-author">
                                <span>{{item.descAuthor}}</span>
                            </div>
                            <div class="av-source">
                                <span>{{item.descSource}}</span>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="av-app-visto-container">
            <div class="av-app-visto-title">
                <span>Onde o App Renda Fixa foi visto</span>
            </div>
            <div class="av-carousel noticias">
                <owl-carousel-o *ngIf="loaded" [options]="carouselOptions">
                    <ng-container *ngFor="let i of cardsPortaisNoticias">
                         <ng-template carouselSlide class="img">
                            <a target="_blank" [href]="i.link">
                                <img [src]="i.imagem">
                            </a>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>