import { Injectable } from '@angular/core';
import { SimpleSnackBar, MatSnackBar, MatSnackBarRef, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject ,  Subscription } from 'rxjs';


@Injectable()
export class RequestLoaderService {
    private static __loading: boolean = false;
    private static __tracking: boolean = false;
    private static notifier: Subject<number[] | number>;
    private static trackingInformer = new Subject<{ isTracking: boolean, progress: number, abort?: () => void }>();
    constructor(public snackBar: MatSnackBar) {

    }

    private set _loading(value: boolean) {
        RequestLoaderService.__loading = value;
    }

    private get _loading(): boolean {
        return RequestLoaderService.__loading;
    }

    subscribeToTracking(callback: (params: { isTracking: boolean, progress: number, abort?: () => void }) => void): Subscription {
        return RequestLoaderService.trackingInformer.subscribe(callback);
    }

    show(): void {
        this._loading = true;
    }

    showAndTrack(abort?: () => void): Subject<number[] | number> {
        this.show();
        RequestLoaderService.trackingInformer.next({ isTracking: true, progress: 0, abort: abort });
        RequestLoaderService.notifier = new Subject<number[] | number>();
        const subRef = RequestLoaderService.notifier.subscribe(progress => {
            const progressArr: number[] = Array.isArray(progress) ? progress : [progress];
            RequestLoaderService.trackingInformer.next({
                isTracking: true,
                progress: progressArr.reduce((result, element) => {
                    if (result === undefined || result === null) return 1; else return result + element;
                }, 0) / progressArr.length
            });
        }, error => {
            setTimeout(() => { RequestLoaderService.notifier.complete() }, 60);
        }, () => {
            RequestLoaderService.notifier = undefined;
            RequestLoaderService.trackingInformer.next({ isTracking: false, progress: undefined, abort: abort });
            subRef.unsubscribe();
        });
        return RequestLoaderService.notifier;
    }

    trackProgress(notifier: Subject<number[] | number>, progress: number[] | number, delay?: number): void {
        if (!delay)
            notifier.next(progress);
        else
            setTimeout(() => { notifier.next(progress) }, delay);
    }

    hide(result?: string, action?: string): void {
        this._loading = false;
        if (RequestLoaderService.notifier)
            RequestLoaderService.notifier.complete();
        if (result) {
            action = action ? action : "Ok";
            this.snackBar.open(result, action, { duration: 7700 });
        }
    }

    report(message: string, action?: string, config?: any): MatSnackBarRef<SimpleSnackBar> {
        return this.snackBar.open(message, action || "Ok", config || { duration: 7700 });
    }

    get loading(): boolean {
        return this._loading;
    }
}