import { Injectable } from '@angular/core';

@Injectable()
export class HelpMeService {
    private static tips: {[name: string]: string} = {};
    protected get tips(): {[name: string]: string} {return HelpMeService.tips;}
    constructor() {}

    private _register(name: string, text: string): void {
        this.tips[name] = text;
    }

    public alias(alias: string, name: string): void {
        if (!this.tips[name]) return;
        this.tips[alias] = this.tips[name];
    }

    public get(name: string): string {
        return this.tips[name];
    }

    public register(name: string, text: string): void
    public register(tips: ({name: string, text: string} | {name: string, alias: string})[])
    public register(arg1: any, arg2?: any): void {
        let tips: {name: string, text?: string, alias?: string}[];
        const aliases: {name: string, alias?: string}[] = [];
        if (Array.isArray(arg1)) {
            tips = arg1;
        } else {
            tips = [{name: arg1, text: arg2}];
        }
        tips.forEach(element => {
            if (!element || !element.name) return;
            if (element.alias) {
                aliases.push(element)
            }
            if (element.text) {
                this._register(element.name, element.text);
            }
        });
        aliases.forEach(element => {
            this.alias(element.alias, element.name);
        });
    }
}