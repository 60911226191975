/* 
 *  Tabela de ratings
 *    http://bonsinvestimentos.com.br/wp-content/uploads/2016/04/moodys-fitch-sp-rating-nota.png 
 */

// Qualidade Alta
const _cor_alta = '#26c6da';
const _qual_alta = 1000;
// Qualidade Média
const _cor_media = '#9ccc65';
const _qual_media = 100;
// Qualidade Baixa
const _cor_baixa = '#ffca28';
const _qual_baixa = 10;
// Qualidade Muito Baixa
const _cor_muito_baixa = '#ff9800';
const _qual_muito_baixa = 1;

const __classification = [
     {
          id: "prime",
          title: "Prime",
          ratings: ['Aaa', 'AAA']
     },
     {
         id: "grauElevado",
         title: "Grau Elevado",
         ratings: ['Aa1', 'Aa2', 'Aa3', 'AA+', 'AA', 'AA-']
     },
     {
         id: "grauMedioElevado",
         title: "Grau Médio Elevado",
         ratings: ['A1', 'A2', 'A3', 'A+', 'A', 'A-']
     },
     {
         id: "grauMedioBaixo",
         title: "Grau Médio Baixo",
         ratings: ['Baa1', 'Baa2', 'Baa3', 'BBB+', 'BBB', 'BBB-']
     },
     {
         id: "grauEspeculativo",
         title: "Grau Especulativo",
         ratings: ['Ba1', 'Ba2', 'Ba3', 'BB+', 'BB', 'BB-']
     },
     {
         id: "grauAltamenteEspeculativo",
         title: "Grau Altamente Especulativo",
         ratings: ['B1', 'B2', 'B3', 'B+', 'B', 'B-']
     },
     {
         id: "grauExtremamenteEspeculativo",
         title: "Extremamente Especulativo",
         ratings: ['Ca', 'Caa1', 'Caa2', 'Caa3', 'CCC+', 'CCC', 'CCC-', 'CC']
     },
     {
         id: "grauEmMoratoria",
         title: "Em Moratória",
         ratings: ['C', 'DDD', 'DD', 'D']
     }
 ];

const __ratingsData = [
    {
      ratings: ['AAA', 'Aaa'],
      color: _cor_alta,
      value: _qual_alta * 7
    },
    {
        ratings: ['AA+', 'Aa1'],
        color: _cor_alta,
        value: _qual_alta * 6
    },
    {
        ratings: ['AA', 'Aa2'],
        color: _cor_alta,
        value: _qual_alta * 5
    },
    {
        ratings: ['AA-', 'Aa3'],
        color: _cor_alta,
        value: _qual_alta * 4
    },
    {
        ratings: ['A+', 'A1'],
        color: _cor_alta,
        value: _qual_alta * 3
    },
    {
        ratings: ['A', 'A2'],
        color: _cor_alta,
        value: _qual_alta * 2
    },
    {
        ratings: ['A-', 'A3'],
        color: _cor_alta,
        value: _qual_alta * 1
    },
    
    {
      ratings: ['BBB+', 'Baa1'],
      color: _cor_media,
      value: _qual_media * 3
    },
    {
        ratings: ['BBB', 'Baa2'],
        color: _cor_media,
        value: _qual_media * 2
    },
    {
        ratings: ['BBB-', 'Baa3'],
        color: _cor_media,
        value: _qual_media * 1
    },

    {
      ratings: ['BB+', 'Ba1'],
      color: _cor_baixa,
      value: _qual_baixa * 6
    },
    {
        ratings: ['BB', 'Ba2'],
        color: _cor_baixa,
        value: _qual_baixa * 5
    },
    {
        ratings: ['BB-', 'Ba3'],
        color: _cor_baixa,
        value: _qual_baixa * 4
    },
    {
        ratings: ['B+', 'B1'],
        color: _cor_baixa,
        value: _qual_baixa * 3
    },
    {
        ratings: ['B', 'B2'],
        color: _cor_baixa,
        value: _qual_baixa * 2
    },
    {
        ratings: ['B-', 'B3'],
        color: _cor_baixa,
        value: _qual_baixa * 1
    },

    {
      ratings: ['CCC', 'Caa1', 'CCC+'],
      color: _cor_muito_baixa,
      value: _qual_muito_baixa * 5
    },
    {
      ratings: ['CC', 'Caa2'],
      color: _cor_muito_baixa,
      value: _qual_muito_baixa * 4
    },
    {
      ratings: ['C', 'Caa3', 'CCC-'],
      color: _cor_muito_baixa,
      value: _qual_muito_baixa * 3
    },
    {
      ratings: ['RD', 'Ca'],
      color: _cor_muito_baixa,
      value: _qual_muito_baixa * 2
    },
    {
      ratings: ['D'],
      color: _cor_muito_baixa,
      value: _qual_muito_baixa * 1
    }
]

let __ratings = {};

const __ucPerfilDesc = "Para investidores que priorizam a preservação do patrimônio, segurança e liquidez nos investimentos em lugar de buscar alta rentabilidade.";
const __coPerfilDesc = "Para investidores que buscam a preservação do patrimônio, mas também almejam retornos acima da inflação correndo pouco risco.";
const __moPerfilDesc = "Para investidores que têm condições e apetite para buscar ganhos mais expressivos, mas que ainda têm algumas limitações para correr grandes riscos.";
const __arPerfilDesc = "Para investidores que não têm problema em correr riscos no curto e médio prazo e têm conhecimento de que o retorno normalmente está ligado ao risco assumido.";
const __agPerfilDesc = "Para investidores que buscam obter o máximo de retorno possível, não levando em conta os riscos assumidos.";

export interface Perfil {
    name: string; 
    value: number; 
    description: string;
    id: string;
}

export class Rating {
    private static perfils = {
        0: {name: "Ultra conservador", value: 0, description: __ucPerfilDesc, id: "ultra-conservador"},
        1: {name: "Conservador", value: 1, description: __coPerfilDesc, id: "conservador"},
        2: {name: "Moderado", value: 2, description: __moPerfilDesc, id: "moderado"},
        3: {name: "Arrojado", value: 3, description: __arPerfilDesc, id: "arrojado"},
        4: {name: "Agressivo", value: 4, description: __agPerfilDesc, id: "agressivo"},

        "ultra conservador": {name: "Ultra conservador", value: 0, description: __ucPerfilDesc, id: "ultra-conservador"},
        "conservador": {name: "Conservador", value: 1, description: __coPerfilDesc, id: "conservador"},
        "moderado": {name: "Moderado", value: 2, description: __moPerfilDesc, id: "moderado"},
        "arrojado": {name: "Arrojado", value: 3, description: __arPerfilDesc, id: "arrojado"},
        "agressivo": {name: "Agressivo", value: 4, description: __agPerfilDesc, id: "agressivo"},
    }

    public static getPerfil(perfil: number | string): Perfil {
        perfil = typeof perfil === 'string' ? perfil.toLowerCase().replace("-", " ") : perfil;
        return Rating.perfils[perfil];
    }

    private _text: string;
    private _value: number;
    private _color: string;
    private _class: string;
    private _classId: string;
    public get text(): string {return this._text;}
    public get value(): number {return this._value;}
    public get color(): string {return this._color;}
    public get classText(): string {return this._class;}
    public get classId(): string {return this._classId;}

    public static mount(
        text: string, 
        value: number, 
        color: string,
        _class?: string,
        _classId?: string
    ): Rating {
        let rating  = new Rating();
        rating._text = text;
        rating._value = value;
        rating._color = color;
        rating._class = _class;
        rating._classId = _classId;
        return rating;
    }

    public static get(rating: string): Rating {
        return __ratings[rating] || null;
    }

    public static formatRating (rating: string | string[]): string {
        if (Array.isArray(rating)) {
          rating = rating.join(", ");
        }
        if (rating != "") {
          rating = " (" + rating + ")";
        }

        return rating;
    }

    public toString(): string {
        return this.text;
    }
}

__ratingsData.forEach(element => {
    element.ratings.forEach(text => {
        let __className;
        let __classId;
        for (const __class of __classification) {
            if (__class.ratings.indexOf(text) > -1) {
                __classId = __class.id;
                __className = __class.title;
                break;
            }
        }
        __ratings[text] = Rating.mount(text, element.value, element.color, __className, __classId);
    });
});
