
<section class="banner-mini-card">
    <div class="bm-container">
        <ng-container *ngFor="let i of cards">
            <mat-card class="bm-card">
                <div class="bm-image">
                    <img [src]="i.image">
                </div>
                <div class="bm-desc">
                    <span>{{i.desc}}</span>
                </div>
            </mat-card>
        </ng-container>
    </div>
</section>