<div class="pop-up-container">
  <div [ngClass]="isDarkMode ? 'dark-primary-color' : ''" class="advice-header">
    <img (click)="close(null)" src="../../../../../assets/icons/closeDialog.svg" />
    <span>Resultado</span>
  </div>
  <form *ngIf="!sentEmail" [formGroup]="form">    

    <div class="advice-body">
      <span>Digite seu email para dar o primeiro passo, rumo ao milhão</span>
      <input mdbInput formControlName="email" class="icon-mail" placeholder="Digite seu e-mail" [(ngModel)]="email" required/>
    </div>

    <div class="advice-footer">
      <div class="footer-button">
        <button mat-raised-button [disabled]="!form.valid" (click)="sendEmail()" color="accent">VER RESULTADO</button>
      </div>
    </div>
  </form> 

  <div [ngClass]="isDarkMode ? 'dark-secondary-color' : ''" class="advice-body-ok" *ngIf="sentEmail">
    <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="message">Tempo para alcançar o milhão</span>
    <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="data"><div>{{resultado}}</div> meses</span>
    <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="message"> - ou - </span>
    <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="data"><div>{{resultado_anos}}</div> anos</span>

    <button mat-stroked-button color="primary" (click)="close(null)">OK!</button>
  </div>
</div>