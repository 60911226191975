import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    HttpClient,
    HttpEventType,
    HttpResponse,
    HttpRequest,
    HttpHeaders,
    HttpErrorResponse
} from '@angular/common/http';

import {Constants} from './constants';
import { JsonValid } from './localstorage.service';
import { DOCUMENT } from '@angular/common';


export interface PathMap {
    id: string;
    params?: any;
    querryString?: any | string;
}


@Injectable()
export class Connector {
    constructor (
        private http2: HttpClient, 
        public constants: Constants,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    private toParams(params: object) {
        let _params;
        for (let key in params) {
            _params.append(key, params[key]);
        }
        return _params;
    }

    private toHeaders(headers: object, __httpClient?: boolean) : any {
        if (__httpClient)  {
            return new HttpHeaders(<any>Object.keys(headers).reduce((reduce, key) => {
                const value = headers[key];
                if (value !== null && value !== undefined) reduce[key] = value;
                return reduce;
            }, {}));
        }
        let constructHeaders = {}
        for (let key in headers) {
            const value = headers[key];
            if (value !== null && value !== undefined) constructHeaders[key] = value;
        }
        let _headers: HttpHeaders = new HttpHeaders(constructHeaders);
        return _headers;
    }

    private getOptions(headers?: object, params?: object, _options?: any, __httpClient?: boolean): any {
        let options: any = _options || {};
        if (headers) {
            options.headers = this.toHeaders(headers, __httpClient);
        }
        if (params) {
            options.params = this.toParams(params);
        }
        return options;
    }

    protected _get (url: string, headers?: object, params?: object, options?: object): Observable<any>  {
        return this.http2.get(url, this.getOptions(headers, params, options));
    }

    public get (url: string, headers?: object, params?: object): Promise<Response>  {
        return this._get(url, headers, params).toPromise();
    }

    protected _head (url: string, headers?: object, params?: object): Observable<any>  {
        return this.http2.head(url, this.getOptions(headers, params));
    }

    public head (url: string, headers?: object, params?: object): Promise<Response>  {
        return this._head(url, headers, params).toPromise();
    }

    protected _delete (url: string, headers?: object, params?: object): Observable<any>  {
        return this.http2.delete(url, this.getOptions(headers, params));
    }

    public delete (url: string, headers?: object, params?: object): Promise<Response> {
        return this._delete(url, headers, params).toPromise();
    }

    protected _post (url: string, body?: any, headers?: object, params?: object): Observable<any> {
        return this.http2.post(url, body, this.getOptions(headers, params));
    }

    public post (url: string, body?: any, headers?: object, params?: object): Promise<Response> {
        return this._post(url, body, headers, params).toPromise();
    }

    protected _put (url: string, body?: any, headers?: object, params?: object): Observable<any> {
        return this.http2.put(url, body, this.getOptions(headers, params));
    }

    public put (url: string, body?: any, headers?: object, params?: object): Promise<Response> {
        return this._put(url, body, headers, params).toPromise();
    }

    protected _patch (url: string, body?: any, headers?: object, params?: object): Observable<any> {
        return this.http2.patch(url, body, this.getOptions(headers, params));
    }

    public patch (url: string, body?: any, headers?: object, params?: object): Promise<Response> {
        return this._patch(url, body, headers, params).toPromise();
    }

    public usingHost(host: string) {
        console.log('entrou usingHost');
        
        return Api.mountOnHost(this, host);
    }

    public get api (): Api {
        return this.usingHost(undefined);
    }

    protected httpWithProgress(
        method: string,
        url: string, 
        onProgress: (upload: number, download: number) => void, 
        body?: any,
        headers?: object, 
        params?: object
    ): Promise<HttpResponse<any>>  {
        return new Promise((resolve, reject) => {
            const requestInfo = new HttpRequest(method, url, body, this.getOptions(headers, params, {reportProgress: true}, true));
            const request = this.http2.request(requestInfo); 
            // _get(url, headers, params, {reportProgress: true});
            let uploadProgress = undefined;
            let downloadProgress = 0;
            const subscription = request.subscribe((event: any) => {
                if (event.type === HttpEventType.UploadProgress && onProgress) {
                    uploadProgress = event.loaded / event.total;
                    onProgress(uploadProgress, downloadProgress);
                } else if (event.type === HttpEventType.DownloadProgress && onProgress) {
                    downloadProgress = event.loaded / event.total;
                    onProgress(uploadProgress, downloadProgress);
                } else if (event.type === HttpEventType.Response) {
                    const httpResponse: HttpResponse<any> = event;
                    const httpHeaders = new HttpHeaders(httpResponse.headers.keys().reduce((result, key) => {
                        result[key] = httpResponse.headers.get(key);
                        return result;
                    }, {}));
                    const response = new HttpResponse({
                        body: httpResponse.body,
                        headers: httpHeaders,
                        status: httpResponse.status,
                        url: httpResponse.url,
                        statusText: httpResponse.statusText
                    });
                    subscription.unsubscribe();
                    resolve(response);
                }
            }, (response: HttpErrorResponse) => {
                reject(response.error || {message: "Ocorreu um erro durante a última operação"});
            });
        });
    }

    public request(
        request: {
            url?: string, 
            headers?: object, 
            params?: object, 
            method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD',
            body?: any
        },
        onResponse?: (response: HttpResponse<any>) => void,
        onError?: (error: any) => void,
        onProgress?: (upload: number, download: number) => void
    ): void {
        this.httpWithProgress(
            request.method || 'GET', 
            request.url, 
            onProgress || function () {}, 
            request.body, 
            request.headers, 
            request.params
        ).then(onResponse || function () {}).catch(onError || function () {});
    }

    
    /** 
    * Instâncias de browser como document window e location não funcionam no Server Side Rendering,
    *o uso da função de abertura em uma nova aba pelo typescript deve ser feita usando esta função que cria
    *uma tag `<a>`, com target `_blank` e o href para a url informada, clica nela e elimina o elemento ao final. 
    */
    public openInNewTab(url: string){
        const link = this.document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.click();
        link.remove();
    }
}


export interface ApiRequestInfo {
    headers?: object, 
    params?: object, 
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD',
    body?: any
}


@Injectable()
export class Api {
    private host: string
    constructor (private connector: Connector) {
    }

    static mountOnHost(connector: Connector, host?: string): Api {
        console.log('entrou mountOnHost');
        
        if (host) {
            console.log('entrou if host');
            
            if (!host.endsWith("/")) {
                host += "/";
            }
        }
        console.log('host:', host);
        console.log('connector:', connector);
        
        const api = new Api(connector);
        api.host = host;
        console.log('api:', api);
        
        return api;
    }

    private compilePath(pathMap: PathMap): string {
        console.log('entrou compilePath');
        console.log('pathMap.id:', pathMap.id);
        console.log('this.host:', this.host);
        
        let url = this.connector.constants.get(pathMap.id, this.host);
        console.log('url:', url);
        
        const params = pathMap.params || {};
        for (const key in params) {
            const value = params[key];
            url = url.replace('{' + key + '}', value === undefined ? "" : value + "");
        }
        let querryString = pathMap.querryString || "";
        if (typeof querryString !== 'string') {
            const _querryString: any = querryString;
            querryString = '';
            for (const key in _querryString) {
                const value = _querryString[key];
                if (value === undefined)
                    continue;
                querryString += '&' + key + '=' + _querryString[key];
            }   
        }
        if (querryString) {
            if (querryString[0] === '?' || querryString[0] === '&') {
                querryString = querryString.slice(1, querryString.length);
            }
            url += '?' + querryString;
        }
        return url;
    }

    private getUrl(pathMap: string | PathMap): string {
        console.log('entrou getUrl');
        console.log('pathMap:', pathMap);
        if (typeof pathMap !== 'string') {
            return this.compilePath(pathMap);
        }
        let path: string = pathMap;
        if (path.startsWith("/")) {
            path = path.substring(1);
        }
        const __path = path.replace(/\\:/g, "$colon$").split(":");
        let url = this.connector.constants.get(__path[0], this.host);
        for (let i = 1; i < __path.length; ++i) {
            const param = __path[i].split("=");
            if (param.length >= 2 && param[0]) {
                param[1] = param[1] === 'undefined' || param[1] === 'null' ? undefined : param[1];
                url = url.replace("{" + param[0].replace("$colon$", ":") + "}", param[1] || "");
            }
        }
        return url;
    }
    public get (path: string | PathMap, headers?: object, params?: object): Promise<Response>  {
        console.log('path:', this.getUrl(path));
        return this.connector.get(this.getUrl(path), headers, params);
    }

    public head (path: string | PathMap, headers?: object, params?: object): Promise<Response>  {
        return this.connector.head(this.getUrl(path), headers, params);
    }

    public delete (path: string | PathMap, headers?: object, params?: object): Promise<Response>  {
        return this.connector.delete(this.getUrl(path), headers, params);
    }

    public post (path: string | PathMap, body?: any, headers?: object, params?: object): Promise<Response> {
        return this.connector.post(this.getUrl(path), body, headers, params);
    }

    public put (path: string | PathMap, body?: any, headers?: object, params?: object): Promise<Response> {
        return this.connector.put(this.getUrl(path), body, headers, params);
    }

    public patch (path: string | PathMap, body?: any, headers?: object, params?: object): Promise<Response> {
        return this.connector.patch(this.getUrl(path), body, headers, params);
    }

    public request(
        path: string | PathMap,
        request: ApiRequestInfo,
        onResponse?: (response: HttpResponse<any>) => void,
        onError?: (error: any) => void,
        onProgress?: (upload: number, download: number) => void
    ): void {
        request = request || <any>{};
        request["url"] = this.getUrl(path);
        return this.connector.request(<any>request, onResponse, onError, onProgress);
    }
};


export interface ApiRequest {
    readonly url: string;
    readonly completed: boolean;
    readonly response: Response;
}


export interface ApiRequestFactory {
    onResponse(callback: (response: Response) => void): ApiRequestFactory;
    onError(callback: (error: any) => void): ApiRequestFactory;
    onProgress(callback: (upload: number, download: number) => void): ApiRequestFactory;
    start(): ApiRequest;
    readonly started: boolean;
}



