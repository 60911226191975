<div fxLayout="row">
    <div class="callendar" [fxHide]="!prefixIcon">
        <mat-icon [ngClass]="darkMode ? 'dark-primary-font' : ''">{{prefixIcon}}</mat-icon>
    </div>
    <mat-form-field [ngClass]="darkMode ? 'dark-mat-form-field' : ''" class="container" flex="grow">    
            <input matInput #datepickerInput [required]="required ? true : null"
            [max]="max" [min]="min" [value]="value" (click)="open($event)" (dateChange)="onValueChange($event)"
            [matDatepicker]="datepicker" [placeholder]="placeholder" [disabled]="disabled" readonly/>
        <mat-datepicker [panelClass]="darkMode ? 'dark-datepicker' : ''" #datepicker [startAt]="startAt"></mat-datepicker>
    </mat-form-field>
    <div class="date-toggle-button">
        <button mat-icon-button (click)="toggle($event)" [disabled]="disabled">
            <mat-icon [ngClass]="darkMode ? 'dark-primary-font' : ''">{{suffixIcon}}</mat-icon>
        </button>
    </div>
</div>