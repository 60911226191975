import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-welcome',
  templateUrl: './main-welcome.component.html',
  styleUrls: ['./main-welcome.component.scss']
})
export class MainWelcomeComponent implements OnInit {
  public welcome: any = {
    title: 'Encontre os melhores investimentos',
    desc: 'O Aplicativo Renda Fixa é o único que ajuda pequenos e médios investidores a investir através de uma plataforma completa com busca e comparação, acesso à especialistas, ferramentas e curso para a escolha do seu melhor investimento.',
    subdesc: 'CLIQUE NO BOTÃO ABAIXO E DESCUBRA O INVESTIMENTO CERTO PARA SEU OBJETIVO',
    descMobile: 'Busque investimentos com alta rentabilidade em renda fixa ou renda variável. Faça parte de mais de 300 mil investidores do App Renda Fixa!'
  }
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
  }

  goDown(){
    this.document.getElementById("next").scrollIntoView({behavior: "smooth"});
  }

}
