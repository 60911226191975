import { Inject, Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TituloCard } from "../model/titulo-card";
import { RequestLoaderService } from "../components/request.loader/request.loader.service";
import { UserService } from "./user.service";
import { CorretorasService } from "./corretoras.service";
import { RendafixaService } from "./rendafixa.service";
import { RouterStateService } from "./router-state.service";
import { Constants } from "../providers/constants";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Connector } from "./connector";
import { InvestFillDataDialogComponent } from "app/components/invest-fill-data-dialog/invest-fill-data-dialog.component";

@Injectable()
export class InvestService {
    corretora_nome;
    OSName="Unknown OS";
    whatsLink = "https://api.whatsapp.com/send?phone=5511988829851&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20Ativos%20Alternativos%20que%20vi%20na%20plataforma%20do%20App."
    constructor(
        private spinner: RequestLoaderService,
        private userService: UserService,
        private corretorasService: CorretorasService,
        private rendafixaService: RendafixaService,
        private http: HttpClient,
        private constants: Constants,
        protected dialog: MatDialog,
        protected routerStateService: RouterStateService,
        private connector: Connector,
        @Inject(DOCUMENT) private document: Document        
    ) {
        
     }

    private sendInvest(result: { name: string, tel: string, email: string, _id: any }) {
        return this.rendafixaService.postThirdPartyInvestimentInvestSend(
            result.name, result.tel, result.email, result._id, result['pais']
        );
    }

    public investExternally(url): void {
        this.connector.openInNewTab(url);
    }

    public speakWithWhatsApp(titulo){
        if(titulo) {
            if(titulo.idx == 'IPCA'){
                this.whatsLink = this.whatsLink + " O investimento que gostei é: " + titulo.corretora + " " + titulo.tipo + " " + titulo.idx + " +" + titulo.juros + " %.";
            } else if(titulo.idx == 'PRÉ' || titulo.idx == 'CDI' || titulo.idx == 'SELIC') {
                this.whatsLink = this.whatsLink + " O investimento que gostei é: " + titulo.corretora + " " + titulo.tipo + " " + titulo.juros + " % " + titulo.idx + ".";
            } else {
                this.whatsLink = this.whatsLink;
            }
        }
        var windowOpener = this.document.open(this.whatsLink);
        if(!windowOpener) this.document.location.href = this.whatsLink; //windowOpener dando falso é sinal de pop-up blocker
    }

    private investByTitulo(titulo: TituloCard): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log('entrou investByTitulo');
            const destroyMe = this.corretorasService.subscribe().cycle(() => {
                const corretora = this.corretorasService.getCorretoraSafe(titulo.corretora);
                if (corretora["investir"]) {
                    console.log('entrou if corretora["investir"]');
                    corretora["investir"]();
                    destroyMe.destroy();
                    return resolve(titulo);
                } else if (!titulo.invest) {
                    console.log('entrou if !titulo.invest');
                    this.investByOpeningPopUp(titulo);
                    destroyMe.destroy();
                    return resolve(titulo);
                }
                /* pop-up que realiza o investimento começa aqui */
                const config: MatDialogConfig = {
                    width: '900px',
                    maxWidth: '95vw',
                    data: {
                        user: this.userService.user,
                        agencia: titulo.__agencia
                    },
                };

                this.dialog.open(InvestFillDataDialogComponent, config).afterClosed().subscribe(result => {
                    if (!result || !result.send) {
                        return;
                    } else {
                        this.spinner.show();
                    }
                    result._id = titulo._id;
                    this.sendInvest(result).then(response => {
                        this.spinner.hide("Solicitação efetuada com sucesso!", "Ok");
                        destroyMe.destroy();
                        resolve(response);
                    }).catch(error => {
                        destroyMe.destroy();
                        reject(error);
                    });
                });
            });
        });
    }

    getCorretoraNome(corretora_name) {
        corretora_name = corretora_name.toLowerCase();
        for (const corretora of this.corretorasService.corretoras) {
            if (corretora.nome.toLowerCase() == corretora_name) {
                this.corretora_nome = corretora.nome;
                return this.corretora_nome;
            }
        }
    }


    public investByOpeningPopUp(titulo){
        const dialogRef = this.dialog.open(InvestFillDataDialogComponent, {
            width: '900px',
            maxWidth: '95vw',
            data: {
                titulo: titulo,
                enable_popup: titulo.enable_popup
            }
        }).afterClosed().subscribe((result: any) => {
            if (result && result.name && result.email && result.tel && result.pais) {
                this.spinner.show();
                if(result["investimento"] && result["investimento"]["corretora"] == "Hurst Capital"){
                    let pl = {
                        'objectId': titulo.objectId || 'adblocker',
                        'id': titulo.id,
                        'nome': result.name,
                        'email': result.email,
                        'telefone': result.tel,
                        "pais": result.pais
                    }
                    this.http.post(this.constants.get('leadHurst'), pl).subscribe(val => {
                        this.speakWithWhatsApp(titulo);
                        this.spinner.hide();
                    }, err=> {
                        this.spinner.hide("Erro: " + err.error.status + ". Contate nosso suporte no Contato@rendafixa.rocks.", 'OK');

                    })
                }
                else if(result["investimento"]){
                    let pl = {
                        'objectId': titulo.objectId || 'adblocker',
                        'id': titulo.id,
                        'nome': result.name,
                        'email': result.email,
                        'telefone': result.tel,
                        "pais": result.pais
                    }
                    this.http.post(this.constants.get('leadGeral'), pl).subscribe(val => {
                        for (const corretora of this.corretorasService.corretoras) {
                            if (titulo.corretora.toLocaleLowerCase() == corretora.nome.toLocaleLowerCase()) {
                                if( corretora.url &&
                                    corretora.url.indexOf('{nome}') > -1 && 
                                    corretora.url.indexOf('{email}') > -1 && 
                                    corretora.url.indexOf('{telefone}') > -1
                                ){
                                    corretora.url = corretora.url.replace('{nome}', result.name)
                                    corretora.url = corretora.url.replace('{email}', result.email)
                                    corretora.url = corretora.url.replace('{telefone}', result.tel)
                                    this.investExternally(corretora.url)
                                    this.spinner.hide()
                                    break;
                                }else if (!corretora.url){
                                    this.spinner.hide("Erro: Interno de Corretora. Contate nosso suporte no Contato@rendafixa.rocks, por gentileza.", 'OK');
                                } else {
                                    this.spinner.hide();
                                    this.investExternally(corretora.url)
                                }
                            }
                        }
                    }, err=> {
                        this.spinner.hide("Erro: " + err.error.status + ". Contate nosso suporte no Contato@rendafixa.rocks.", 'OK')
                    })
                }
                else{
                    for (const corretora of this.corretorasService.corretoras) {
                        if (titulo.corretora.toLocaleLowerCase() == corretora.nome.toLocaleLowerCase()) {
                            if( corretora.url &&
                                corretora.url.indexOf('{nome}') > -1 && 
                                corretora.url.indexOf('{email}') > -1 && 
                                corretora.url.indexOf('{telefone}') > -1
                            ){
                                corretora.url = corretora.url.replace('{nome}', result.name)
                                corretora.url = corretora.url.replace('{email}', result.email)
                                corretora.url = corretora.url.replace('{telefone}', result.tel)
                                console.log(corretora, result);
                                this.investExternally(corretora.url)
                                this.spinner.hide()
                                break;
                            }else if (!corretora.url){
                                this.spinner.hide("Erro: Interno de Corretora. Contate nosso suporte no Contato@rendafixa.rocks, por gentileza.", 'OK');
                            } else {
                                this.spinner.hide();
                                this.investExternally(corretora.url)
                            }
                        }
                    }
                }
            }
        })
    }

    public invest(titulo, isRobot?) {
        if(!isRobot){
            if (
                this.document.location.href.search('shouldopenpopup=true') != -1
                || this.document.location.href.search('shouldopenpopup%3Dtrue') != -1
            ) {
                this.spinner.hide();
                return this.investByTitulo(titulo);
            } else if (titulo.invest || titulo.enable_popup || titulo.url) {
                this.spinner.hide()
                const dialogRef = this.dialog.open(InvestFillDataDialogComponent, {
                    width: '900px',
                    maxWidth: '95vw',
                    data: {
                        titulo: titulo,
                        enable_popup: titulo.enable_popup
                    }
                }).afterClosed().subscribe((result: any) => {
                    if (result && result.name && result.email && result.tel) {
                        this.spinner.show();
                        if(result["investimento"] && result["investimento"]["corretora"] == "Hurst Capital"){
                            let pl = {
                                'objectId': titulo.objectId || 'adblocker',
                                'id': titulo.id,
                                'nome': result.name,
                                'email': result.email,
                                'telefone': result.tel,
                                "pais": result.pais
                            }
                            this.http.post(this.constants.get('leadHurst'), pl).subscribe(val => {
                                this.investExternally(result["investimento"]["url"]);
                                this.speakWithWhatsApp(titulo);
                                return this.spinner.hide();
                            }, err=> {
                                return this.spinner.hide("Erro: " + err.error.status + ". Contate nosso suporte no Contato@rendafixa.rocks.", 'OK');
                            })
                        }
                        else if(result["investimento"]){
                            let pl = {
                                'objectId': titulo.objectId || 'adblocker',
                                'id': titulo.id,
                                'nome': result.name,
                                'email': result.email,
                                'telefone': result.tel,
                                "pais": result.pais
                            }
                            this.http.post(this.constants.get('leadGeral'), pl).subscribe(val => {
                                if(titulo.url){
                                    this.spinner.hide();
                                    return this.investExternally(titulo.url);
                                } else {
                                    this.corretorasService.corretoras.forEach((corretora, i) => {
                                        if (titulo.corretora.toLocaleLowerCase() == corretora.nome.toLocaleLowerCase()) {
                                            if( corretora.url &&
                                                corretora.url.indexOf('{nome}') > -1 && 
                                                corretora.url.indexOf('{email}') > -1 && 
                                                corretora.url.indexOf('{telefone}') > -1
                                            ){
                                                corretora.url = corretora.url.replace('{nome}', result.name)
                                                corretora.url = corretora.url.replace('{email}', result.email)
                                                corretora.url = corretora.url.replace('{telefone}', result.tel)
                                                this.investExternally(corretora.url)
                                                return this.spinner.hide()
                                            } else {
                                                this.spinner.hide();
                                                return this.investExternally(corretora.url)
                                            }
                                        } else if(i+1 == this.corretorasService.corretoras.length && titulo.corretora.toLocaleLowerCase() != corretora.nome.toLocaleLowerCase()){
                                            return this.spinner.hide("Erro Interno de Corretora. Contate nosso suporte no Contato@rendafixa.rocks, por gentileza.", 'OK');
                                        } 
                                    })
                                }
                            }, err=> {
                                this.spinner.hide("Erro: " + err.error.status + ". Contate nosso suporte no Contato@rendafixa.rocks.", 'OK');
                            })
                        }
                        else{
                            for (const corretora of this.corretorasService.corretoras) {
                                if (titulo.corretora.toLocaleLowerCase() == corretora.nome.toLocaleLowerCase()) {
                                    corretora.url = corretora.url.replace('{nome}', result.name)
                                    corretora.url = corretora.url.replace('{email}', result.email)
                                    corretora.url = corretora.url.replace('{telefone}', result.tel)
                                    this.investExternally(corretora.url)
                                    this.spinner.hide()
                                    break;
                                }
                            }
                        }
                    }
                })
            } else {
                if (titulo.emissor && !titulo.corretora) {
                    this.spinner.hide()
                    titulo.corretora = titulo.emissor
                }
                return new Promise((resolve, reject) => {
                    console.log('entrou investservice.invest');
                    if (!titulo.corretora && titulo.carteira === "tesourodireto") {
                        this.spinner.hide();
                        return this.investExternally("https://apprendafixa.orama.com.br/novo-cadastro/novo-cliente/4257/#/?utm_source=aplicativo_rendafixa&utm_campaign=clique_interno_apprendafixa&utm_medium=aplicativo_rendafixa");
                    }
                    else if (!titulo.corretora && titulo.carteira !== "tesourodireto") {
                        this.spinner.hide()
                        return reject("Invalid asset");
                    }
                    this.investByTitulo(titulo).then(resolve).catch(reject);
                    this.spinner.hide()
                });
            }
        } else {
            this.spinner.hide();
            if(titulo.info){
                titulo.logo = [titulo.info.card_logo]
                titulo.cores = [titulo.info.card_background_color]
                titulo.investimento = titulo.manager
            } else {
                titulo.logo = [titulo.logo]
                titulo.cores = [titulo.r == 'Magnetis' ? 'linear-gradient(135deg, rgb(63, 76, 85) 0%, rgb(29, 34, 37) 100%)' : titulo.r == "Warren" ? 'linear-gradient(45deg, rgb(239, 102, 150) 0px, rgb(238, 46, 93) 70%)' : 'linear-gradient(135deg, #0969b4 1%, #2196f3 100%)']
                titulo.investimento = titulo.r
                titulo.risco = {}
                titulo.risco.text = titulo.tc
            }
            const dialogRef = this.dialog.open(InvestFillDataDialogComponent, {
                width: '900px',
                maxWidth: '95vw',
                data: {
                    titulo: titulo,
                }
            }).afterClosed().subscribe((result: any) => {
                if (result && result.name && result.email && result.tel) {
                    this.spinner.show();      
                    if(result["investimento"]){
                        let pl = {
                            'objectId': titulo.objectId || 'adblocker',
                            'id': titulo.investimento + ' ' + titulo.risco.text,
                            'nome': result.name,
                            'email': result.email,
                            'telefone': result.tel,
                            "pais": result.pais
                        }
                        this.http.post(this.constants.get('leadGeral'), pl).subscribe(val => {
                            for (const corretora of this.corretorasService.corretoras) {
                                if (titulo.investimento.toLocaleLowerCase() == corretora.nome.toLocaleLowerCase()) {
                                    if( corretora.url &&
                                        corretora.url.indexOf('{nome}') > -1 && 
                                        corretora.url.indexOf('{email}') > -1 && 
                                        corretora.url.indexOf('{telefone}') > -1
                                    ){
                                        corretora.url = corretora.url.replace('{nome}', result.name)
                                        corretora.url = corretora.url.replace('{email}', result.email)
                                        corretora.url = corretora.url.replace('{telefone}', result.tel)
                                        this.investExternally(corretora.url)
                                        this.spinner.hide()
                                        break;
                                    }else if(titulo.info && titulo.info.url){
                                        this.spinner.hide();
                                        this.investExternally(titulo.info.url);
                                    } 
                                    else if (!corretora.url && !result.investimento.info.url){
                                        this.spinner.hide("Erro: Interno de Corretora. Contate nosso suporte no Contato@rendafixa.rocks, por gentileza.", 'OK');
                                    } else {
                                        this.spinner.hide();
                                        this.investExternally(corretora.url)
                                    }
                                }
                            }
                        }, err=> {
                            this.spinner.hide("Erro: " + err.error.status + ". Contate nosso suporte no Contato@rendafixa.rocks.", 'OK');
                        })
                    }
                }
            })
        }
    }
}
