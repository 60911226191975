import {Injectable} from '@angular/core';
import {Connector} from './connector';
import {LocalStorageService} from './localstorage.service';
import {ProgressiveServiceBase} from './progressive.service.base';
import {InvestmentsService} from './investments.service';
import {TituloCard} from './../model/titulo-card';

export interface Address {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string; 
    uf: string;
    unidade: string; 
    ibge: string;
    gia: string;
}

export class QueryType {
    public static get Destaque() {return 1;}
    public static get MaiorRentabilidade() {return 2;}
    public static get Normal() {return 3;}
}

export interface FeaturedInvestment {
    vencimento?: string,
    vl?: number,
    vb?: number,
    rpp?: number,
    am?: number,
    qtdMinima?: number,
    qualificado?: boolean,
    tir?: number,
    tt?: number,
    idx?: string,
    rpd?: number,
    liquidez?: string,
    total?: number,
    rbp?: number,
    a?: string,
    logo?: any[],
    incentivada?: boolean,
    tx_prog?: boolean,
    du?: number,
    tipo?: string,
    chat?: boolean,
    avista_id?: number,
    rbm?: number,
    rld?: number,
    amortizacao?: string,
    rla?: number,
    rbd?: number,
    rlm?: number,
    rba?: number,
    vpp?: number,
    prlt?: number,
    rating?: string,
    carencia?: string,
    corretora?: string,
    rlp?: number,
    cores?: any[],
    vrl?: number,
    dc?: number,
    preco?: number,
    _id?: any,
    tp_d?: string,
    vir?: number,
    taxa?: string,
    nr?: number,
    emissor?: string,
    agencia?: string,
    investir?: boolean,
    juros?: number,
    teq?: string
}

export interface FeaturedBanner {
    active: boolean,
    sponsor: string,
    asset?: string,         // CDB, RDB
    category?: string,      // rendafixa, robodeinvestimentos, fundos, ...,
    type: "url" | "route",
    action_url?: string,
    image_url?: string
}


@Injectable()
export class RendafixaService extends ProgressiveServiceBase {
    constructor(
        private connector: Connector, 
        protected localStorage: LocalStorageService,
        private investmentsService: InvestmentsService
    ) {
        super(localStorage);
    }

    public featuredInvestmentsDestaque(): Promise<TituloCard[]> {
        return this.investmentsService.queryInvestments({
            type: QueryType.Destaque,
            limit: 5
        });
    }

    public featuredInvestmentsMaiorRentabilidade(): Promise<TituloCard[]> {
        return this.investmentsService.queryInvestments({
            type: QueryType.MaiorRentabilidade,
            limit: 5
        });
    }

    public featuredInvestmentsQuery(corretora: string | string[], random?: boolean): Promise<TituloCard[]> {
        const c: string[] = Array.isArray(corretora) ? corretora : [corretora];
        return this.investmentsService.queryInvestments({
            type: QueryType.Normal,
            limit: 5,
            corretora: c,
            random: random
        });
    }

    public getInit(hash?: string): Promise<any> {
        hash = hash ? hash : "";
        const url = this.connector.constants.get("getInit").replace("{hash}", hash);
        return this.connector.get(url);
    }

    public getInitObject(hash?: string): Promise<any> {
        if (!hash) hash = this.localStorage.getItem('init-hash');
        return new Promise<any>((resolve, reject) => {
            if (hash) {
                const cached = this.localStorage.get("getInit", true);
                if (!cached) hash = undefined;
            }
            this.getInit(hash).then(response => {
                let data = this.parseValue(response, "getInit") || {};
                hash = (data.resultados || {}).hash;
                if (hash) {
                    this.localStorage.setItem('init-hash', hash);
                } else {
                    const handler = this.catch("getInit", resolve, reject)
                    return handler({status: 304, json: function () {return "Not modified"; }});
                }
                resolve(this.save("getInit", data));
            }).catch(
                this.catch("getInit", resolve, reject)
            );
        });
    }

    public postThirdPartyInvestimentInvestSend(name: string, tel: string, email: string, _id: any, pais: string): Promise<any> {
		if (!(typeof _id === 'string' || _id instanceof String)) {
			_id = _id.$oid;
		}
		return this.connector.api.post("privateInvestSend", {
			"nome": name,
			"telefone": tel,
			"email": email,
            "_id": _id,
            "pais": pais
		});
    }
    
    public get featuredBanners(): Promise<any> {
        return this.connector.api.get("featuredBanners");
    }

    public getAddressByCEP(cep: string): Promise<any> {
        return this.connector.api.get({id: "cep", params: {cep: cep}});
    }
}
