export class RuntimeInjection {
    private constructor() {}
    public static injectScript(source: string, _id?: string) {
        if (_id && document.getElementById(_id)) {
            return;
        }
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.async = true;
            script.src = source;
            if (_id) script.id = _id;
            script.addEventListener('load', resolve);
            script.addEventListener('error', () => reject('Error loading script.'));
            script.addEventListener('abort', () => reject('Script loading aborted.'));
            document.head.appendChild(script);
        });
    }
}
