import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';

import { RequestCacheService } from './requestCache.service';

const TTL = 10;
@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
      private cache: RequestCacheService
    ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cachedResponse = this.cache.get(request.url);
    return cachedResponse
      ? Observable.of(cachedResponse)
      : this.sendRequest(request, next);
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).do(event => {
      if (event instanceof HttpResponse) {
        this.cache.set(req.url, event, TTL);
      }
    });
  }
}
