
export interface EnvironmentType {
  production: boolean;
  platform: string;
  routeTracing: boolean;
  appleID: string;
  googleID: string;
}

export const environment: EnvironmentType = {
  production: true,
  platform: "web",
  routeTracing: false,
  appleID: 'com.ionicframework.rendafixa997647.applesignin',
  googleID: '1039055682578-ac9in92ed3q7vllpc9il1h8h881hq7uk.apps.googleusercontent.com'
};
