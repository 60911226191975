import {Inject, Injectable} from '@angular/core';
import {Connector} from './connector';
import {FutureService} from './future.service';
import {CorretoraBase, Corretora} from './../model/corretora';
import { LatinKiller } from '../utils/latin-killer';
import { Constants } from './constants';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

interface _Corretora extends CorretoraBase {
    url?: string,
    card_logo?: string,
    card_background_color?: string;
}

const avistaDescription = "A Avista S.A. - Crédito, Financiamento e Investimento é a mais nova empresa do Grupo Avista e agora fazemos parte de um grupo de empresas que foram desenvolvidas para prover produtos e serviços financeiros de qualidade aos clientes Avista. Agora foi criada a Avista Financeira para prover serviços financeiros complementares aos clientes Avista.";

@Injectable()
export class CorretorasService extends FutureService {
    private corretorasList: Corretora[] = [];
    private _corretoras: {[key: string]: Corretora} = {};
    constructor(
        @Inject(DOCUMENT) private document: Document        
    ) {
        super();
    }

    private __applyNull(obj, key) {
        if (obj[key] === undefined) {
            obj[key] = null;
        }
    }

    public __getCorretoras(result): void {
        const corretoras: any = {};
        const corretorasList: Corretora[] = [];
        result = result["resultados"];
        result = result["corretoras"];
        (<Array<_Corretora>>result).forEach((corretora: _Corretora) => {
            this.__applyNull(corretora, "card_background_color");
            this.__applyNull(corretora, "card_logo");
            this.__applyNull(corretora, "url");
            const nom = (corretora.nom || "").toUpperCase();
            const nome = (corretora.nome || "").toUpperCase();
            corretoras[corretora.numero] = corretora;
            corretoras[nom] = corretora;
            corretoras[nome] = corretora;
            corretoras[LatinKiller.kill(nom)] = corretora;
            corretoras[LatinKiller.kill(nome)] = corretora;
            corretorasList.push(<Corretora>corretora);
        });
        
        // Hard fixes
        if ("AVISTA INVESTIMENTOS" in corretoras) {
            corretoras["AVISTA"] = corretoras["AVISTA INVESTIMENTOS"];
            corretoras["AVISTA FINANCEIRA"] = corretoras["AVISTA"];
        } else if ("AVISTA FINANCEIRA" in corretoras) { 
            corretoras["AVISTA"] = corretoras["AVISTA FINANCEIRA"]; 
            corretoras["AVISTA INVESTIMENTOS"] = corretoras["AVISTA"]; 
        }
        if ("AVISTA" in corretoras) {
            corretoras["AVISTA"].description = avistaDescription;
            corretoras["AVISTA"].investir = function() {
                var windowOpener = this.document.open("https://www.avistafinanceira.com.br/parceria-app-renda-fixa-avista-financeira");
		        if(!windowOpener) this.document.location.href = "https://www.avistafinanceira.com.br/parceria-app-renda-fixa-avista-financeira"; //windowOpener dando falso é sinal de pop-up blocker
            }
        }

        this._corretoras = corretoras;
        this.corretorasList = corretorasList;
        this.init();
        this.inform();
    }

    getCorretora(key: string | number): Corretora {
        const _key: any = key;
        key = (_key || {}).toUpperCase ? _key.toUpperCase() : key;
        const corretora = this._corretoras[key];
        if (!corretora && key) {
            return this._corretoras[key.toString().split(" ")[0]];
        }
        return corretora
    }

    getCorretoraSafe(key: string | number): Corretora {
        const corretora = this.getCorretora(key);
        if (corretora) {
            return corretora;
        }
        const _key: string = key ? key.toString() : null;
        return {
            card_background_color: null,
            card_logo: null,
            nom: _key,
            nome: _key,
            numero: null,
            url: null
        }
    }

    public get corretoras(): Corretora[] {
        return this.corretorasList;
    }

    public getCorretoraLogo(corretora: Corretora, color?: boolean): string
    public getCorretoraLogo(key: string | number, color?: boolean): string
    public getCorretoraLogo(key: any, color?: boolean): string {
        const base = color ? "/assets/logo/card-color/" : "/assets/logo/card/";
        let card_logo;
        if (key.card_logo !== undefined) {
            card_logo = key.card_logo;
        } else {
            card_logo = this.getCorretoraSafe(key).card_logo;
        }
        return card_logo ? base + card_logo : null;
    }
}

@Injectable()
export class CidadesService {
    constructor(private http: HttpClient, private connector: Connector) { }
    getCidades(url, uf) {
        return this.http.get(this.connector.constants.get(url) + uf)
        .toPromise()
		    .then(response => response)
		    .catch(this.handleErrorPromise);
    }

    private handleErrorPromise (error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }
}

@Injectable()
export class CountriesService {
    constructor(private http: HttpClient, 
        private constants: Constants) { }
    
    public getDdi(){
        return this.http.get(this.constants.get('getDdi'));
    }
}