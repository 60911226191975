import {NgModule} from "@angular/core";
import {CurrencyMaskModule as _CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
    imports: [
        _CurrencyMaskModule
    ],
    exports: [
        _CurrencyMaskModule
    ]
})
export class CurrencyMaskModule {}