// Modulos Angular

import { registerLocaleData, CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';

// Modulos NGX

import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCaptchaModule } from 'ngx-captcha';
import { QuicklinkModule, QuicklinkStrategy} from 'ngx-quicklink';

// Outros módulos

import { AppRoutesModule } from './app.routes';
import { DirectivesModule } from './directives/directives.module';
import { ProvidersModule } from './providers/providers.module';
import { PagesModule } from './pages/pages.module';
import { AppComponent } from './app.component';
import { IsUserLoggedGuardService } from './providers/isUserLoggedGuard.service';
import { Interceptor } from './providers/interceptor.service';
import { RequestCacheService } from './providers/requestCache.service';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';

// Usados neste arquivo

import { GoogleLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';

registerLocaleData(localePt, 'pt-BR');


const dbConfig: DBConfig  = {
  name: 'fundosDB',
  version: 2,
  objectStoresMeta: [{
    store: 'fundos',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } },
      { name: 'email', keypath: 'email', options: { unique: false } }
    ]
  },
  {
    store: 'fundos_nomes',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } },
      { name: 'cnpj', keypath: 'cnpj', options: { unique: false } }
    ]
  },
  ]
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "left",
    allowNegative: true,
    decimal: ",",
    precision: 2,
    prefix: "R$ ",
    suffix: "",
    thousands: "."
};

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    RouterModule.forRoot([], {preloadingStrategy: QuicklinkStrategy, onSameUrlNavigation: 'reload'}),
    AppRoutesModule,
    ProvidersModule.forRoot(),
    NgxMaskModule.forRoot(),
    DirectivesModule,
    PagesModule,
    CommonModule,
    QuicklinkModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    SocialLoginModule,
    CurrencyMaskModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1039055682578-ac9in92ed3q7vllpc9il1h8h881hq7uk.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    NgxImageCompressService,
    RequestCacheService,
    IsUserLoggedGuardService
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
}
