import { isPlatformBrowser } from '@angular/common';
import { OnInit, OnDestroy, AfterViewInit, Directive, Inject, PLATFORM_ID } from '@angular/core';

export interface ChartInterface {
    setOption(options: any): void;
    resize(): void
}

export interface ChartRef {
    options: any;
    init(refresher: (options: any) => void): void;
    isReady(): boolean;
}
  
export abstract class AbstractChartRef implements ChartRef {
    public abstract options;
    public ready: boolean = false;
    public isReady(): boolean {return !!this.refresher && this.ready};
    private refresher: (options: any) => void;
    private _onReady: (ref: AbstractChartRef) => void;
    public onReady(callback: (ref: AbstractChartRef) => void): void {
        this._onReady = callback;
    }
    public init(refresher: (options: any) => void): void {
        this.refresher = refresher;
        if (this.isReady() && this._onReady) this._onReady(this);
    }
    public refresh(): void {
        if (this.isReady()) this.refresher(this.options);
    }
}

@Directive()
export class GraficoBase implements OnInit, OnDestroy, AfterViewInit {
    private chartInstance: ChartInterface;
    constructor(
        @Inject(PLATFORM_ID) private platformId: string
    ) {
    }

    ngOnInit() {
        if(isPlatformBrowser(this.platformId)) window.addEventListener('resize', this.resize);
    }

    ngAfterViewInit () {
        setTimeout(() => this.init(), 1000);
    }

    ngOnDestroy(): void {
        if(isPlatformBrowser(this.platformId)) window.removeEventListener('resize', this.resize);
    }

    protected onResize() {

    }

    protected onInit() {

    }

    private init() {
        if (this.chartInstance != null && this.chartInstance != undefined) {
            this.onInit();
        }
    }

    private resize() {
        if (this.chartInstance != null && this.chartInstance != undefined) {
            this.onResize();
        }
    }

    private __refreshOptions(options) {
        setTimeout(() => {
            try {
                this.chartInstance.setOption(options);
                this.chartInstance.resize();
            } catch (err) {
                // skip
            }
        });
    }

    protected setOptions(options, defaultOptions?) {
        if (defaultOptions) {
            Object.keys(defaultOptions).forEach((key: string) => {
                options[key] = defaultOptions[key];
            });
        }
        this.__refreshOptions(options);
    }

    public captureChartInstance(instance: ChartInterface) {
        this.chartInstance = instance;
    }
}
