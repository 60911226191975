import {Component, OnInit} from '@angular/core';

export interface NoFooter {
	noFooterFlag: boolean;
}

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	private now: Date;
	constructor() { }

	ngOnInit() {
		this.now = new Date();
	}

	get currentYear(): number {
		return this.now.getFullYear();
	}
}
