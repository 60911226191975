import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { FlexLayoutModule } from "@angular/flex-layout";
export { FileUploadService, FileStatus, FileSelectedEvent, readFileToDataUrl } from "./file-upload.service";

import { Ng4FilesModule } from './ng4-files.module';
import { Ng4FilesStatus, Ng4FilesSelected } from "./index";
import { FileUploadService } from "./file-upload.service";
import { FileUploadDialogComponent } from "./components/file-upload-dialog/file-upload-dialog.component";


@NgModule({
    declarations: [FileUploadDialogComponent],
    imports: [Ng4FilesModule, MatDialogModule, MatButtonModule, FlexLayoutModule],
    exports: [Ng4FilesModule],
    providers: [FileUploadService]
})
export class FileUploadModule {

}