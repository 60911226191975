<!-- RESOLUÇÃO ACIMA DE 600PX TABLET E DESKTOP-->
<section class="carousel-ong">
    <div class="co-carousel">

        <!-- CAROUSEL DE INVESTIMENTO DESKTOP -->
        <owl-carousel-o *ngIf="loaded" style="touch-action: manipulation" [options]="carouselOptions">
            <ng-container *ngFor="let item of carouselInvestmentData">
                <ng-template carouselSlide>
                    <div class="co-background" [style.background]="'url('+ '../../../../assets/background-images/' + item.image_name + ')'">
                        <div class="co-container">
                            <div class="co-texts">
                                <div class="co-title">
                                    <span>{{item.title}}</span>
                                </div>
                                <div class="co-desc">
                                    <span>{{item.desc}}</span>
                                </div>
                                <div class="co-subdesc">
                                    <span>{{item.sub_desc}}</span>
                                </div>
                                <div (click)="moreInfo(item)" class="co-btn-more">
                                    <button  mat-button>{{item.button}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>

<!-- RESOLUÇÃO ABAIXO DE 600PX - MOBILE -->
<section style="touch-action: manipulation;" class="carousel-ong responsive">
    

    <!-- CAROUSEL DE INVESTIMENTO MOBILE NOVO DESIGN -->
    <div class="co-carousel-mb" style="padding:0 0 0 10px;">
        <owl-carousel-o *ngIf="loaded" [options]="carouselOptionsMobile">
            <ng-container *ngFor="let item of carouselInvestmentDataMobile">
                <ng-template carouselSlide [width]="150">
                    <div (click)="moreInfo(item)" class="co-background-mb" [style.background]="'url('+ '../../../../assets/background-images/' + item.image_name_mobile + ')'">
                        <div class="co-container-mb">
                            <div class="co-texts-mb">
                                <div class="co-desc-mb">
                                    <span>{{item.desc}}</span>
                                </div>
                                <div class="co-btn-more-mb">
                                    <button mat-button>
                                        VER TODOS
                                        <mat-icon style="font-size:16px; margin-top:7px; margin-left:10px;">arrow_forward</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
