import { Component, OnInit, ViewChild, HostListener } from '@angular/core';

import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';

@Component({
  selector: 'app-carousel-ong',
  templateUrl: './carousel-ong.component.html',
  styleUrls: ['./carousel-ong.component.scss']
})
export class CarouselOngComponent implements OnInit {
  public loaded: boolean = false;
  public carouselOptions: OwlOptions = {
    dots: true,
    nav: true,
    items: 1,
  }
  public carouselOptionsMobile: OwlOptions = {
    dots: false,
    nav: false,
    items: 2,
    autoWidth: true,
  }
  public carouselInvestmentDataMobile: any[] = [
    {
      "image_name_mobile":'ong-investimento-mobile-slide1.webp',
      "desc":'Maiores rentabilidades de renda fixa',
      "link": '/app/investimentos/rendafixa?tipo=ALL&valinv=1000&vencimentoInicio=180&vencimentoFim=720'
    },
    {
      "image_name_mobile":'ong-investimento-mobile-slide2.webp',
      "desc":'Investimentos para sua reserva de emergência.',
      "link": '/app/investimentos/rendafixa?valinv=100&liquidez=true'
    },
    {
      "image_name_mobile":'ong-investimento-mobile-slide3.webp',
      "desc":'Investimentos mais seguros do mercado.',
      "link": '/app/investimentos/rendafixa?valinv=500&fgc=true'
    },
    {
      "image_name_mobile":'ong-investimento-mobile-slide4.webp',
      "desc":'Investimentos para longo prazo.',
      "link": '/app/investimentos/rendafixa?tipo=ALL&valinv=5000&liquidez=false'
    }
  ]
  public carouselInvestmentData: any[] = [
    {
      "title":'INVISTA COM A GENTE',
      "desc":'Maiores rentabilidades de renda fixa',
      "sub_desc":'Comece a partir de R$ 1 mil reais e resgate o dinheiro em menos de 2 anos.',
      "image_name":'investment-slide-1.webp',
      "button":'ver investimento',
      "link": '/app/investimentos/rendafixa?tipo=ALL&valinv=1000&vencimentoInicio=180&vencimentoFim=720'
    },
    {
      "title":'INVISTA COM A GENTE',
      "desc":'Investimentos para sua reserva de emergência',
      "sub_desc":'Comece a partir de R$ 100 reais e resgate o dinheiro quando precisar.',
      "image_name":'investment-slide-2.webp',
      "button":'ver investimento',
      "link": '/app/investimentos/rendafixa?valinv=100&liquidez=true'
    },
    {
      "title":'INVISTA COM A GENTE',
      "desc":'Investimentos mais seguros do mercado',
      "sub_desc":'Comece a partir de R$ 500 reais e tenha a segurança do Fundo Garantidor de Crédito.',
      "image_name":'investment-slide-3.webp',
      "button":'ver investimento',
      "link": '/app/investimentos/rendafixa?valinv=500&fgc=true'
    },
    {
      "title":'INVISTA COM A GENTE',
      "desc":'Investimentos para longo prazo',
      "sub_desc":'Comece a partir de R$ 5 mil reais e resgate o dinheiro no vencimento.',
      "image_name":'investment-slide-4.webp',
      "button":'ver investimento',
      "link": '/app/investimentos/rendafixa?valinv=5000&liquidez=false'
    }
  ]
  constructor(
    private _router: Router
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.loaded = true;
    }, 500)
  } 
  
  public moreInfo(item){
    this._router.navigateByUrl(item.link);
  }
}
