<section class="invest-fill-data-dialog-overall">
    <div [ngStyle]="{'background': data.titulo.cores[0]}" class="image-side">
        <img [src]="'/assets/logo/card/' + data.titulo.logo[0]" [alt]="data.titulo.logo[0]">
    </div>
    <div [ngClass]="isDarkMode ? 'dark-terciary-color' : ''" class="info-side">
        <form [formGroup]="fillDataDialogForm">
            <span class="description" [ngClass]="isDarkMode ? 'dark-primary-font' : ''">Preencha seus dados abaixo para continuar investindo<span *ngIf="data.titulo.corretora"> com {{data.titulo.corretora}}</span>.</span>
            <mat-form-field [ngClass]="isDarkMode ? 'dark-mat-form-field' : ''">
                <mat-label>Nome</mat-label>
                <input matInput required placeholder="Escreva seu nome" formControlName="name" />
            </mat-form-field>
            <div class="phone-section">
                <mat-form-field class="code-input" [ngClass]="isDarkMode ? 'dark-mat-form-field' : ''">
                    <mat-label>Código</mat-label>
                    <mat-select formControlName="ddi" required>    
                        <mat-option [ngStyle]="{'background': isDarkMode ? '#212121' : 'white', 'color': isDarkMode ? 'white' : '#323232'}" 
                        (click)="setThis($event, country)" *ngFor="let country of countries" [value]="country.ddi">
                            <div style="display: flex; align-items: center;">
                                <img [src]="country.url_bandeira" type="image/webp"/> <span style="margin-left: 10px;">+{{country.ddi}}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="phone-input" [ngClass]="isDarkMode ? 'dark-mat-form-field' : ''">
                    <mat-label>Telefone</mat-label>
                    <input matInput [errorStateMatcher]="matcher" required [mask]="countryMask" 
                    formControlName="tel" placeholder="(xx) xxxxx-xxxx" />
                    <mat-hint *ngIf="verifyingPhone && phoneTouched">Verificando telefone...</mat-hint>
                    <mat-error *ngIf="phoneTouched && !phoneVerified && !verifyingPhone">{{errorMessage || 'Erro ao verificar telefone.'}}</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field [ngClass]="isDarkMode ? 'dark-mat-form-field' : ''">
                <mat-label>E-mail</mat-label>
                <input matInput required placeholder="pessoa@empresa.com" formControlName="email" />
            </mat-form-field>
            <div class="buttons-row">
                <button  mat-dialog-close [ngClass]="isDarkMode ? 'dark-primary-font' : ''" mat-button>Cancelar</button>
                <button mat-raised-button color="accent" (click)="send()" [disabled]="!fillDataDialogForm.valid || !phoneVerified">Confirmar</button>
            </div>
        </form>
    </div>
</section>