import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AssessorService } from './assessor.service';
import { AssessorLinksDialogComponent } from './assessor-links-dialog/assessor-links-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MasksModule } from '../../directives/textmask/textmask.module';
import { MongeralService } from '../../providers/mongeral.service';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        CurrencyMaskModule,
        MasksModule,
        ReactiveFormsModule,
        MatSelectModule
    ],
    declarations: [AssessorLinksDialogComponent],
    providers: [
        AssessorService,
        MongeralService
    ],
    exports: []
})
export class AssessorModule {

}
