import { Dth } from '../utils/dth';

export interface UserSuitability {
    nome: string;
	telefone: string;
	valinv: number;
	aporte?: number;
	p1: true;
	p2: number;
	p3: number;
	p4: Array<number>;
	p5: number;
	p6: number;
	p7: number;
	p8: number;
	p9: boolean;
	p10: number;
	p11: number;
	p12: number;
	p13: number;
	p14: number;
	p15: number;
}

export interface UserPerfil {
    gender?: string;
    birth?: Date;
    city?: string;
    state?: string;
    firstName?: string;
    lastName?: string;
}

export interface UserInterface extends UserPerfil {
    name?: string;
    email?: string;
    picture?: string;
    token?: string;
    firstName?: string;
    lastName?: string;
    _id?: string;
}

export class User implements UserInterface {
    public constructor (
        public name?: string,
        public email?: string,
        public picture?: string,
        public token?: string,
        public firstName?: string,
        public lastName?: string,
        public _id?: string,
        public gender?: string,
        public birth?: Date,
        public city?: string,
        public state?: string,
    ) {
        if (this.birth) {
            this.birth = Dth.from(this.birth).date
        };
    }

    public static from(value: UserInterface): User {
        return new this(
            value.name, 
            value.email, 
            value.picture, 
            value.token, 
            value.firstName, 
            value.lastName,
            value._id,
            value.gender,
            value.birth,
            value.city,
            value.state
        );
    }

    public toString(): string {
        return JSON.stringify(this);
    }

    public get logged (): boolean {
        return !!this.token;
    }

    public getAge(): number {
        if (!this.birth) {
            return NaN;
        }
        return new Date().getFullYear() - this.birth.getFullYear();
    }

    public get age(): number {
        return this.getAge();
    }
};
