import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';

@Component({
  selector: 'app-sabe-como-investir',
  templateUrl: './sabe-como-investir.component.html',
  styleUrls: ['./sabe-como-investir.component.scss']
})
export class SabeComoInvestirComponent implements OnInit {
  
  public valinv;
  public loaded: boolean = false;
  public sciBodyTitle: string = 'No app ou na web você encontra o melhor investimento';
  public sciBodySubTitle: string = 'Você pode buscar o melhor investimento apenas preenchendo o valor que deseja investir.';
  public mobileSlide: any[] = [
    {
      image: '/assets/icons/new-home-icons/mobile-slide-1.webp'
    },
    {
      image: '/assets/icons/new-home-icons/mobile-slide-2.webp'
    },
    {
      image: '/assets/icons/new-home-icons/mobile-slide-3.webp'
    }
  ]
  public cardsParceiros = [
    {
      imagem: '/assets/logo/card-color/avista.png',
    },
    {
      imagem: '/assets/logo/card-color/modalmais.png',
    },
    {
      imagem: '/assets/logo/card-color/btg.png',
    },
    {
      imagem: '/assets/logo/card-color/orama.png',
    },
    {
      imagem: '/assets/logo/card-color/genial.png',
    },
    {
      imagem: '/assets/logo/card-color/bancoin.png',
    },
    {
      imagem: '/assets/logo/card-color/hurst.png',
    }
  ]
  public carouselOptionsImageMobile: OwlOptions = {
    dots: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false,
    autoWidth: true,
    loop: true,
  }
  public carouselOptions: OwlOptions = {
    nav: false,
    dots: false,
    autoWidth: true,
    loop: true
  }
  constructor(
    private _router: Router
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit(){
    setTimeout(()=>{
      this.loaded = true;
    }, 500)
  }
  public flushParceiros(){
    for (let i = this.cardsParceiros.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.cardsParceiros[i], this.cardsParceiros[j]] = [this.cardsParceiros[j], this.cardsParceiros[i]];
    }
  }

  public invest(valinv){
    this._router.navigateByUrl('/app/investimentos/rendafixa?tipo=ALL&valinv=' + valinv);
  }
}
