<div class="nh-howto">
    <div class="nh-container">
        <div class="step-by-step">
            <span>PASSO A PASSO</span>
        </div>
        <div style="margin-top: 20px;" class="nh-title">
            <span>Como o App Renda Fixa funciona?</span>
        </div>
        <div class="nh-line">
            <div class="nh-col-4" *ngFor="let i of howto">
                <div class="nh-content">
                    <img [src]="i.icon">
                    <div class="nh-title"><span>{{i.title}}</span></div>
                    <div class="nh-subtitle"><span>{{i.subtitle}}</span></div>
                    <div class="nh-btn" (click)="activateFunction(i)"><button mat-stroked-button color="primary">{{i.btn}}</button></div>
                </div>
            </div>
        </div>
        <div class="nh-line responsive">
            <owl-carousel-o *ngIf="loaded" [options]="carouselOptions">
                <ng-container *ngFor="let i of howto">
                    <ng-template carouselSlide>
                        <div class="nh-content">
                            <img [src]="i.icon">
                            <div class="nh-title"><span>{{i.title}}</span></div>
                            <div class="nh-subtitle"><span>{{i.subtitle}}</span></div>
                            <div class="nh-btn"><button mat-stroked-button (click)="activateFunction(i)" color="primary">{{i.btn}}</button></div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>