import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RequestLoaderComponent} from './request.loader';
import {RequestLoaderService} from './request.loader.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule
    ],
    declarations: [RequestLoaderComponent],
    providers: [
        RequestLoaderService
    ],
    exports: [RequestLoaderComponent]
})
export class RequestLoaderModule {

}

