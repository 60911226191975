import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../providers/user.service';
import { Router } from '@angular/router';
import { Constants } from '../providers/constants';

@Injectable()
export class RankingService {
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private router: Router,
        private constants: Constants
    ) {
        this.api_url = this.constants.getUrl() + '/vn';
    }
    private api_url;
    private lastUrl;
 

    getUserEmail() {
        return this.userService.user.email
    }

    getLastUrl() {
        if (!this.lastUrl) {
            this.lastUrl = "/app/ranking"
        }
        return this.lastUrl
    }

    setLastUrl() {
        this.lastUrl = this.userService.currentUrl;
    }

    getRankingEx() {
        // retorna a lista de corretoras com o ranking global 
        return this.http.get(this.api_url + '/get_ranking_ex', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token': this.userService.token
            })
        });
    }

    getDetailRankingCorretora(params) {
        // recebe o parâmetro "corretora" contendo o nome da corretora e devolve o ranking consolidado da corretora
        return this.http.post(this.api_url + '/get_detail_ranking_corretora_ex', params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token': this.userService.token
            })
        });
    }

    getRelatedRankings(params) {
        return this.http.post(this.api_url + '/get_related_rankings', params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token': this.userService.token
            })
        });
    }

    getDetailRankingCorretoraEx(params) {

        return this.http.post(this.api_url + '/get_detail_ranking_corretora', params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token': this.userService.token
            })
        });
    }

    getCommentsRankingCorretora(params) {
        // recebe o parâmetro "corretora" contendo o nome da corretora e o parâmetro "pagina" contendo um número inteiro que serve como paginação. Esse método devolve apenas 20 comentários por página
        return this.http.post(this.api_url + '/get_comments_ranking_corretora', params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token': this.userService.token
            })
        });
    }

    get_ranking_avaliar(params) {
        // recebe o "email" e a "corretora" e devolve a avaliação do usuário para a corretora
        return this.http.post(this.api_url + '/get_ranking_avaliar', params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token': this.userService.token
            })
        });
    }
    
    avaliar_corretora(params) {
        // recebe os campos:  'email',  'corretora',  'abertura_conta' - número inteiro de 1 a 5 correspondente à quantidade de estrelas, 'variedade_investimentos' - número inteiro de 1 a 5 correspondente à quantidade de estrelas, 'suporte_cliente' - número inteiro de 1 a 5 correspondente à quantidade de estrelas, 'plataforma_estavel' - número inteiro de 1 a 5 correspondente à quantidade de estrelas, 'facilidade_investir' - número inteiro de 1 a 5 correspondente à quantidade de estrelas,  'custos' - número inteiro de 1 a 5 correspondente à quantidade de estrelas,  'aplicativo_mobile' - true ou false,  'plataforma_digital' - true ou false, 'msg' - é o campo de comentário. Tamanho máximo de 350 caracteres
        return this.http.post(this.api_url + '/avaliar_corretora', params, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token': this.userService.token
            })
        });
    } 
    
}
