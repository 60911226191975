import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mini-cards',
  templateUrl: './mini-cards.component.html',
  styleUrls: ['./mini-cards.component.scss']
})
export class MiniCardsComponent implements OnInit {
  public path: string = '/assets/icons/new-home-icons/'
  public cards: any[] = [
    {
      image: this.path + 'logo_cvm.png',
      desc: 'Todo o processo é fiscalizado pela Comissão de Valores Mobiliários, o “xerife” do Mercado Financeiro.'
    },
    {
      image: this.path + 'logo_anbima.png',
      desc: 'O App Renda Fixa segue as orientações da ANBIMA. Entidade que ajuda a autorregular as empresas.'
    },
    {
      image: this.path + 'logo_bcb.png',
      desc: 'O Bacen é uma entidade responsável por garantir a estabilidade econômica do país e regulamentação do sistema financeiro.'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

}
