

export class Idx {
    static readonly pre = "pre";
    static readonly cdi = "cdi";
    static readonly ipca = "ipca";
    static readonly igpm = "igpm";
    static readonly selic = "selic";
    static readonly igpdi = "igpdi";

    public static solveSafe(idx: string): string {
        if (!idx)
            return null;
        else if (!idx.length || idx.length < 3) {
            return null;
        }
        idx = idx.toLowerCase();
        if (idx[0] === 'p') {
            return Idx.pre;
        } else if (idx.indexOf("selic") > -1) {
            return Idx.selic
        } else if (idx.indexOf("ipca") > -1) {
            return Idx.ipca
        } else if (idx.indexOf("igpm") > -1) {
            return Idx.igpm
        } else if (idx.indexOf("igpdi") > -1) {
            return Idx.igpdi
        } else if (idx.indexOf("cdi") > -1) {
            return Idx.cdi
        }
        return null;
    }

    public static solve(idx: string) {
        const _idx = Idx.solveSafe(idx);
        if (_idx === null) {
            throw new Error("Unkwon " + idx + " idx");
        }
        return _idx;
    }

    private static normalizeTd(idx: string): string {
        switch (idx) {
            case Idx.pre:
                idx = "Prefixado";
                break;
            case Idx.ipca:
                idx = "IPCA";
                break;
            case Idx.igpm:
                idx = "IGPM";
                break;
            case Idx.selic:
                idx = "SELIC";
                break;
        }
        return idx;
    }

    private static normalizePri(idx: string): string {
        switch (idx) {
            case Idx.pre:
                idx = "PRÉ";
                break;
            case Idx.cdi:
                idx = "CDI";
                break;
            case Idx.ipca:
                idx = "IPCA";
                break;
            case Idx.igpm:
                idx = "IGPM";
                break;
            case Idx.selic:
                idx = "SELIC";
                break;
        }
        return idx;
    }

    public static normalize(idxs: string[]): string[] {
        const output: string[] = [];
        if (!idxs)
            return output;
        if (!Array.isArray(idxs)) {
            idxs = [String(idxs)];
        }
        idxs.forEach(element => {
            if (!element) {
                return;
            }
            const _td = Idx.normalizeTd(element);
            const _pri = Idx.normalizePri(element);
            if (_td !== element)
                output.push(_td);
            if (_td.toLowerCase() !== _pri.toLowerCase() || _td === element)
                output.push(_pri);
        });
        return output
    }

    public static get idxs(): string[] {
        return [Idx.cdi, Idx.pre, Idx.ipca, Idx.igpdi, Idx.igpm, Idx.selic];
    }

    public static get idxsForTd(): string[] {
        return [Idx.pre, Idx.ipca, Idx.igpm, Idx.selic];
    }

    public static get idxsForPrivados(): string[] {
        return [Idx.cdi, Idx.pre, Idx.ipca, Idx.igpm, Idx.igpdi];
    }
}