<mat-card [ngClass]="darkMode ? 'dark-secondary-color' : ''" class="advisory-call-card-overall">
    <form *ngIf="!answerGiven" [formGroup]="form" (submit)="invest($event)">
        <span [ngClass]="darkMode ? 'dark-primary-font' : ''" class="invest-with-profissionals-title">Você gostaria de receber uma assessoria<br> de investimentos gratuita?</span>
        <div class="white-bar"></div>
        <div class="reactive-direction">
            <mat-form-field [ngClass]="darkMode ? 'dark-mat-form-field' : ''" appearance="outline" floatLabel="always">
                <mat-label>Nome</mat-label>   
                <input matInput placeholder="Escreva seu nome" formControlName="nome"/>
            </mat-form-field>
            <mat-form-field [ngClass]="darkMode ? 'dark-mat-form-field' : ''" appearance="outline" floatLabel="always">
                <mat-label>E-mail</mat-label>   
                <input matInput placeholder="nome@dominio.com.br" formControlName="email"/> 
            </mat-form-field>
            <div class="phone-section">
                <mat-form-field [ngClass]="darkMode ? 'dark-mat-form-field' : ''" class="code" appearance="outline" floatLabel="always">
                    <mat-label>DDI</mat-label>   
                    <mat-select formControlName="ddi" required>    
                        <mat-option [ngStyle]="{'background': isDarkMode ? '#212121' : 'white', 'color': isDarkMode ? 'white' : '#323232'}" 
                        (click)="setThis($event, country)" *ngFor="let country of countries" [value]="country.ddi">
                            <div style="display: flex; align-items: center;">
                                <img [src]="country.url_bandeira" type="image/webp"/> <span style="margin-left: 10px;">+{{country.ddi}}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field [ngClass]="darkMode ? 'dark-mat-form-field' : ''" class="phone" appearance="outline" floatLabel="always">
                    <mat-label>Telefone</mat-label>   
                    <input matInput placeholder="(xx) xxxxx-xxxx" [errorStateMatcher]="matcher" required [mask]="countryMask"
                        formControlName="telefone">
                    <mat-hint *ngIf="verifyingPhone && phoneTouched">Verificando telefone...</mat-hint>
                    <mat-error *ngIf="phoneTouched && !phoneVerified && !verifyingPhone">{{errorMessage || 'Erro ao verificar telefone.'}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <button [disabled]="!form.valid || !phoneVerified" mat-raised-button color="accent">INVESTIR</button>
    </form>
    <div *ngIf="answerGiven" class="thank-you-message">
        <img src="../../../assets/rufino/rufino-com-logo.svg" alt="rufino-com-logo.svg">
        <span [ngClass]="darkMode ? 'dark-primary-font' : ''" class="message">Uma equipe de especialistas entrará<br>em contato em breve!<br>Obrigado!</span> 
    </div>
</mat-card>