export class Dth {
    private _date: Date;
    private _dthToDate(dth: string, separator?: string): Date {
        const _date = new Date(dth);
        if (!isNaN(_date.getTime())) {
            return _date;
        }
        let dths = dth.split(separator ? separator : "/");
        return new Date(parseInt(dths[2]), parseInt(dths[1]) - 1, parseInt(dths[0]));
    }

    constructor(value?: string, separator?: string) {
        if (!value) {
            this._date = new Date();
        } else {
            this._date = this._dthToDate(value, separator);
        }
    }

    public static isDate(value): boolean {
        if (value instanceof Date) {
            return true;
        }
        return /\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\dZ/.test(value);
    }

    public static fromDate(_date: Date | number): Dth {
        let dth = new Dth();
        if (_date instanceof Date)
            dth._date = _date;
        else
            dth._date = new Date(_date);
        return dth;
    }

    public static from(_date: Date | string, separator?: string): Dth {
        if (_date instanceof Date) {
            return Dth.fromDate(_date);
        }
        return new Dth(_date, separator);
    }

    public get date (): Date {
        return new Date(this._date);
    }

    private _addZero(value: number): string {
        return value < 10 ? "0" + value.toString() : value.toString();
    }

    public get year(): string {
        return this._date.getFullYear().toString();
    }

    public get month(): string {
        return this._addZero(this._date.getMonth() + 1);
    }

    public get day(): string {
        return this._addZero(this._date.getDate());
    }

    public replace(year?: number | string, month?: number | string, day?: number | string): Dth {
        const _year: number = year ? parseFloat(year.toString()) : this._date.getFullYear();
        const _month: number = month ? parseFloat(month.toString()) : this._date.getMonth();
        const _day: number = day ? parseFloat(day.toString()) : this._date.getDate();
        return Dth.fromDate(new Date(_year, _month-1, _day));
    }

    public toString(separator?: string): string {
        separator = separator ? separator : "/";
        return this.day + separator + this.month + separator + this.year;
    }

    private treatAsUTC(date): Date  {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    }
    
    private timeBetween(date: Dth | Date): number {
        if (date instanceof Dth) {
            date = date.date;
        }
        return this.treatAsUTC(this._date).valueOf() - this.treatAsUTC(date).valueOf();
    }

    public daysBetween(date: Dth | Date): number {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        return this.timeBetween(date)/millisecondsPerDay;
    }

    public addDays(days: number): Dth {
        let dth = Dth.fromDate(this._date.valueOf());
        dth._date.setDate(this._date.getDate() + days);
        return dth;
    }

    public minusDays(days: number): Dth {
        let dth = Dth.fromDate(this._date.valueOf());
        dth._date.setDate(this._date.getDate() - days);
        return dth;
    }
}