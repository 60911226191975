import {Component, HostBinding, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {routerAnimation} from '../../utils/page.animation';
import { Destroyable } from '../../utils/future';
import { OnesignalService } from '../../providers/onesignal.service';
import { RequestLoaderService } from '../../providers/request-loader.service';
import { UserService } from '../../providers/user.service';
import { Sidenav } from '../../model/sidenav';
import { User } from '../../model/user';
import { SidenavService } from '../../components/sidemenu/sidenav.service';
import { FacebookService } from '../../providers/facebook.service';
import { AssessorService } from '../../components/assessor/assessor.service';
import { ActivatedRoute } from "@angular/router";
import { FundosService } from '../../providers/fundos.service';
import { Connector } from '../../providers/connector';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'app/providers/seo.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  animations: [routerAnimation]
})
export class MainPageComponent implements OnInit, OnDestroy {
  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
  current_route;
  public _sidenavMode = 'over';
  public _sidenavOpened = false;
  public _boxedLayout = false;
  public user: User;
  public isFacebookApp = false;
  @ViewChild("sidenav", {static: true}) _sidenav: Sidenav;
  private _isFooterHidden = false;
  private _isToolbarHidden = false;
	public isDarkMode: boolean = false;
  private _onUserChangeDestroy: Destroyable;
  showNovoBadge: boolean;
  constructor(
    public onesignalService: OnesignalService,
    public spinner: RequestLoaderService,
    public userService: UserService,
    public sidenavService: SidenavService,
    private router: Router,
    private assessorService: AssessorService,
    private fundoService: FundosService,
    protected activatedRoute: ActivatedRoute,
    private connector: Connector,
    private readonly seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: string
    ) {
    this.seoService.updateMetaInformation('main');
  }

  ngOnInit() {
    this.showNovoBadge = this.shouldShowNovoBadge();
    this.current_route = this.router.url;
    this.user = <User>{}
    this._onUserChangeDestroy = this.userService.onUserChange(user => {
      this.user = user;
    });
    this.sidenavService.__publishSidenav(this._sidenav);
    if(isPlatformBrowser(this.platformId)){
      FacebookService.onRunningOnFacebook(() => {
        this.isFacebookApp = true;
      });
    }
		if(isPlatformBrowser(this.platformId)){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.isDarkMode = true;
      else this.isDarkMode = false;  
    }
    else this.isDarkMode = false;
    
  }

  ngOnDestroy() {
    if (this._onUserChangeDestroy) {
      this._onUserChangeDestroy.destroy();
    }
  }

  public openExternally(url): void {
    this.connector.openInNewTab(url);
	}

  openOnNewTab() {
    this.openExternally("https://materiais.rendafixa.rocks/servicos-financeiro");
  }
  public linkMateriais(){
    this.openExternally('https://blog.apprendafixa.com.br/materiais-educativos');
  }
  public linkYoutube(){
   this.openExternally('https://www.youtube.com/apprendafixa');
  }
  shouldShowNovoBadge() {

    if (localStorage.getItem('alreadyShowNovo1') == null) {
      localStorage.setItem('alreadyShowNovo1', 'true');
      return true
    } else {
      return false
    }
  }

  set sidenavMode(val) {
    this._sidenavMode = val;
    // setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get sidenavMode() {
    return this._sidenavMode;
  }

  get sidenavOpened(): boolean {
    return this._sidenavOpened;
  }

  set boxedLayout(val) {
    this._boxedLayout = val;
    // setTimeout(() => this.resizeService.resizeInformer$.next(), 500);
  }

  get boxedLayout() {
    return this._boxedLayout;
  }

  get isFooterHidden(): boolean {
    return this._isFooterHidden;
  }

  get isToolbarHidden(): boolean {
    return this._isToolbarHidden;
  }

  get pageClass(): string {
    return 'page' + (this.isToolbarHidden ? ' page-no-toolbar' : '') + (this.isFooterHidden ? ' page-no-footer' : '');
  }

  public transitState(value: boolean) {
    setTimeout(() => {
      // this.resizeService.resizeInformer$.next();
      this.sidenavService.inform();
    }, 500);
  }

  saveLastUrl() {
    this.fundoService.setLastUrl(this.router.url)
  }

  public swipeleft($event?: Event) {
    if (this._sidenav._isClosed) {
      return;
    }
    this._sidenav.close().then(() => {
      this.transitState(false);
    });
    if ($event) {
      $event.preventDefault();
      $event.cancelBubble = true;
    }
  }

  public swiperight($event?: Event) {
    if (this._sidenav._isOpened) {
      return;
    }
    this._sidenav.open().then(() => {
      this.transitState(true);
    });
    if ($event) {
      $event.preventDefault();
      $event.cancelBubble = true;
    }
  }

  public sidenavToggle() {
    this._sidenav.toggle().then((value) => {
      this.transitState(value.type == "open");
    });
  }

  public login() {
    // this.routerStateService.updatePathName('/app/perfil');    // this.router.navigateByUrl(undefined);
    // this.router.navigateByUrl('/usuario/login');
    this.router.navigateByUrl('/usuario/login');
  }

  public logout() {
    this.user = this.userService.logout();
  }

  public get profile_image(): string {
    return "url(" + this.user.picture + ")";
  }

  public componentAdded($event) {
    if(isPlatformBrowser(this.platformId)){
      if ($event.noFooterFlag) {
        this._isFooterHidden = true;
      }
      if ($event.noToolbarFlag) {
        this._isToolbarHidden = true;
      }
      window.scroll(0, 0);
    }
  }

  public componentRemoved($event) {
    this._isFooterHidden = false;
    this._isToolbarHidden = false;
  }

  public get isOnesignalAvailable(): boolean {
    return this.onesignalService.isAvailable;
  }

  public get isSubscribedToOnesignal(): boolean {
    // return this._pushStatus;
    return false;
  }

  public openWhatsApp(){
    this.openExternally('https://api.whatsapp.com/send?phone=5511988829851');
  }

  openPrevidenciaSeguro() {
    this.openExternally("https://materiais.apprendafixa.com.br/previdencia-e-seguro");
  }

  openRendaVariavel(){
    this.openExternally('https://materiais.apprendafixa.com.br/renda-variavel');
  }

  public openAssessorDialog(): void {
    this.sidenavToggle();
    setTimeout(() => {
      this.assessorService.openDialog()
    }, 60);
  }
}
