import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import { SidenavService } from './sidenav.service';

/**
 * Service for informing menu nodes about closing
 */
@Injectable()
export class CloseService {

  closeInformer$ = new Subject<number>();

  constructor(private sidenav: SidenavService) {
  }

  inform(menuLevel: number) {
    this.closeInformer$.next(menuLevel);
  }

  close() {
    this.sidenav.close();
  }
}
