import { Injectable } from '@angular/core';
import { RequestLoaderService } from '../request.loader/request.loader.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';


export enum FileStatus {
    STATUS_SUCCESS,
    STATUS_MAX_FILES_COUNT_EXCEED,
    STATUS_MAX_FILE_SIZE_EXCEED,
    STATUS_MAX_FILES_TOTAL_SIZE_EXCEED,
    STATUS_NOT_MATCH_EXTENSIONS
}

export interface FileSelectedEvent {
    status: FileStatus;
    files: File[];
}

export function readFileToDataUrl(
    file: File, 
    onLoadDone?: (data) => void, 
    onError?: (file: File, error) => void,
    onProgress?: (progress: number) => void,
    onAbort?: () => void,
): {abort: () => void, readonly progress: number, readonly loading: boolean} {
    const reader = new FileReader();
    let progress: number = undefined;
    let loading = false;
    // reader.onabort = onAbort;
    reader.onload = function() {
        onLoadDone(reader.result);
    };
    reader.onerror = function (ev) {
        onError(file, reader.error);
    }
    reader.onprogress = function (ev: ProgressEvent) {
        if (ev.lengthComputable) {
            progress = ev.loaded / ev.total;
        }
        onProgress(progress);
    };
    reader.onloadstart = function () {
        loading = true;
    }
    reader.readAsDataURL(file);
    return {
        abort: function () {reader.abort(); onAbort(); },
        get progress() { return progress; },
        get loading() { return loading; },
    };
};


@Injectable()
export class FileUploadService {
    constructor(
        private spinner: RequestLoaderService,
        private dialog: MatDialog
    ) {}

    public validateEvent(event: FileSelectedEvent): string {
        let msg = null;
        if (event.status !== FileStatus.STATUS_SUCCESS && event.status) {
            if (event.status === FileStatus.STATUS_MAX_FILE_SIZE_EXCEED) {
                msg = "Arquivo muito grande. máximo 2Mb!";
            } else if (event.status === FileStatus.STATUS_NOT_MATCH_EXTENSIONS) {
                msg = "Formato de arquivo não suportado!";
            } else {
                msg = "Esse arquivo não pode ser anexado! Tente outro formato ou tamanho.";
            }
        }
        return msg;
    }

    public getFilesFromEvent(event: FileSelectedEvent): File[] {
        if (!event || !event.files) {
            return []
        }
        const error = this.validateEvent(event);
        if (error) {
            throw new Error(error);
        }
        return event.files;
    }

    public loadFileToDataUrl(fileInfo: File | FileSelectedEvent): Promise<{name: string, type: string, dataUrl: string}> {
        return new Promise((resolve, reject) => {
            let file: File;
            if ((<FileSelectedEvent>fileInfo).files) {
                try {
                    file = this.getFilesFromEvent((<FileSelectedEvent>fileInfo))[0];
                } catch (error) {
                    reject(error);
                }
            } else {
                file = <File>fileInfo;
            }
            let trackerRef = {progress: 0, abort: undefined, file: file};
            let dialogRef: MatDialogRef<FileUploadDialogComponent>;
            const ref = readFileToDataUrl(file, data => {
                this.spinner.report("Arquivo carregado com sucesso");
                dialogRef.close();
                resolve({
                    name: file.name,
                    type: file.type,
                    dataUrl: data
                });
            }, (file, error) => {
                error = error || {message: error};
                this.spinner.report(error.message || error);
                reject(error);
            }, progress => {
                setTimeout(() => {trackerRef.progress = progress});
            }, () => {
                this.spinner.report("O upload do arquivo foi cancelado!");
                reject(new Error("O upload do arquivo foi cancelado!"));
            });
            trackerRef.abort = ref.abort;
            dialogRef = this.dialog.open(FileUploadDialogComponent, {data: trackerRef});
            // trackerRef = this.spinner.showAndTrack(ref.abort);
        });
    }
}
