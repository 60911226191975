import { Component, OnInit } from '@angular/core';
import { SeoService } from 'app/providers/seo.service';

@Component({
  selector: 'app-bem-vindo',
  templateUrl: './bem-vindo.component.html',
  styleUrls: ['./bem-vindo.component.scss']
})
export class BemVindoComponent implements OnInit {
  constructor(private readonly seoService: SeoService) { }
  ngOnInit() {
    localStorage.setItem('isFirstViewer', "false");
    this.seoService.initDefaultMetaInformation();
  }
}
