import { Component, Input, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
// import {ResizeService} from '../../resize/resize.service';

declare let $: any;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnChanges {

  private _initialized: boolean = false;
  private $owl: any;
  private resizeSubscription;
  @ViewChild("owlNative", {static: true}) elementRef: ElementRef;
  @Input("items") items: any;
  @Input("view") compile: NgTemplateOutlet;
  @Input("config") config: any;
  constructor(
    // resizeService: ResizeService
  ) {
    // this.resizeSubscription = resizeService.resizeInformer$.subscribe(() => {
    //   this.recycle();
    // });
  }

  ngOnChanges(unused: any): void {
    this.recycle();
  }

  public getConfig() {
    let _config = {
      items: 1,
      slideBy: 1,
      loop: true,
      navigation : false,
      rewindNav : true,
      scrollPerPage : false,
      pagination : true,
    };

    if (this.config) {
      Object.keys(this.config).forEach((key: string) => {
        _config[key] = this.config[key];
      });
    }
    
    return _config;
  }

  private mount (): void {
    let jqueryElement = $(this.elementRef.nativeElement)
    jqueryElement.owlCarousel(this.getConfig());
    jqueryElement.children(".owl-stage-outer").addClass("app-carousel-items");
    jqueryElement.children(".owl-dots").children(".owl-dot").addClass("app-carousel-dot");
    this.$owl = jqueryElement;
  }

  private unmount (): void {
    if (!this.$owl)
      return;
    // let data: any = this.$owl.owlCarousel;
    // if (data) {
    //   data.destroy();
    //   data.trigger('destroy.owl.carousel');
    // }
    this.$owl.trigger('destroy.owl.carousel');
    this.$owl = null;
  }

  private recycle() {
    if (!this._initialized)
      return;
    this.unmount()
    this.mount();
  }

  ngAfterViewInit() {
    this.mount();
    this._initialized = true;
  }

  ngOnDestroy() {
    this.unmount();
  }
}
