import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from "@angular/core";

@Injectable()
export class MatPaginatorIntlPortugues extends MatPaginatorIntl {
    private _getRangeLabel;
    constructor() {
        super();
        // Recicla a funcao jah implementada pelo angular material para evitar bugs quando atualizar o package, pois seria nescessario revisar essa funcao.
        // getRangeLabel infelizmente eh uma property que recebe uma arrow function, logo preciso fazer um proxy que trata a saida do método para traduzir a palavra 'of' para 'de'.
        this._getRangeLabel = this.getRangeLabel;   // passa para outra variavel
        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            return this._getRangeLabel(page, pageSize, length).replace("of", "de");
        }
    }

    public itemsPerPageLabel: string = "Itens por página:";
    public nextPageLabel: string = "próxima página";
    public previousPageLabel: string = "página anterior";
}

export class MatTextLocale {
    static $inject() {
        return {provide: MatPaginatorIntl, useClass: MatPaginatorIntlPortugues};
    }
}