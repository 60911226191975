import {NgModule} from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { environment } from '../environments/environment';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { IsUserLoggedGuardService } from './providers/isUserLoggedGuard.service';
import { HomeComponent } from './pages/home/home.component';
import { BemVindoComponent } from './pages/bem-vindo/bem-vindo.component';

const APP_ROUTES: Routes = [
  {path: '', component: MainPageComponent, children: [
    {path: '', component: HomeComponent},
  ]},
  {
    path: 'app', component: MainPageComponent, children: [
      {path: '', component: HomeComponent},

      {path: 'indicadores', redirectTo: ''},

      {path: 'dashboard', redirectTo: ''},

      {path: 'bem-vindo', component: BemVindoComponent},

      {path: 'investimentos', loadChildren: () => import('./pages/investments/investments.module').then(m => m.InvestmentsModule)},

      {path: 'fundos', loadChildren: () => import('./pages/fundos/fundos.module').then(m => m.FundosModule)},

      {path: 'calculadoras', loadChildren: () => import('./pages/calculadoras/calculadoras.module').then(m => m.CalculadorasModule)},

      {path: 'noticias', loadChildren: () => import('./pages/feed/feed.module').then(m => m.FeedModule)},

      {path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqsModule)},

      {path: 'ranking', loadChildren: () => import('./pages/ranking-de-instituicoes/ranking-de-instituicoes.module').then(m => m.RankingDeInstrucoesModule), canActivateChild: [IsUserLoggedGuardService]},

      {path: 'perfil', loadChildren: () => import('./pages/perfil/perfil.module').then(m => m.PerfilModule), canActivateChild: [IsUserLoggedGuardService]},

      {path: '**', redirectTo: '', pathMatch: 'prefix'},
    ]
  },
  {path: 'login', redirectTo: './app/calculadoras'},
  {path: 'usuario', loadChildren: () => import('./pages/user-pages/user-pages.module').then(m => m.UserPagesModule)},
  {path: '', redirectTo: '/app', pathMatch: 'prefix'},
  {path: '**', redirectTo: '/app', pathMatch: 'prefix'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {enableTracing: environment.routeTracing},),
  ]
})
export class AppRoutesModule {
}
