import {CHART_TEXT_COLOR, MAT_DEEP_ORANGE, MAT_LIGHT_BLUE} from '../../utils/colors';

export const defaultOptions = {
    chart: {
        zoomType: 'x',
        type: 'line',
        backgroundColor: '#FFFFFF',
    },
    series: [],
    plotOptions: {
        line: {
            marker: {
                enabled: false
            }
        }
    },
    legend: {
        itemStyle:{
            color: '#323232'
        }
    },
    tooltip: {
        shared: true,
        crosshairs: true,
        headerFormat: '<b>{point.x:%m/%Y}</b><br>',
        valueSuffix: ' %'
        // pointFormat: '{series.name}: {point.y:.2f}<br>'
    },
    xAxis: {
        startOnTick: false,
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the dummy year
            month: '%m/%Y'
        },
        title: {
            text: null
        },
        labels: {
            style: {
                color: '#323232'
            }
        }
    },
    yAxis: {
        startOnTick: false,
        title: {
            style: {
                color: '#323232'
            },
            text: 'Acumulado de 12 meses'
        },
        labels: { 
            style: {
                color: '#323232'
            },
            formatter: function () {
                return this.value + "%";
            }
        }
    },
    credits: {
        enabled: false
    },
};