import {NgModule} from '@angular/core';
import * as Validators from './validators.directive'
import {MasksModule} from './textmask/textmask.module';
import {CurrencyMaskModule} from "./currency-mask.module";
import { DefaultSourceDirective } from './default-source';
import { SwipeTabDirective } from './swipe-tabs.directive';

@NgModule({
    imports: [
        MasksModule,
    ],
    declarations: [
        DefaultSourceDirective,
        Validators.EmailValidator,
        Validators.AvistaPasswordValidator,
        Validators.MoneyValidator,
        Validators.TelefoneValidator,
        Validators.CpfValidator,
        SwipeTabDirective
    ],
    exports: [
        CurrencyMaskModule,
        DefaultSourceDirective,
        MasksModule,
        Validators.EmailValidator,
        Validators.AvistaPasswordValidator,
        Validators.MoneyValidator,
        Validators.TelefoneValidator,
        Validators.CpfValidator,
        SwipeTabDirective
    ]
})
export class DirectivesModule {

}