import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountriesService } from 'app/providers/corretoras.service';
import { UserService } from 'app/providers/user.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RequestLoaderService } from '../request.loader/request.loader.service';


@Component({
  selector: 'app-invest-fill-data-dialog',
  templateUrl: './invest-fill-data-dialog.component.html',
  styleUrls: ['./invest-fill-data-dialog.component.scss']
})
export class InvestFillDataDialogComponent implements OnInit {

  public fillDataDialogForm: FormGroup;
  public isDarkMode: boolean = false;
  public countries;
  public phoneVerified = false;
  public phoneTouched = false;
  public verifyingPhone = false;
  public errorMessage = 'Telefone inválido';
  public countryMask = '(00) 00000-0000||(00) 0000-0000';
  public countryLength = 10;
  public matcher = {
    isErrorState: () => {
      return this.phoneTouched && !this.phoneVerified; // return Boolean status value
    }
  }

  constructor(
    private dialogRef: MatDialogRef<InvestFillDataDialogComponent>,
    private _spinner: RequestLoaderService,
    private _countriesService: CountriesService,
    private _userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public investimento: any,
    @Inject(MAT_DIALOG_DATA) public info: any,
    @Inject(PLATFORM_ID) private platformId: string,
    private formBuilder: FormBuilder,
  ) {
    if(isPlatformBrowser(this.platformId)){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.isDarkMode = true;
      else this.isDarkMode = false;  
    }
    else this.isDarkMode = false;
  }

  ngOnInit(): void {
    this.initialize();
  }

  setThis(e, country){
    this.phoneVerified = false;
    this.verifyingPhone = false;
    this.phoneTouched = false;
    this.fillDataDialogForm.get("pais").setValue(country['pais']);
    this.fillDataDialogForm.get("ddi").setValue(country['ddi']);
    this.countryMask = country['ddi'] != 55 ?'0000000000' : '(00) 00000-0000||(00) 0000-0000';
    this.countryLength = country['ddi'] != 55 ? 8 : 10;
    this.fillDataDialogForm.get("tel").setValue(undefined);
  }

  initialize(){
    this.fillDataDialogForm = this.formBuilder.group({
      name: [undefined, [Validators.required]],
      ddi: [55, [Validators.required]],
      pais: ["Brasil", [Validators.required]],
      tel: [undefined, [Validators.required]],
      email: [undefined, [Validators.required, Validators.email]],
      investimento: [this.investimento || this.investimento["titulo"] || undefined],
      corretora: [this.investimento.investimento]
    });
    let user;
    const userExists = localStorage.getItem('user');
    if(userExists && !!userExists) {
      user = JSON.parse(localStorage.getItem('user'));
      if(user){
        this._userService.readToken().then(resp => {
          if(resp){
            resp['name'] ? this.fillDataDialogForm.get('name').setValue(resp['name']) : undefined;
            resp['ddi'] ? this.fillDataDialogForm.get('ddi').setValue(resp['ddi']) : 55;
            resp['email'] ? this.fillDataDialogForm.get('email').setValue(resp['email']) : undefined;
            resp['tel'] ? this.fillDataDialogForm.get('tel').setValue(resp['tel']) : undefined;
            resp['pais'] ? this.fillDataDialogForm.get('pais').setValue(resp['pais']) : "Brasil";
          }
        }).catch(err => {
          this._spinner.hide(err['message']);
        })
      }
    }
    this.fillDataDialogForm.get('tel')
    .valueChanges.pipe().subscribe(val => {
      this.phoneVerified = false;
      this.phoneTouched = true;
    })
    this.fillDataDialogForm.get('tel')
    .valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(val => {
      if(!val || val['length'] < this.countryLength || !this.fillDataDialogForm.get('pais').value) return;
      else this.initiatePhoneVerification(val, this.fillDataDialogForm.get('pais').value);
    })
    let localCountries = localStorage.getItem('listaPaises');
    if(localCountries === null){
      this._countriesService.getDdi().subscribe((val) => {
        this.countries = val["ddi"];
        this.countries.sort((a,b) => a.ddi > b.ddi ? 1 : -1);
        localStorage.setItem("listaPaises", JSON.stringify(this.countries));
      }, err => {
        this._spinner.hide("Erro ao carregar os códigos dos países.");
      });
    }
    else{
      this.countries = JSON.parse(localCountries);
    }
    if(!this.countries) this._spinner.hide("Erro: os códigos dos países não carregaram.");
  }

  initiatePhoneVerification(phoneNumber: string, country: string){
    this.verifyingPhone = true;
    this.fillDataDialogForm.get("tel").disable();
    this.fillDataDialogForm.get("ddi").disable();
		this._userService.checkUserCaptcha().then(()=>{
      this._userService.verifyPhone(phoneNumber, country).then(val => {
        /**
         * O timeout é pra evitar spam de verificação de telefone.
         */
        setTimeout(() => {
          this.fillDataDialogForm.get("tel").enable();
          this.fillDataDialogForm.get("ddi").enable();
          this.verifyingPhone = false;
          this.phoneVerified = true;
        }, 2000);
      }).catch(err => {
        if(err && err['error'] && err['error']['Message']){
          this.fillDataDialogForm.get("tel").enable();
          this.fillDataDialogForm.get("ddi").enable();
          this.phoneVerified = false;
          this.verifyingPhone = false;
          this.errorMessage = err['error']['Message'];
        }
        else {
          this.fillDataDialogForm.get("tel").enable();
          this.fillDataDialogForm.get("ddi").enable();
          this.phoneVerified = false;
          this.verifyingPhone = false;
          this._spinner.hide("Erro ao verificar o telefone.");
        }
      })
    })
  }

  send(){
    if(this.fillDataDialogForm.valid && this.phoneVerified)this.dialogRef.close(this.fillDataDialogForm.value);
  }
}
