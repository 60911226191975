import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from "@angular/router"
import { AssessorService } from '../../../../components/assessor/assessor.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-howto',
  templateUrl: './howto.component.html',
  styleUrls: ['./howto.component.scss']
})
export class HowtoComponent {
  public path: string = '/assets/icons/new-home-icons/';
  public loaded: boolean = false;
  public howto: any[] = [
    {
      index: 0,
      icon: this.path + 'search.png',
      title: 'Pesquise o quanto você quer investir',
      subtitle: 'Investimentos para todos os bolsos, desde 1 real até 1 milhão.',
      btn: 'PESQUISAR'
    },
    {
      index: 1,
      icon: this.path + 'list.png',
      title: 'ou encontre a lista de investimentos para você',
      subtitle: 'São diversos investimentos, onde você pode investir diretamente conosco.',
      btn: 'LISTAS DE INVESTIMENTOS'
    },
    {
      index: 2,
      icon: this.path + 'help.png',
      title: 'Não sabe por onde começar? Nós te ajudamos',
      subtitle: 'Conte com uma equipe de especialistas certificados para te ajudar.',
      btn: 'FALE COM ESPECIALISTA'
    }
  ]
  public carouselOptions: OwlOptions = {
    dots: true,
    items: 1,
  }
  constructor(
    private _router: Router,
    private _assessorService: AssessorService,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.loaded = true;
    }, 500)
  }

  activateFunction(index){
    if(isPlatformBrowser(this.platformId)){
      switch(index.index){
        case 0:
          if(window.screen.width > 600){
            this.document.getElementById("invest-banner").scrollIntoView({behavior: "smooth"});
          }
          else{
            this.document.getElementById("invest-banner-mobile").scrollIntoView({behavior: "smooth"});
          }
          break;
        case 1:
          this._router.navigateByUrl('/app/investimentos/rendafixa');
          break;
        case 2:
          this._assessorService.openDialog();
          break;
      }
    }
  }

}
