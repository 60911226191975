import { Constants } from '../../providers/constants';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TituloCard } from '../../model/titulo-card';
import { AssessorService } from '../../components/assessor/assessor.service';
import { PageBase } from '../page.base';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router"
import { MatDialog } from "@angular/material/dialog";
import { CalculatorPopUpDialogComponent } from './index-component/calculator-pop-up-dialog/calculator-pop-up-dialog.component';
import { OwlOptions } from 'ngx-owl-carousel-o/lib/models/owl-options.model';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'app/providers/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends PageBase implements OnInit, OnDestroy {
  icon;
  milMoney;
  milCalendar;
  milInvest;
  quando_investir_rendaFixa;
  quando_resgatar_rendaFixa;
  quando_investir_tesouroDireto;
  quando_resgatar_tesouroDireto;
  quando_investir_robo;
  qual_perfil;
  cnpj1;
  cnpj2;
  million_money;
  million_calendar;
  million_invest;
  filteredOptions1;
  filteredOptions2;
  myControl1 = new FormControl();
  myControl2 = new FormControl();
  public button_selected = 'button-rendaFixa-selected';
  public carouselPatrocinadosItems: any[];
  public carouselParceirosItems: any[];
  public investmentsDestaque: TituloCard[];
  public investmentsCorretora: TituloCard[];
  public investmentsMaiorRentabilidade: TituloCard[];
  public sidenavOpened: boolean;
  public iniciantes;
  public cursos;
  public content;
  public cardsParceiros = [
    {
      imagem: '/assets/logo/card-color/avista.png',
    },
    {
      imagem: '/assets/logo/card-color/modalmais.png',
    },
    {
      imagem: '/assets/logo/card-color/btg.png',
    },
    {
      imagem: '/assets/logo/card-color/orama.png',
    },
    {
      imagem: '/assets/logo/card-color/bancoin.png',
    },
    {
      imagem: '/assets/logo/card-color/hurst.png',
    }
  ]
  public carouselOptions: OwlOptions = {
    nav: false,
    dots: false,
    autoWidth: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
        loop: true
      },
      650: {
        items: 2,
        loop: true
      },
      1000: {
        items: 3,
        loop: true
      },
      1300: {
        items: 4,
        loop: true
      },
      1700: {
        items: 5,
        loop: true
      }
    }
  }
  public carouselOptions2: OwlOptions = {
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      650: {
        items: 2,
        nav: true
      }
    }
  }
  public bannersItems;
  public api_url;
  public colorSelect1 = 'gray';
  public colorSelect2 = 'gray';
  public partnerCards;
  public isDarkMode: boolean = false;
  public loaded: boolean = false;
  constructor(
    private http: HttpClient,
    protected dialog: MatDialog,
    private assessorService: AssessorService,
    private router: Router,
    private constants: Constants,
    private readonly seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    super();
    this.seoService.initDefaultMetaInformation();
    this.api_url = this.constants.getUrl();
    if(isPlatformBrowser(this.platformId)){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.isDarkMode = true;
      else this.isDarkMode = false;  
    }
    else this.isDarkMode = false;
    this.cardsParceiros = [
      {
        imagem: this.isDarkMode ? '/assets/logo/card/avista-alt.png' : '/assets/logo/card-color/avista.png',
      },
      {
        imagem:  this.isDarkMode ? '/assets/logo/card/modalmais.png' : '/assets/logo/card-color/modalmais.png',
      },
      {
        imagem:  this.isDarkMode ? '/assets/logo/card/btg.png' : '/assets/logo/card-color/btg.png',
      },
      {
        imagem:  this.isDarkMode ? '/assets/logo/card/orama.png' : '/assets/logo/card-color/orama.png',
      },
      {
        imagem:  this.isDarkMode ? '/assets/logo/card/bancoin.png' : '/assets/logo/card-color/bancoin.png',
      },
      {
        imagem:  this.isDarkMode ? '/assets/logo/card/hurst.png' : '/assets/logo/card-color/hurst.png',
      }
    ]
  }
  
  shouldDisable(value) {
    if (typeof value == 'string') {
      return 'hidden'
    }
  }

  calculate(e?, opcao?) {
    if (e) {
      e.cancelBubble = true;
      e.preventDefault();
    }
    if(!this.milMoney || !this.milCalendar || !this.milInvest) return ;
    opcao = {
      milMoney: this.milMoney,
      milCalendar: this.milCalendar,
      milInvest: this.milInvest
    }
    this.dialog.open(CalculatorPopUpDialogComponent, {
      width: '350px',
      height: '300px',
      data: {
        opcao
      }
    });
  }

  get sidenavClosed(): boolean {
    return !this.sidenavOpened;
  }

  getMedias(limit?) {
    if (limit == undefined) limit = '';
    else limit = '/' + limit;
    this.http.get(this.api_url + '/vn/get_media_home' + limit)
      .toPromise().then((val) => {
        this.content = val;
      })
      .catch(err => {
        this.handleErrorPromise
      })
  }

  buttonClick(tipo) {
    if (this.button_selected == tipo) {
      this.button_selected = null;
    }
    this.button_selected = tipo;
  }

  selectValueTipoDeInvestUpdated(event) {
    this.milInvest = event.target.value;
    this.colorSelect2 = 'black';
  }

  searchInvetimento() {
    var url = 'app/investimentos/rendafixa?tipo=ALL';
    if (this.quando_investir_rendaFixa !== undefined) {
      url += '&valinv=' + this.quando_investir_rendaFixa;
    }
    this.router.navigateByUrl(url);
  }

  selectValueUpdated(event) {
    this.qual_perfil = event.target.value;
    this.colorSelect1 = 'black';
  }

  ngOnInit(): void {
    const firstViewer = localStorage.getItem('isFirstViewer');
    if(!firstViewer || firstViewer !== "false") this.router.navigateByUrl('/app/bem-vindo');
    this.investmentsCorretora = [];
    this.investmentsDestaque = [];
    this.investmentsMaiorRentabilidade = [];
    this.carouselParceirosItems = [];
    this.carouselPatrocinadosItems = [];
    this.http.get(this.api_url + '/vn/get_featured_banners').subscribe((data: any) => {
      this.carouselParceirosItems = data['parceiros'];
    });
    this.getMedias(4);
    this.flushParceiros();
    this.loaded = true;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  openAssessorDialog() {
    this.assessorService.openDialog();
  }

  flushParceiros() {
    for (let i = this.cardsParceiros.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.cardsParceiros[i], this.cardsParceiros[j]] = [this.cardsParceiros[j], this.cardsParceiros[i]];
    }
  }
}
