<div class="loader-background" fxLayout="column" fxLayoutAlign="center center" *ngIf="loading">
    <mat-progress-spinner [mode]="spinnerMode" [value]="value" color="primary"></mat-progress-spinner>
    <!-- <span class="accent-color loading-dotty" [style.xs]="{'font-size': '14pt', 'font-weight': 'bold'}">
        Aguarde estamos calculando
    </span> -->
    <div class="abort-me" *ngIf="canAbort">
        <button mat-icon-button color="primary" (click)="abort()">
            <mat-icon color="primary">close</mat-icon>
        </button>
    </div>
    <br>
    <span class="primary-color loading-random-messages" ngClass.xs="xs">
    </span>
</div>