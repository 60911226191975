import { Component, OnInit, NgZone, Input } from '@angular/core';
import { 
  Indicators, 
  IndicatorsIndicator, 
  IndicatorsCurrency, 
  // IndicatorsCrypto, 
  IndicatorsCryptoDeal, 
  IndicatorsService
} from '../../../../providers/indicators.service';
import { LocalStorageService } from '../../../../providers/localstorage.service';
import { IndicatorsOverlayComponent } from '../../../../components/indicators/indicators-overlay/indicators-overlay.component';
import { MatDialog } from '@angular/material/dialog';
import { RequestLoaderService } from '../../../../providers/request-loader.service';
import { UserService } from '../../../../providers/user.service';


interface IndicatorsFilterable {
  cambios: {[key: string]: IndicatorsCurrency};
  indices: {[key: string]: {value: number, label: string, name: string}};
  cryptos: {[key: string]: IndicatorsCryptoDeal};
}
@Component({
  selector: 'app-new-indicators',
  templateUrl: './new-indicators.component.html',
  styleUrls: ['./new-indicators.component.scss']
})
export class NewIndicatorsComponent implements OnInit {
  private _filterOptions: {
    indices: {[key: string]: boolean},
    cambios: {[key: string]: boolean},
    cryptos: {[key: string]: boolean},
  };
  private indicators: IndicatorsFilterable;
  private _indicesOnView: IndicatorsIndicator[];
  private _cambioOnView: IndicatorsCurrency[];
  private _cryptoOnView: IndicatorsCryptoDeal[];
  public get indices(): IndicatorsIndicator[] {return this._indicesOnView;}
  public get cambios(): IndicatorsCurrency[] {return this._cambioOnView;}
  public get cryptos(): IndicatorsCryptoDeal[] {return this._cryptoOnView;}
  @Input() darkMode: boolean;

  constructor(
    private indicatorsService: IndicatorsService,
    private spinner: RequestLoaderService,
    private localStorage: LocalStorageService,
    private dialog: MatDialog,
    private zone: NgZone,
    private userService: UserService
  ) { 
  }

  ngOnInit() {
    this.userService.checkUserCaptcha().then(()=>{
      this.indicatorsService.indicatorsNormalized.then(result => {
        this.indicators = this.parseIndicatorsNormalized(result);
        delete this.indicators['hash'];
        delete this.indicators['destaques'];
        this.setFilter();
        this.filter();
      }).catch((error) => {
        console.error(error);
        this.spinner.report(error, "Ok");
      });
    });
  }

  private _getKeysIn(obj: {[key: string]: any}, values?: {[key: string]: boolean}): {[key: string]: boolean} {
    values = values ? values : {};
    return Object.keys(obj).reduce((obj, key) => {
      obj[key] = values[key] !== undefined ? values[key] : true;
      return obj;
    }, {});
  }

  private parseIndicatorsNormalized(indicators: Indicators): IndicatorsFilterable {
    return this.zone.runOutsideAngular(() => {
      return {
        indices: {
          cdi: {value: indicators.indices.cdi.now, label: "diário", name: "cdi"},
          selic: {value: indicators.indices.selic.now, label: "over", name: "selic"},
          selic_meta: {value: indicators.indices.selic_meta.now, label: "meta", name: "selic"},
          igpdi_ano: {value: indicators.indices.igpdi.ano, label: "ano", name: "igpdi"},
          igpdi_acu_12: {value: indicators.indices.igpdi.acu_12, label: "12 meses", name: "igpdi"},
          igpm_ano: {value: indicators.indices.igpm.ano, label: "ano", name: "igpm"},
          igpm_acu_12: {value: indicators.indices.igpm.acu_12, label: "12 meses", name: "igpm"},
          ipca_ano: {value: indicators.indices.ipca.ano, label: "ano", name: "ipca"},
          ipca_acu_12: {value: indicators.indices.ipca.acu_12, label: "12 meses", name: "ipca"},
          poup_ano: {value: indicators.indices.poup.ano, label: "ano", name: "poup"},
          poup_mes: {value: indicators.indices.poup.mes, label: "mês", name: "poup"},
          poup_acu_12: {value: indicators.indices.poup.acu_12, label: "12 meses", name: "poup"},
          poup_anualizada: {value: indicators.indices.poup.now, label: "anualizada", name: "poup"},
        },
        cambios: indicators.cambios,
        cryptos: indicators.cryptos,
      }
    });
  }

  private get _cachedFilterOptions(): any {
    return this.localStorage.get('indicadores-filtro', true) || null;
  }

  private set _cachedFilterOptions(value: any) {
    this.localStorage.set('indicadores-filtro', value || null, true);
  }

  private setFilter(options?: any): void {
    options = options ? options : this._cachedFilterOptions || {};
    if (!options.cryptos) {
      options.cryptos = { 0: false, 1: false, 2: false, 3: false, 4: false, 
        5: false, 6: false, 7: true,
        8: true, 9: false, 10: true, 11: false, 12: true, 13: true,
        14: false, 15: false, 16: false, 17: false, 18: true,
      }
      // A função abaixo estava fazendo uma aleatoriedade removendo os 3 primeiros campos e fazendo um reduce pra preencher os dados padrão.
      // Como as respostas de criptos mudaram e a lógica abaixo não se adapta a nova resposta, coloquei a mesma na mão na variavel acima.
      // Ela pega as informações de BTC, ETH, LTC, Ripple, Ada e 1inch da Bitcointrade (que estão de acordo com a cotação atual).
      // A função antiga vai ficar abaixo caso precise retroceder para o método anterior.
      // options.cryptos = Object.keys(this.indicators.cryptos).slice(3).reduce((o, k) => {o[k] = false; return o;}, {});
    }
    this._filterOptions = this.zone.runOutsideAngular(() => {
      return {
        indices: this._getKeysIn(this.indicators.indices, options.indices),
        cambios: this._getKeysIn(this.indicators.cambios, options.cambios),
        cryptos: this._getKeysIn(this.indicators.cryptos, options.cryptos),
      }
    });
    this._cachedFilterOptions = this._filterOptions;
  }

  public filter(): void {
    this._indicesOnView = this.zone.runOutsideAngular(() => {
      return Object.keys(this._filterOptions.indices).reduce((arr, idx) => {
        if (this._filterOptions.indices[idx]) arr.push(this.indicators.indices[idx]);
        return arr;
      }, []);
    });
    this._cambioOnView = this.zone.runOutsideAngular(() => {
      return Object.keys(this._filterOptions.cambios).reduce((arr, idx) => {
        if (this._filterOptions.cambios[idx]) arr.push(this.indicators.cambios[idx]);
        return arr;
      }, []);
    });
    this._cryptoOnView = this.zone.runOutsideAngular(() => {
      return Object.keys(this._filterOptions.cryptos).reduce((arr, key) => {
        const crypto = this.indicators.cryptos[key];
        if (this._filterOptions.cryptos[key]) arr.push(crypto)
        return arr;
      }, []);
    });
  }

  private updateFilter(options: any): void {
    options = Object.assign(this._filterOptions, options || {});
    this._cachedFilterOptions = this._filterOptions;
    this.filter();
  }
  
  public getColor(value: number): string {
    return value < 0 ? "red" : "green";
  }

  public getCambioColorFor(value: number): string {
    if (isNaN(value)) return "";
    return "color-" + this.getColor(value);
  }

  private viewOptions(
    name: string,
    options: {[key: string]: boolean}, 
    labels: {[key: string]: {label?: string, name?: string, provider?: string}},
    onChange: (changes: {[key: string]: boolean}) => void,
  ): void {
    this.dialog.open(IndicatorsOverlayComponent, {
      width: '300px',
      data: {
        name: name,
        options: options,
        labels: labels
      }
    }).afterClosed().subscribe(changes => {
      if (changes) {
        onChange(changes);
      };
    });
  }

  public viewOptionsIndices($event?: Event): void {
    if ($event) {
      $event.cancelBubble = true;
      $event.preventDefault();
    }
    this.viewOptions('Índices', this._filterOptions.indices, this.indicators.indices, changes => {
      this.updateFilter({indices: changes});
    });
  }

  public viewOptionsCambios($event?: Event): void {
    if ($event) {
      $event.cancelBubble = true;
      $event.preventDefault();
    }
    this.viewOptions('Câmbios', this._filterOptions.cambios, this.indicators.cambios, changes => {
      this.updateFilter({cambios: changes});
    });
  }

  public viewOptionsCryptos($event?: Event): void {
    if ($event) {
      $event.cancelBubble = true;
      $event.preventDefault();
    }
    this.viewOptions('Criptomoedas', this._filterOptions.cryptos, this.indicators.cryptos, changes => {
      this.updateFilter({cryptos: changes});
    });
  }

  getBottomLineIndex(index, indexTotal){
    let oof = indexTotal%2;
    if(oof == 0){
      if(index < indexTotal-2) return "with-bottom-line";
    }
    else if(index < indexTotal-1) return "with-bottom-line";
  }

  getBottomLineExchange(index, indexTotal){
    if(index < indexTotal-1) return "with-bottom-line";
  }
}
