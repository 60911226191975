import {Injectable, NgZone} from '@angular/core';
import {Constants} from './constants';
import {Subject} from 'rxjs';
import {LocalStorageService} from './localstorage.service';
import {RuntimeInjection} from '../utils/inject';
import {UserService} from "./user.service";

// https://documentation.onesignal.com/v5.0/docs/web-push-sdk


interface OneSignal extends Array<any> {
	sendTags(tags: { [key: string]: any }): Promise<{}>;

	isPushNotificationsEnabled(callback?: (status: boolean) => void): Promise<boolean>;

	isPushNotificationsSupported(): boolean;

	showHttpPrompt(): void;

	on(event: string, callback: Function);

	setSubscription(unmute: boolean): void;

	getUserId(): Promise<any>;

	getTags(): Promise<{ [key: string]: any }>;

	SERVICE_WORKER_PARAM: any;
	initialized: boolean;
}


@Injectable()
export class OnesignalService {
	private informer = new Subject<boolean>();
	private static isDebug = false;
	private static _inited = false;
	private static _available = false;
	private static __id: any;
	private static _tags: any;

	private get available(): boolean {
		return OnesignalService._available;
	}

	private set available(value: boolean) {
		OnesignalService._available = value;
	}

	constructor(private $zone: NgZone,
				private constants: Constants,
				private localStorage: LocalStorageService,
				private userService: UserService) {
	}

	protected get Onesignal(): OneSignal {
		return (<any>window).OneSignal;
	}


	private _inject(id: string): void {
		const url = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
		RuntimeInjection.injectScript(url, id).then(() => {

			this.Onesignal.push(["init", {
				appId: "c6588f11-7767-49e8-ac83-32f7044129ce",
				notifyButton: {
					enable: true, /* Set to false to hide */
					size: 'medium', /* One of 'small', 'medium', or 'large' */
					position: 'bottom-left', /* Either 'bottom-left' or 'bottom-right' */
					offset: {
						bottom: '48px',
					},
					colors: { // Customize the colors of the main button and dialog popup button
						'circle.background': '#ec407a',
						'circle.foreground': 'white',
						'badge.background': '#ec407a',
						'badge.foreground': 'white',
						'badge.bordercolor': 'white',
						'pulse.color': 'white',
						'dialog.button.background.hovering': 'rgb(77, 101, 113)',
						'dialog.button.background.active': 'rgb(70, 92, 103)',
						'dialog.button.background': '#ec407a',
						'dialog.button.foreground': 'white'
					},
					prenotify: false, /* Show an icon with 1 unread message for first-time site visitors */
					showCredit: false, /* Hide the OneSignal logo */
					text: {
						'tip.state.unsubscribed': 'Acompanhe as últimas novidades e alertas',
						'tip.state.subscribed': "Você está já está recebendo nossos alertas",
						'tip.state.blocked': "Os alertas foram desligados",
						'message.prenotify': 'Clique para receber nossos alertas',
						'message.action.subscribed': "Seja bem vindo ao mundo dos investimentos!",
						'message.action.resubscribed': "Você está já está recebendo nossos alertas",
						'message.action.unsubscribed': "Os alertas foram desligados",
						'dialog.main.title': 'App Renda Fixa',
						'dialog.main.button.subscribe': 'ATIVAR',
						'dialog.main.button.unsubscribe': 'DESATIVAR',
						'dialog.blocked.title': 'Erro',
						'dialog.blocked.message': "Siga as instruções para permitir nossos alertas:"
					}
				},
				welcomeNotification: {
					"title": "App Renda Fixa",
					"message": "Seja bem vindo ao mundo dos investimentos!",
				},
			}])

			this.asyncInit();
			OnesignalService._inited = true;
		}).catch((err) => {
			console.error(err);
		});
	}

	private _prepare(): void {
		this._inject('onesignal-jssdk');
	}

	private asyncInit(): void {
		this.Onesignal.push(['registerForPushNotifications'])
		this.Onesignal.push(() => {
			this.Onesignal.on('subscriptionChange', (isSubscribed: boolean) => {
				if (isSubscribed) {
					this.Onesignal.getUserId().then(_id => {

						OnesignalService.__id = _id;
						this.informer.next(!!_id);
						this.localStorage.set('objectId', _id);
						this.updateTags({
							pushPromocoes: true,
							pushAberto: true,
							pushEncerrado: true,
							pushSuspenso: true
						});
						this.userService.updateOneSignalPlayerId();
					}).catch(error => {
						OnesignalService.__id = null;
						this.informer.next(false);
						this.localStorage.delete('objectId');

					});
				}
				this.informer.next(isSubscribed);
			});
			this.available = this.Onesignal.isPushNotificationsSupported();
		});
	}

	public get inited(): boolean {
		return OnesignalService._inited;
	}

	public inject(): void {
		if (this.inited) {
			return;
		}
		this.$zone.runOutsideAngular(() => {
			setTimeout(() => this._prepare());
		});
	}

	public get isAvailable(): boolean {
		return this.available;
	}

	public get tags(): Promise<{ [key: string]: any }> {
		return new Promise<{ [key: string]: any }>((resolve, reject) => {
			if (!this.isAvailable) {
				reject("Onesignal não está disponível");
			}
			if (OnesignalService._tags) {
				return resolve(OnesignalService._tags);
			}
			this.Onesignal.getTags().then(result => {
				OnesignalService._tags = (result || {tags: {}}).tags || {};
				resolve(OnesignalService._tags);
			}).catch(error => {
				OnesignalService._tags = null;
				reject(error);
			});
		});
	}

	public updateTags(tags: { [key: string]: any }): Promise<{}> {
		return new Promise((resolve, reject) => {
			tags = Object.keys(tags).reduce((o, k) => {
				const v = tags[k];
				if (v !== undefined) {
					o[k] = v + "";
				}
				return o;
			}, {});
			this.Onesignal.sendTags(tags).then(() => {
				OnesignalService._tags = null;
				this.tags;
				resolve(tags);
			}).catch(reject);
		});
	}

	public get _id(): any {
		return OnesignalService.__id;
	}

	public hideFab(): void {
		setTimeout(() => {
			let style = document.getElementById("hide-onesignal") || document.createElement("style");
			style.innerHTML = "#onesignal-bell-container{display: none;}";
			if (!style.id) {
				style.id = "hide-onesignal";
				document.head.appendChild(style);
			}
		}, 60);
	}

	public showFab(): void {
		setTimeout(() => {
			let style = document.getElementById("hide-onesignal") || document.createElement("style");
			style.innerHTML = "#onesignal-bell-container{display: block;}";
			if (!style.id) {
				style.id = "hide-onesignal";
				document.head.appendChild(style);
			}
		}, 60);
	}
}
