import { Pipe, NgModule } from "@angular/core";


@Pipe({
    name: "default"
})
export class DefaultPipe {
    transform(value: string, fallback: string): string {
        return value === undefined || value === null ? fallback : value;
    }
}

@Pipe({
    name: "counter"
})
export class CounterPipe {
    transform(value: number, counter: string, singular?: string): string {
        if (typeof value !== "number") return null;
        singular = singular || counter;
        return value === 1 ? "1 " + singular : value + " " + counter;
    }
}

@Pipe({
    name: "liquidez"
})
export class LiquidezPipe {
    transform(value: boolean, mode: string): string {
        const tmp = value ? "Diária" : "No vencimento";
        return mode === "lowercase" ? tmp.toLowerCase() : tmp;
    }
}

@NgModule({
    declarations: [
        DefaultPipe,
        LiquidezPipe,
        CounterPipe
    ],
    exports: [
        DefaultPipe,
        LiquidezPipe,
        CounterPipe
    ]
})
export class PipesModules {

}