import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Connector } from 'app/providers/connector';


@Component({
  selector: 'app-button-template',
  templateUrl: './button-template.component.html',
  styleUrls: ['./button-template.component.scss']
})
export class ButtonTemplateComponent implements OnInit {

  public imageUrl;
  public arrowUrl;
  public customIcon;

  @Input() mainMessage: String;
  @Input() subMessage: String;
  @Input() icon: String;
  @Input() icon_tag: String;
  @Input() background: String;
  @Input() direction: String;
  @Input() clickable: string;
  @Input() mainFont: String;
  @Input() arrowColor: String;
  @Input() subFont: String;
  @Input() perfil: boolean;
  @Input() img: string;
  @Input() isIcon: string

  constructor(
    private router: Router,
    private connector: Connector
  ) { }

  ngOnInit() {
    this.constructTemplate();
  }

  customBackground(){
    if(this.background != undefined) return this.background;
    else return 'white';
  }

  customMainFont(){
    if(this.mainFont != undefined) return this.mainFont;
    else return '#323232';
  }

  customSubFont(){
    if(this.subFont != undefined) return this.subFont;
    else return '#888888';
  }

  customArrowColor(){
    if(this.arrowColor != undefined) return this.arrowColor;
    else '#ffffff';
  }

  // Consultar todos os icones disponíveis na pasta
  constructTemplate(){
    this.imageUrl = "../../../assets/icons/new-home-icons/" + this.icon + ".svg";
    if(this.icon == 'bancotag'){
      this.imageUrl = '../../../assets/logo/card-color/' + this.icon + '.png';  
    }else if(this.isIcon == 'png'){
      this.imageUrl = '../../../assets/logo/card/' + this.img;
    }
  }

  actionClick(e){
    if(this.direction == 'out') {
      this.connector.openInNewTab(this.clickable);
    }

    // exemplo: this.router.navigateByUrl('app' + '/servicos/cursos/'); sendo o clickable o /servicos/cursos/
    else if(this.direction == 'in') this.router.navigateByUrl('app' + this.clickable);
    else console.log("direction sem valor");
  }

}
