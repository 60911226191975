import {Injectable} from '@angular/core';
import {Connector} from './connector';
import {LocalStorageService} from './localstorage.service';
import {ProgressiveServiceBase} from './progressive.service.base';

interface IndicatorsCurrencyRequest {
    max: number,
    min: number,
    name: string,
    pctChange: number,
    ts: string,
    tx: number,
    txc: number,        
}
interface IndicatorsCryptoRequest {
    compra: number;
    cripto: string;
    data: number;
    exchange: string;
    label: string;
    max: number;
    min: number;
    pct_change: number;
    ultima: number;
    venda: number;
    volume: number;
}

export interface IndicatorsDestaque {
    tx: string, 
    url: string, 
    id: any, 
    tit: string
};
export interface IndicatorsCurrency {
    max: number,
    min: number,
    name: string,
    label: string;
    variation: number,
    date: Date,
    sell: number,
    buy: number,
}
export interface IndicatorsIndicator {
    now: number,
    mes: number,
    ano: number,
    acu_12: number,
}

export interface IndicatorsCryptoDeal {
    id: string;
    name: string;
    provider: string;
    date: Date;
    buy: number;
    sell: number;
    volume: number;
    now: number;
    max: number;
    min: number;
    variation: number;
}

export interface Indicators {
    destaques: IndicatorsDestaque[];
    hash: string;
    last_update: Date;
    cambios: {
        dolar: IndicatorsCurrency;
        euro: IndicatorsCurrency;
        libra: IndicatorsCurrency;
    };
    indices: {
        igpdi: IndicatorsIndicator;
        igpm: IndicatorsIndicator;
        ipca: IndicatorsIndicator;
        poup: IndicatorsIndicator;
        selic: IndicatorsIndicator;
        selic_meta: IndicatorsIndicator;
        cdi: IndicatorsIndicator;
    }
    cryptos:  {[key: string]: IndicatorsCryptoDeal};
}


@Injectable()
export class IndicatorsService extends ProgressiveServiceBase {
    constructor (private connector: Connector, localStorage: LocalStorageService) {
        super(localStorage);
    };

    public get indicators() {
        const token = this.localStorage.getItem('g-recaptcha')
        return this.connector.api.get("indicatorsReqApiVn", {
            'g-rcp-tkn': token
        })
    }

    public get indicatorsObject(): Promise<object> {
        return new Promise<object>((resolve, reject) => {
            this.indicators.then(
                this.then("indicators", resolve)
            ).catch(
                this.catch("indicators", resolve, reject)
            );
        });
    }

    private __getTimestamp(date: number | Date): number
    private __getTimestamp(date: any): number {
        if (date instanceof Date) {
            return (<Date>date).getTime();
        }
        return date;
    }

    public indicatorsChart(start?: number | Date, end?: number | Date){
        let path = this.connector.constants.get("indicatorsHistory");
        if (start !== undefined && start !== null) {
            path += "/" + this.__getTimestamp(start).toString();
            if (end !== undefined && end !== null) {
                path += "/" + this.__getTimestamp(end).toString();
            }
        }
        return this.connector.get(path);
    }

    public indicatorsChartObject(start?: number | Date, end?: number | Date): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.indicatorsChart(start, end).then(
                response => {
                    resolve(response)
                }
            ).catch(reject);
        });
    }

    private parseCurrency(currency: IndicatorsCurrencyRequest): IndicatorsCurrency {
        const name = currency.name.split(' ');
        return {
            buy: currency.txc,
            sell: currency.tx,
            date: new Date(parseInt(currency.ts)),
            max: currency.max,
            min: currency.min,
            variation: currency.pctChange,
            name: name[0],
            label: name[1]
        }
    }

    private parseCrypto(crypto: {[key: string]: IndicatorsCryptoRequest}):  {[key: string]: IndicatorsCryptoDeal} {
        crypto = crypto ? crypto : <any>{};
        const result: {[key: string]: IndicatorsCryptoDeal} = {};
        for (const key in crypto) {
            const element = crypto[key];
            const id = element.cripto;
            const i = element.label.lastIndexOf('(');
            let name = "";
            if (i > -1) {
                name = element.label.slice(0, i - 1).toLowerCase();
            } else {
                name = id.toUpperCase();
            }
            result[key] = {
                id: id,
                name: name,
                provider: element.exchange,
                date: new Date(element.data),
                buy: element.compra,
                sell: element.venda,
                volume: element.volume,
                now: element.ultima,
                max: element.max,
                min: element.min,
                variation: element.pct_change,
            };
        }
        return result;
    }

    public get indicatorsNormalized(): Promise<Indicators> {
        return new Promise<Indicators>((resolve, reject) => {
            this.indicatorsObject.then(result => {
                let destaques = [];
                if (result["destaque1"])
                    destaques.push(result["destaque1"]);
                if (result["destaque2"])
                    destaques.push(result["destaque2"]);
                let indicator: Indicators = {
                    destaques: destaques,
                    hash: result["hash"],
                    last_update: new Date(result["last_update"]),
                    cambios: {
                        dolar: this.parseCurrency(result["dolar"]),
                        euro: this.parseCurrency(result["euro"]),
                        libra: this.parseCurrency(result["libra"]),
                    },
                    indices: {
                        igpdi: {
                            now: parseFloat(result["igpdi_mes"]),
                            mes: parseFloat(result["igpdi_mes"]),
                            ano: parseFloat(result["igpdi_ano"]),
                            acu_12: parseFloat(result["igpdi_acu_12"])
                        },
                        igpm: {
                            now: parseFloat(result["igpm_mes"]),
                            mes: parseFloat(result["igpm_mes"]),
                            ano: parseFloat(result["igpm_ano"]),
                            acu_12: parseFloat(result["igpm_acu_12"])
                        },
                        ipca: {
                            now: parseFloat(result["ipca_mes"]),
                            mes: parseFloat(result["ipca_mes"]),
                            ano: parseFloat(result["ipca_ano"]),
                            acu_12: parseFloat(result["ipca_acu_12"])
                        },
                        poup: {
                            now: parseFloat(result["poup_mes_anualizada"]),
                            mes: parseFloat(result["poup_mes"]),
                            ano: parseFloat(result["poup_ano"]),
                            acu_12: parseFloat(result["poup_acu_12"])
                        },
                        selic: {
                            now: parseFloat(result["selic"]),
                            mes: null,
                            ano: null,
                            acu_12: null
                        },
                        selic_meta: {
                            now: parseFloat(result["selic_meta"]),
                            mes: null,
                            ano: null,
                            acu_12: null
                        },
                        cdi: {
                            now: parseFloat(result["cdi"]),
                            mes: null,
                            ano: null,
                            acu_12: null
                        }
                    },
                    cryptos: this.parseCrypto(result["criptomoedas"])
                };
                indicator.indices.ipca.now = indicator.indices.ipca.now || indicator.indices.ipca.acu_12;
                indicator.indices.igpdi.now = indicator.indices.igpdi.now || indicator.indices.igpdi.acu_12;
                indicator.indices.igpm.now = indicator.indices.igpm.now || indicator.indices.igpm.acu_12;
                
                resolve(indicator);
            }).catch(error => {
                reject(error);
            })
        });
    }
}
