<mat-sidenav-container
    ngClass.xs="app-xs app-lt-sm app-lt-md app-lt-lg app-lt-xl"
    ngClass.sm="app-gt-xs app-sm app-lt-md app-lt-lg app-lt-xl"
    ngClass.md="app-gt-xs app-gt-sm app-md app-lt-lg app-lt-xl"
    ngClass.lg="app-gt-xs app-gt-sm app-gt-md app-lg app-lt-xl"
    ngClass.xl="app-gt-xs app-gt-sm app-gt-md app-gt-lg app-xl">
    <mat-sidenav [ngClass]="isDarkMode ? 'dark-secondary-color' : ''" #sidenav [mode]="sidenavMode" class="left-sidenav dark-theme">

        <header class="sidenav-header" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-icon color="primary" class="icon-close" (click)="swipeleft($event)">arrow_back</mat-icon>
            <div fxLayout="column" fxLayoutAlign="space-around center">
                <div *ngIf="user.picture && user.logged; then profileImage else profileNoImage"></div>
                <ng-template #profileImage>
                    <div [style.background-image]="profile_image" class="profile-image"></div>
                </ng-template>
                <ng-template #profileNoImage>
                    <mat-icon svgIcon="icon-avatar-88" color="primary" class="profile-icon"></mat-icon>
                    <!-- <mat-icon svgIcon="perfilvazio" color="primary" class="profile-icon"></mat-icon> -->
                </ng-template>
                <div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="user.logged; then profileUserLogged else profileUserNotLogged">
                </div>
                <ng-template #profileUserNotLogged>
                    <div fxLayout="column" class="text-center">
                        <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="primary-text-color" style="color: rgba(33,33,33,0.87)">Faça o login</span>
                        <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="secondary-text-color" style="color: rgba(33,33,33,0.54)">Para usar todas as funcionalidades</span>
                    </div>
                    <button mat-raised-button color="accent" class="login-button" (click)="login()">Login</button>
                </ng-template>
                <ng-template #profileUserLogged>
                    <div fxLayout="column" class="text-center">
                        <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="primary-text-color" style="color: rgba(33,33,33,0.87);">{{user.name}}</span>
                        <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="secondary-text-color" style="color: rgba(33,33,33,0.54);">{{user.email}}</span>
                    </div>
                    <button mat-raised-button color="primary" class="login-button" routerLink="/app/perfil" (click)="sidenavToggle()" *ngIf="!isFacebookApp">
                        <!-- Meu Perfil -->
                        MINHA CONTA
                    </button>
                </ng-template>
            </div>
            <mat-icon color="primary" class="icon-close icon-right" (click)="openSettings($event)" *ngIf="isSubscribedToOnesignal">
                settings
            </mat-icon>
        </header>
        <perfect-scrollbar class="perfect-scrollbar sidenav-body">
            <px-gdn-sidemenu [ngClass]="isDarkMode ? 'dark-secondary-color' : ''">
                <px-gdn-sideitem title="Home" routerLinkUrl="/app">
                    <mat-icon item-icon svgIcon="indicadores" class="svgicon white"></mat-icon>
                </px-gdn-sideitem>
                <px-gdn-sideitem title="Investimentos" #investimentoSubmenu>
                    <mat-icon item-icon-href svgIcon="investimentos" class="svgicon white"></mat-icon>
                </px-gdn-sideitem>
                <px-gdn-sideitem title="Renda Fixa" routerLinkUrl="/app/investimentos/rendafixa" [menuHandle]="investimentoSubmenu">
                </px-gdn-sideitem>
                <px-gdn-sideitem title="Renda variável" (click)="openRendaVariavel()" [menuHandle]="investimentoSubmenu">
                </px-gdn-sideitem>
                <px-gdn-sideitem title="Fundos de investimento" (click)="saveLastUrl()" routerLinkUrl="/app/fundos" [menuHandle]="investimentoSubmenu">
                </px-gdn-sideitem>
                
                <!-- <px-gdn-sideitem title="Investir" routerLinkUrl="/app/parceiros">
                    <mat-icon item-icon svgIcon="investirpeloapp" class="svgicon white"></mat-icon>
                </px-gdn-sideitem> -->

                <px-gdn-sideitem title="Ferramentas" #submenuFerramentas>
                    <mat-icon item-icon-href svgIcon="servicos" class="svgicon white"></mat-icon>
                </px-gdn-sideitem>

                <px-gdn-sideitem [menuHandle]="submenuFerramentas" title="Comparador de fundos" (click)="saveLastUrl()" routerLinkUrl="/app/fundos/comparador/">

                </px-gdn-sideitem>

                <px-gdn-sideitem [menuHandle]="submenuFerramentas" title="Ranking" routerLinkUrl="/app/ranking">
 
                </px-gdn-sideitem>

                <px-gdn-sideitem [menuHandle]="submenuFerramentas" title="Calculadoras" routerLinkUrl="/app/calculadoras">
                </px-gdn-sideitem>

                <px-gdn-sideitem title="Descubra seu perfil" routerLinkUrl="/app/perfil/suitability" [menuHandle]="submenuFerramentas">
                </px-gdn-sideitem>

                <px-gdn-sideitem title="Aprender" #submenuEducations>
                    <mat-icon item-icon-href svgIcon="school" class="svgicon white"></mat-icon>
                </px-gdn-sideitem>

                <px-gdn-sideitem title="Materiais gratuitos" (click)="linkMateriais()" [menuHandle]="submenuEducations">
                </px-gdn-sideitem>

                <px-gdn-sideitem title="Blog" routerLinkUrl="/app/noticias" [menuHandle]="submenuEducations">
                </px-gdn-sideitem>

                <px-gdn-sideitem title="Vídeos" (click)="linkYoutube()" [menuHandle]="submenuEducations">
                </px-gdn-sideitem>

                <px-gdn-sideitem title="Dúvidas frequentes" routerLinkUrl="/app/faq" [menuHandle]="submenuEducations">
                </px-gdn-sideitem>
                
                <px-gdn-sideitem title="Fale com Especialista" [action]="true" (click)="openAssessorDialog()">
                    <mat-icon item-icon-href svgIcon="whatsapp" class="svgicon white"></mat-icon>
                </px-gdn-sideitem>
            </px-gdn-sidemenu>
        </perfect-scrollbar>
    </mat-sidenav>
    <app-toolbar class="page-toolbar" *ngIf="!isToolbarHidden"></app-toolbar>
    <div [class]="pageClass" role="main">
        <router-outlet (activate)="componentAdded($event)" (deactivate)="componentRemoved($event)"></router-outlet>
    </div>
    <app-footer *ngIf="!isFooterHidden"></app-footer>
</mat-sidenav-container>
