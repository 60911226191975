export class ApiError extends Error {
    constructor(public what: string, public status?: number) {
        super(what);
    }
}

export class InternalServerError extends ApiError {
    private static _message: string = "Ocorreu um erro em nosso servidor";
    constructor() {
        super(InternalServerError.message, 500);
    }

    public static get message(): string {
        return this._message;
    }
}

export class ServerUnavailableError extends ApiError {
    private static _message: string = "O nosso servidor está temporariamente indisponivel";
    constructor() {
        super(ServerUnavailableError.message, 503);
    }

    public static get message(): string {
        return this._message;
    }
}

export function getApiError(response)  {
    let message;
    switch (response.status) {
        case 500:
            return new InternalServerError();
        case 503:
            return new ServerUnavailableError();
    }
    try {
        if(response.error['message']) message = response.error['message'];
        else message = response['message'];
        message = message ? message : "";
        message = message.message ? message.message : message;
        message = message.replace ? message.replace(/\"/g, '') : message;
        return new ApiError(message, response.status);
    } catch (err) {
        message = InternalServerError.message;
    }
    return new ApiError(message, response.status);
}