import {Injectable, NgZone} from '@angular/core';
import {Constants} from './constants';
import {UserInterface} from './../model/user';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

declare const FB;
const __appId = '909351845795011';
const __socialPlugins = false;
const __requireFields = 'id, name, first_name, last_name, email, gender, picture.height(961), age_range, birthday';
// const __requireFields = 'id, name, email, picture.height(961)';

/* Alterado a função abaixo para remover o parametro url.
Nas chamadas da função a url nunca é fornecida, portanto é inútil ela existir, 
para fins de teste a unica chamada obrigatória dela na função será trocada por undefined.
Isto é necessário para a implementação do SSR que não pode haver window.(...)

linha removida: 
if (!url) url = window.location.href;
*/

function getParameterByName(name: string) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(undefined);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export interface FBLoginStatus {
    logged: boolean,
    authResponse?: {
        accessToken: string,
        expiresIn: number,
        signedRequest: any,
        userID: string
    }
}

export interface FBUserProfile {
    _id: string,
    name: string,
    firstName: string,
    lastName: string,
    email: string,
    gender: string,
    picture: string,
    ageRange: string,
    birthday: string
}

export interface FBUser extends FBUserProfile {
    token: string
}

export interface FBUi {
    method?: string,     // 'feed',
    name?: string,       // 'This is the content of the "name" field.',
    link?: string,       // 'http://www.hyperarts.com/external-xfbml/'+post.id,
    picture?: string,       // 'http://www.hyperarts.com/external-xfbml/share-image.gif',
    caption?: string,       // post.caption,
    description?: string,       // 'This is the content of the "description" field, below the caption.',
    message?: string
}

@Injectable()
export class FacebookService {
    private static isDebug: boolean = false;
    private static _inited: boolean = false;
    private static _fbcg;
    private static _originIsFacebook = FacebookService.verifyOrigin();
    private static _fbAppInited = false;
    private static _subject = new Subject();
    constructor(private $zone: NgZone) {}

    private static verifyOrigin(): boolean {
        const origin = getParameterByName('origin');
        return origin === 'facebook';
    }

    private _inject(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        FacebookService.isDebug = !new Constants().env.production;
        let js = d.createElement(s); 
        js.id = id;
        if (FacebookService.isDebug)
            js.src = "https://connect.facebook.net/pt_BR/sdk/debug.js";
        else
            js.src = "https://connect.facebook.net/pt_BR/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
        FacebookService._inited = true;
    }

    private static onFBApp(): void {
        console.log("Bem vindo ao APP Renda Fixa no Facebook!");
        setInterval(() => {
            let mh = 600;
            FB.Canvas.getPageInfo((info) => {
                let w = info.clientWidth;
                let h = info.clientHeight;
                
                FB.Canvas.setSize({
                    width: w,
                    height: h < mh ? mh : h,
                });
            });
        }, 600);
        this._fbAppInited = true;
        this._subject.next();
    }

    private static fbAsyncInit() {
        FB.init({
            appId: __appId,
            autoLogAppEvents: FacebookService.isDebug,
            xfbml: __socialPlugins,
            version: 'v2.11'
        });
        
        if (FacebookService._originIsFacebook) {
            FacebookService.onFBApp() ;
        }
    }

    public static onRunningOnFacebook(callback: () => void): void {
        this._subject.subscribe(callback);
    }

    public get inited(): boolean {
        return FacebookService._inited;
    }

    public inject() {
        if (this.inited) return;
        this.$zone.runOutsideAngular(() => {
            window["fbAsyncInit"] = FacebookService.fbAsyncInit;
            setTimeout(() => this._inject(document, 'script', 'facebook-jssdk'));
        });
    }

    protected get FB(): any {
        return FB || {};
    }

    /*
     * Rejeita e retorna com seguranca o parametro arg no caso !arg
     */
    protected safe(arg, reject): any {
        if (arg)
            return arg;
        reject(undefined);
        return function(){};
    }

    public getLoginStatus(): Promise<FBLoginStatus> {
        return new Promise<FBLoginStatus>((resolve, reject) => {
            this.safe(this.FB.getLoginStatus, reject)(response => {
                const logged = response.status === 'connected';
                if (logged) {
                    resolve({
                        logged: logged,
                        authResponse: response.authResponse
                    });
                } else {
                    reject(response.status);
                }
            });
        });
    }

    private testPermissionGranted(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.safe(this.FB.api, reject)('/me/permissions', function(response) {
                const critical = {
                    "public_profile": true, 
                    "email": true
                };
                let permitted: string[] = [];
                for (let i = 0; i < response.data.length; i++) { 
                    const item = response.data[i];
                    if (item.status == 'declined' && critical[item.permission]) {
                        reject(item.permission);
                        break;
                    }
                    permitted.push(item.permission);
                }
                resolve(permitted);
            });
        });
    }

    public getUserProfile(): Promise<FBUserProfile> {
        return new Promise<FBUserProfile>((resolve, reject) => {
            this.safe(this.FB.api, reject)("/me", {fields: __requireFields}, user => {
                const picture = user.picture ? user.picture.data.url : null;
                resolve({
                    _id: user.id, 
                    name: user.name, 
                    firstName: user.first_name, 
                    lastName: user.last_name, 
                    email: user.email, 
                    gender: user.gender, 
                    picture: picture, 
                    ageRange: user.age_range, 
                    birthday: user.birthday,
                });
            });
        });
    }

    public logout(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.safe(this.FB.logout, reject)(response => {
                resolve(response);
            }).catch(err => {
                reject(null);
            });
        });
    }

    public login(): Promise<FBUser> {
        return new Promise<FBUser>((resolve, reject) => {
            this.FB.login(response => {
                const logged = response.status === 'connected';
                if (!logged) {
                    return reject(response.status);
                }
                this.getUserProfile().then(user => {
                    const name = user.name ? user.name : (user.firstName + " " + user.lastName);
                    if (!user.email) {
                        return reject("Não foi possível obter o email do usuário");
                    } else if (!name) {
                        return reject("Não foi possível obter o nome do usuário");
                    }
                    resolve({
                        token: response.authResponse.accessToken,
                        _id: user._id,
                        name: name,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        gender: user.gender,
                        picture: user.picture,
                        ageRange: user.ageRange,
                        birthday: user.birthday
                    });
                }).catch(err => {
                    this.logout().then(() => {
                        reject("Problemas para obter as informações do usuário");
                    }).catch(() => {
                        reject("Não foi possível fazer o login");
                    });
                });
            },{
                scope: 'email',
                return_scopes: true
            });
        });
    }

    public ui(params: FBUi): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.safe(this.FB.ui, reject)({
                method: params.method,
                name: params.name,
                link: params.link,
                picture: params.picture,
                caption: params.caption,
                description: params.description,
                message: params.message
            }, response => {
                resolve(response);
            });
        });
    }

    public shareToFeed(params: {
        url?: string, title?: string, image?: string, 
        description?: string, message?: string, caption?: string
    }): Promise<any> {
        return this.ui({
            method: 'feed',
            link: params.url,
            name: params.title,
            picture: params.image,
            caption: params.caption,
            description: params.description,
            message: params.message
        });
    }
}
