import { PageService } from "../providers/page.service";
import { Injectable, OnDestroy } from "@angular/core";

// TODO: Add Angular decorator.
@Injectable()
export class PageBase implements OnDestroy {
    constructor(title?: string) {
        if (title) {
            this.setPageTilte(title);
        }
    }

    protected setPageTilte(value: string): void {
        PageBase.setPageTilte(value);
    }

    protected getPageTilte(): string {
        return PageBase.getPageTilte();
    }

    protected static setPageTilte(value: string): void {
        PageService.title = value;
    }

    protected static getPageTilte(): string {
        return PageService.title;
    }

    ngOnDestroy(): void {
        PageService.restore();
    }
}
