import { Component, Input, Output, ViewChild, EventEmitter, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { GraficoBase } from './grafico.base';

declare const Highcharts: any;
declare const $: any;
const defaultOptions = {};
type DataRefresher = (options: any) => void;



@Component({
    selector: 'app-highchart',
    template: '<div #highchartElement class="highcharts-container"></div>',
    styleUrls: ['./highcharts.component.scss'],
})
export class HighchartsComponent extends GraficoBase {
    @ViewChild("highchartElement", {static: true}) __chartElement: ElementRef;
    @Input('chartOptions') __options: any = {};
    @Input('width') width: any;
    @Input('height') height: any;
    @Output('onRefresh') onRefresh: EventEmitter<DataRefresher> = new EventEmitter<DataRefresher>();
    isHighcharts
    private chart;
    constructor(
        private el: ElementRef,
        @Inject(PLATFORM_ID) private _platformId: string
        ) {
        super(_platformId);
        setTimeout(() => {
            Highcharts.setOptions({
                lang: {
                    decimalPoint: ',',
                    thousandsSep: '.',
                    contextButtonTitle: 'Imprimir ou baixar gráfico(s). ',
                    printChart: 'Imprimir',
                    downloadPNG: 'Exportar no formato PNG',
                    downloadJPEG: 'Exportar no formato JPEG',
                    downloadPDF: 'Exportar no formato PDF',
                    downloadSVG: 'Exportar no formato SVG',
                    resetZoom: 'Desfazer zoom',
                    resetZoomTitle: '',
                    months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                    shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                    weekdays: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"]
                }
            });
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.captureChartInstance({
                resize: () => {
                },
                setOption: (options: any) => {
                    const native = this.nativeElement;
                    if (this.chart) {
                        if (this.width || this.height) {
                            options.chart.width = this.width || undefined;
                            options.chart.height = this.height || undefined;
                        }
                        this.chart.update(options);
                    } else {
                        options.title = options.title || {};
                        options.title.text = options.title.text || "";
                        options.chart = options.chart ? options.chart : {};
                        options.chart.renderTo = native;
                        options.credits = options.credits || { enabled: false };
                        this.chart = new Highcharts.Chart(options);
                        setTimeout(() => {
                            this.chart.update(options);
                            this.chart.reflow();
                        }, 1000);
                    }
                }
            });
            super.ngAfterViewInit();
        }, 1000);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private get nativeElement(): any {
        return (this.__chartElement || this.el).nativeElement;
    }

    protected onInit() {
        this.onRefresh.emit((options) => this.refresh(options));
    }

    protected onResize() {
        this.recycle();
        this.onRefresh.emit((options) => this.refresh(options));
    }

    refresh(options: any) {
        this.setOptions(this.__options, options);
    }

    recycle() {
        this.refresh(defaultOptions);
    }

    public get options() {
        return this.__options;
    }
}
