// import {
//   animate,
//   state,
//   style,
//   transition,
//   trigger
// } from '@angular/animations';
// import {Location} from '@angular/common';
// import {CloseService} from '../close.service';
// import {Subscription} from 'rxjs/Subscription';
// import {Component, Input, Output, EventEmitter, OnDestroy, OnInit, OnChanges, SimpleChanges} from '@angular/core';
// import { RouterStateService } from '../../../providers/router-state.service';
// import { DOCUMENT } from '@angular/platform-browser';
// import {Inject} from '@angular/core';
// import { Router } from '@angular/router';


// @Component({
//   selector: 'px-gdn-sideitem',
//   templateUrl: './sideitem.component.html',
//   styleUrls: ['./sideitem.component.scss'],
//   animations: [
//     trigger('closeState', [
//       state('close', style({
//         height: '0'
//       })),
//       state('open', style({
//         height: '*'
//       })),
//       transition('close => open', animate('250ms ease-in')),
//       transition('open => close', animate('250ms ease-out'))
//     ])]
// })
// export class SideitemComponent implements OnInit, OnDestroy, OnChanges {
//   private _isClosed = true;
//   private _isMenu: boolean;
//   // private closeServiceSubscription: Subscription;
//   @Input() title: string;
//   @Input() closeState = 'close';
//   @Input() routerLinkUrl: string;
//   @Input() href: string;
//   @Input() action: boolean;
//   @Input("menuHandle") submenuHandle: SideitemComponent;
//   // menuLevel: number = -1;
//   // @ContentChildren(SideitemComponent, {descendants: true}) contentChildren: QueryList<SideitemComponent>;

//   constructor(
//     private closeService: CloseService, 
//     public location: Location,
//     private routerStateService: RouterStateService,
//     private router: Router,
//     ) {
//     // this.closeServiceSubscription = closeService.closeInformer$.subscribe(next => {
//     //   if (next === this.menuLevel) {
//     //     this.closeState = 'close';
//     //   }
//     // });
//   }

//   private forceGuard (title) {
//     // Verificando se é path que precisa de route. Feito desse modo por problema em identificar a rota que foi clickada.
//     if (
//       title === 'Bate-papo' ||
//       title === 'Fórum'
//       ) {
//         this.router.navigateByUrl(null);
//       }
//   }

//   ngOnInit() {
//     this._isClosed = true;
//   }

//   // ngAfterContentInit(): void {
//   //   // this.contentChildren.forEach((item) => {
//   //   //   item.incrementMenuLEvel();
//   //   // });
//   // }

//   ngOnChanges(changes: SimpleChanges): void {
//     if (changes.submenuHandle) {
//       const cur: SideitemComponent = changes.submenuHandle.currentValue;
//       if (cur) {
//         cur.register();
//       }
//     }
//   }

//   ngOnDestroy(): void {
//     // this.closeServiceSubscription.unsubscribe();
//   }

//   isClosed(): boolean {
//     return this._isClosed;
//   }

//   close(): void {
//     this._isClosed = true;
//   }

//   register(): void {
//     this._isMenu = true;
//     this.action = true;
//   }

//   unregister(): void {
//     this._isMenu = false;
//   }

//   get isMenu(): boolean {
//     return this._isMenu;
//   }

//   closeSidemenu(title) {
//     if (this.submenuHandle) {
//       this.submenuHandle.close();
//     }
//     if (this.isMenu) {
//       this._isClosed = !this._isClosed;
//       return;
//     } else if (this.action) {
//       return;
//     }
//     this.forceGuard(title);
//     setTimeout(() => {
//       this.closeService.close();
//     });
//   }

//   get isHidden(): boolean {
//     return this.submenuHandle ? this.submenuHandle.isClosed() : false;
//   }
// }


import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {Location} from '@angular/common';
import {CloseService} from '../close.service';
import {Subscription} from 'rxjs';
import {Component, Input, Output, EventEmitter, OnDestroy, OnInit, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'px-gdn-sideitem',
  templateUrl: './sideitem.component.html',
  styleUrls: ['./sideitem.component.scss'],
  animations: [
    trigger('closeState', [
      state('close', style({
        height: '0'
      })),
      state('open', style({
        height: '*'
      })),
      transition('close => open', animate('250ms ease-in')),
      transition('open => close', animate('250ms ease-out'))
    ])]
})
export class SideitemComponent implements OnInit, OnDestroy, OnChanges {
  private _isClosed: boolean = true;
  private _isMenu: boolean;
  // private closeServiceSubscription: Subscription;
  @Input() title: string;
  @Input() closeState = 'close';
  @Input() routerLinkUrl: string;
  @Input() href: string;
  @Input() action: boolean;
  @Input("menuHandle") submenuHandle: SideitemComponent;
  // menuLevel: number = -1;
  // @ContentChildren(SideitemComponent, {descendants: true}) contentChildren: QueryList<SideitemComponent>;

  constructor(private closeService: CloseService, public location: Location) {
    // this.closeServiceSubscription = closeService.closeInformer$.subscribe(next => {
    //   if (next === this.menuLevel) {
    //     this.closeState = 'close';
    //   }
    // });
  }

  ngOnInit() {
    this._isClosed = true;
  }

  // ngAfterContentInit(): void {
  //   // this.contentChildren.forEach((item) => {
  //   //   item.incrementMenuLEvel();
  //   // });
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submenuHandle) {
      const cur: SideitemComponent = changes.submenuHandle.currentValue;
      if (cur) {
        cur.register();
      }
    }
  }


  ngOnDestroy(): void {
    // this.closeServiceSubscription.unsubscribe();
  }

  isClosed(): boolean {
    return this._isClosed;
  }

  close(): void {
    this._isClosed = true;
  }

  register(): void {
    this._isMenu = true;
    this.action = true;
  }

  unregister(): void {
    this._isMenu = false;
  }

  get isMenu(): boolean {
    return this._isMenu;
  }

  closeSidemenu(): void {
    if (this.submenuHandle) {
      this.submenuHandle.close();
    }
    if (this.isMenu) {
      this._isClosed = !this._isClosed;
      return;
    } else if (this.action) {
      return;
    }
    this.closeService.close();
  }

  get isHidden(): boolean {
    return this.submenuHandle ? this.submenuHandle.isClosed() : false;
  }
}