import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../../providers/user.service';
import { MongeralService } from '../../../providers/mongeral.service';
import { isPlatformBrowser } from '@angular/common';
import { Connector } from 'app/providers/connector';



@Component({
  selector: 'app-assessor-links-dialog',
  templateUrl: './assessor-links-dialog.component.html',
  styleUrls: ['./assessor-links-dialog.component.scss']
})
export class AssessorLinksDialogComponent implements OnInit {
  public whatsappDialogIcon: string = '../../../../assets/icons/whatsapp-dialog-talk.svg';
  public emailDialogIcon: string = '../../../../assets/icons/email-dialog-talk-orange.svg';
  public phoneDialogIcon: string = '../../../../assets/icons/phone-dialog-talk.svg';
  public telegramDialogIcon: string = '../../../../assets/icons/telegram-dialog-talk.svg';
  public type: string = 'talk-to-expert';
  public userName;
  public userEmail;
  public userTel;
  public userAge;
  public userValue;
  public userOthers;
  public userCountry: string = '55';
  public form: FormGroup;
  public formAllLife: FormGroup;
  public formPrivateSolutions: FormGroup;
  public enableButtonSend: boolean = true;
  public message: string;
  public phonefixo: string;
  public validPhone: string;
  public validPhoneBorder: string;
  public checked: string;
  public isPhoneOrMobile: string;
  public typePhone: string;
	public isDarkMode: boolean = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AssessorLinksDialogComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private mongeralService: MongeralService,
    private snackbar: MatSnackBar,
    private connector: Connector,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    const user = this.userService.user;
    this.userName = user.name;
    this.userEmail = user.email;
    if(isPlatformBrowser(this.platformId)){
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) this.isDarkMode = true;
		  else this.isDarkMode = false;  
    }
    else this.isDarkMode = false;
		
  }
  ngOnInit() {
    if(!this.data) this.data = {type: null};
    switch(this.data.type){
      case 'all-life':
        this.type = 'all-life';
        break;
      case 'private-solutions':
        this.type = 'private-solutions';
        break;
      case 'life-calm':
        this.type = 'life-calm';
        break;
      case 'calcLeads':
        this.type = 'calcLeads'
        break;
      case 'success':
        this.type = 'success';
        break;
      default:
        this.type = 'talk-to-expert'
    }
    this.createData();
  }
  public static get assessorWhatsapp(): string {
    return "https://api.whatsapp.com/send?phone=5511988829851";
  }
  public static get assessorEmail(): string {
    return "contato@rendafixa.rocks";
  }
  public static get assessorTel(): string{
    return '55-11-98882-9851';
  }
  public static get assessorTelegram(): string{
    return 'http://bit.ly/telegram-apprendafixa'
  }
  public get email(): string {
    return AssessorLinksDialogComponent.assessorEmail;
  }
  public get whatsapp(): string {
    return AssessorLinksDialogComponent.assessorWhatsapp;
  }
  public get telefone(): string{
    return AssessorLinksDialogComponent.assessorTel;
  }
  public get telegram(): string {
    return AssessorLinksDialogComponent.assessorTelegram;
  }
  
  public createData(){
    this.form = this.fb.group({
      name: new FormControl(this.userName, Validators.compose([Validators.required, Validators.minLength(4)])),
      email: new FormControl(this.userEmail, Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])),
      age: new FormControl(this.userAge, Validators.compose([Validators.required, Validators.maxLength(7)])),
      value: new FormControl(this.userValue, [Validators.required]),
      others: new FormControl(this.userOthers, [Validators.required]),
      country: new FormControl(this.userCountry,Validators.compose([Validators.required, Validators.maxLength(3)]))
    });
    this.formAllLife = this.fb.group({
      name: new FormControl(this.userName, Validators.compose([Validators.required, Validators.minLength(4)])),
      email: new FormControl(this.userEmail, Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])),
      country: new FormControl(this.userCountry,[Validators.required])
    });
    this.formPrivateSolutions = this.fb.group({
      name: new FormControl(this.userName, Validators.compose([Validators.required, Validators.minLength(4)])),
      email: new FormControl(this.userEmail, Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])),
      age: new FormControl(this.userAge, Validators.compose([Validators.required, Validators.maxLength(7)])),
      value: new FormControl(this.userValue, [Validators.required]),
      country: new FormControl(this.userCountry,Validators.compose([Validators.required, Validators.maxLength(3)]))
    });
  }
  public sendLeads(telmask, type){
    var prefix = this.userCountry; // Brasil
    var tel = telmask.inputElement.value.replace(/[{()}]/g, '').replace(' ','');
    var phone = prefix + tel

    switch(type){
      case 'calcLeads':
        let calcLead = {
          user: {
            nome_completo: this.formAllLife.value.name,
            telefone: {
              tipo: this.typePhone,
              numero: phone
            },
            email: this.formAllLife.value.email
          },
          tipo: 'calculadora'
        }
        this.sendDataToServer(calcLead)
        break;
      case 'all-life':
        let life = {
          user: {
            nome_completo: this.formAllLife.value.name,
            telefone:{
              tipo: this.typePhone,
              numero: phone
            },
            email: this.formAllLife.value.email
          },
          tipo: 'seguro',
          // name: this.formAllLife.value.name,
          // email: this.formAllLife.value.email,
          // country: this.formAllLife.value.country,
          // tel: phone
        }
        this.sendDataToServer(life);
      break;
      case 'private-solutions':
        let solutions = {
          user: {
            nome_completo: this.formPrivateSolutions.value.name,
            telefone: {
              tipo: this.typePhone,
              numero: phone
            },
            email: this.formPrivateSolutions.value.email
          },
          tipo: 'previdencia',
          detalhes: {
            valor_mes: this.formPrivateSolutions.value.value,
            idade: this.formPrivateSolutions.value.age
          }
          // name: this.formPrivateSolutions.value.name,
          // email: this.formPrivateSolutions.value.email,
          // value: this.formPrivateSolutions.value.value,
          // age: this.formPrivateSolutions.value.age,
          // tel: phone
        }
        this.sendDataToServer(solutions);
      break;
      case 'life-calm':
        let calm = {
          user: {
            nome_completo: this.form.value.name,
            telefone:{
              tipo: this.typePhone,
              numero: phone
            },
            email: this.form.value.email
          },
          tipo: 'longo_prazo',
          detalhes: {
            finalidade: this.form.value.others,
            idade_retirada: this.form.value.age,
            valor_total: this.form.value.value
          }
          // name: this.form.value.name,
          // email: this.form.value.email,
          // age: this.form.value.age,
          // value: this.form.value.value,
          // others: this.form.value.others,
          // country: this.form.value.country,
          // tel: phone
        }
        this.sendDataToServer(calm);
      break;
    }
  }

  public sendDataToServer(data){
    if(data.tipo == 'calculadora'){
      // esperando endpoint 
      this.dialogRef.close(true)
    }else if(data.tipo != 'calculadora'){
      this.mongeralService.sendLeadsToServer(data).then(val => {
        this.dialogRef.close(val)
      }).catch(error => {
        console.log(error);
        if (error.error.return.message.errors.email)
        {
          this.snackbar.open('O e-mail informado não é válido.', '', {duration: 4500});
        }
      });
    }
  }
  public closeDialog(){
    this.dialogRef.close();
  }
  public onKeyUp(telmask, typeForm){
    var continua = true;
    var prefix = this.form.value.country; // Brasil
    var tel = telmask.inputElement.value.replace(/[{()}]/g, '').replace(' ','');
    var phone = prefix + tel

    // verificar se há preenchimento
    if(phone.length == 0){
      this.message = 'telefone inválido.';
      this.validPhone = 'invalid-phone';
      this.validPhoneBorder = 'warn';
      this.isPhoneOrMobile = '';
      continua = false;
    }

    // verificar se o número é de entidade públca
    if(phone.length === 3){
      this.message = 'telefone inválido.';
      this.validPhone = 'invalid-phone';
      this.validPhoneBorder = 'warn';     
      this.checked = 'close';
      this.isPhoneOrMobile = '';
      continua = false
    }

    // verificar se tem código do pais +55
    if(continua && phone.substring(0, 3) == ''){
      phone = phone.substring(3);
    }
    var type: string;
    // verificar o 5º digito
    var digitControl = parseInt(phone.charAt(4));
    if(continua){
      if(digitControl >= 2 && digitControl <= 6){
        if(phone.length < 12){
          telmask.inputElement.maxLength = 13 
          this.message = 'telefone inválido.';
          this.validPhone = 'invalid-phone';
          this.validPhoneBorder = 'warn';
          this.checked = 'close';
          this.isPhoneOrMobile = '';
          this.enableButtonSend = true;
        }
        if(phone.length == 12) {
          type = 'fixo';
          this.typePhone = 'residencial';

        }
      }
      if(digitControl >= 7 && digitControl <= 9){
        telmask.inputElement.maxLength = 14
        if(phone.length < 14){
          this.message = 'telefone inválido.';
          this.validPhone = 'invalid-phone';
          this.validPhoneBorder = 'warn';
          this.checked = 'close';
          this.isPhoneOrMobile = '';
          this.enableButtonSend = true;
        }
        if(phone.length == 13){
          type = 'movel';
          this.typePhone = 'celular';
        }
      }
    }
    if(type == 'fixo'){
      this.message = 'telefone fixo.';
      this.validPhone = 'valid-phone';
      this.checked = 'check';
      this.isPhoneOrMobile = 'phone';
      this.validPhoneBorder = 'success';
      this.enableButtonSend = false;
    }
    if(type == 'movel'){
      this.message = 'telefone celular.';
      this.validPhone = 'valid-phone';
      this.checked = 'check';
      this.isPhoneOrMobile = 'phone_iphone';
      this.validPhoneBorder = 'success';
      this.enableButtonSend = false;
    }
    switch(typeForm){
      case 'AllLife':
        (type && this.formAllLife.status == 'VALID') ? this.enableButtonSend = false : this.enableButtonSend = true;
      break;
      case 'privateSolutions':
        (type && this.formPrivateSolutions.status == 'VALID') ? this.enableButtonSend = false : this.enableButtonSend = true;
      break;
      case 'form':
        (type && this.form.status == 'VALID') ? this.enableButtonSend = false : this.enableButtonSend = true;
      break;
      default:
        this.enableButtonSend = true;
    }
  }

  public get tel(): string{
    return AssessorLinksDialogComponent.assessorTel;
  }

  public toTel(){
    // this.document.location.href = `tel:${this.tel}`;
    this.connector.openInNewTab(`tel:${this.tel}`); // tem que testar no telefone em servidor de teste
  }
}
