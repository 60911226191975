import { CanActivateChild, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

@Injectable()
export class ChartService {
    constructor(
    ) {
    }


    // [[[820, 932, 901, 934, 1290, 1330, 1320], [820, 932, 901, 934, 1290, 1330, 1320]], [["2016-1", "2016-2", "2016-3", "2016-4", "2016-5", "2016-6", "2016-7", "2016-8", "2016-9", "2016-10", "2016-11", "2016-12"],["2016-1", "2016-2", "2016-3", "2016-4", "2016-5", "2016-6", "2016-7", "2016-8", "2016-9", "2016-10", "2016-11", "2016-12"]]]

    // legend: {
    //     show: true,
    //     orient: 'hoverAnimation',
    //     x: 'right',
    //     y: 'top',
    //     data: ['apk', '邮件营销', '联盟广告'],
    //     itemWidth: 5,
    //     itemHeight: 5,
    //     textStyle: {
    //         color: '#000000'
    //     }
    // },

    current_chart_data;
    isFirstChart = true;

    getIsFirstChart () {
        return this.isFirstChart;
    }

    updateFisrtChart() {
        this.isFirstChart = this.isFirstChart;
    }

    getAlocacao(data) {
        let total = 0;
        const keys_arr = Object.keys(data);
        keys_arr.map((key) => {
            total = total + data[key]
        })

        const arr = keys_arr.map((key) => {
            let percentage = (data[key] / total) * 100;
            const fixed = percentage.toFixed(1);
            let key_with_percentage = key + ' ' + fixed + '%'
            return [key_with_percentage, { name: key_with_percentage, value: data[key] }];
        });
        let data_legend = [];
        let data_chart = [];
        for (const i of arr) {
            data_legend.push(i[0]);
            data_chart.push(i[1]);
        }

        return ({
            color: [
                '#ffa300',
                '#008978',
                '#50d350',
                '#4259cc',
                '#ae29ce',
                '#ff5b22',
                '#e5235f',
                '#31a6f4'
            ],
            animation: false,
            legend: {
                selectedMode: false,
                show: true,
                x: 'center',
                y: 215,
                height: '100%',
                data: data_legend,
                itemWidth: 3,
                itemHeight: 3,
                textStyle: {
                    color: '#000000'
                },
                padding: 2
            },
            tooltip: {
                show: 'true',
                trigger: 'none',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    legendHoverLink: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'top'
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: data_chart,
                    symbol: 'none'
                }
            ]
        })
    }


    oldgetAlocacao() {
        return ({
            color: ['#217EF2', '#01C1B6', '#5200C1'],
            animation: false,
            legend: {
                show: true,
                orient: 'hoverAnimation',
                x: 'right',
                y: 'top',
                data: ['Ações', 'Fundos DI', 'Renda Fixa'],
                itemWidth: 5,
                itemHeight: 5,
                textStyle: {
                    color: '#000000'
                }
            },
            tooltip: {
                trigger: 'none',
            },
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: [
                        { value: 335, name: 'Ações' },
                        { value: 310, name: 'Fundos DI' },
                        { value: 234, name: 'Renda Fixa' },
                    ],
                    symbol: 'none'
                }
            ]
        })
    }

    getIndice(indice: number) {
        let categoria;
        if (indice < 1) {
            if (indice < 0) {
                categoria = 'Ruim'
            } else {
                categoria = 'Médio'
            }
        } else if (indice > 1) {
            if (indice < 2) {
                categoria = 'Bom'
            } else {
                categoria = 'Ótimo'
            }
        }

        let color;
        let title;
        let data;

        switch (categoria) {
            case 'Médio':
                color = ['#EAEAEA', '#EAEAEA', '#167AF7', '#EAEAEA']
                // title = 'ultra \n conservador'
                title = indice + '\n Retorno/Risco \n' + 'Médio'
                data = [
                    { value: 300000 },
                    { value: 900000 },
                    { value: 300000 },
                    { value: 300000 },
                    { value: 300000 },
                ]
                break;
            case 'Ótimo':
                color = ['#167AF7', '#EAEAEA', '#EAEAEA', '#EAEAEA']
                title = indice + '\n Retorno/Risco \n' + 'Ótimo'
                data = [
                    { value: 300000 },
                    { value: 900000 },
                    { value: 300000 },
                    { value: 300000 },
                    { value: 300000 },
                ]
                break;
            case 'Ruim':
                title = indice + '\n Retorno/Risco \n' + 'Ruim'
                color = ['#EAEAEA', '#167AF7', '#EAEAEA', '#EAEAEA'];
                data = [
                    { value: 500000 },
                    { value: 1000000 },
                    { value: 500000 },
                    { value: 500000 },
                ]
                break;

            case 'Bom':
                color = ['#EAEAEA', '#EAEAEA', '#EAEAEA', '#EAEAEA', '#167AF7', '#EAEAEA']
                title = indice + '\n Retorno/Risco \n' + 'Bom'
                data = [
                    { value: 500000 },
                    { value: 100000 },
                    { value: 500000 },
                    { value: 500000 },
                    { value: 500000 },
                ]
                break;

            default:
                break;
        }

        return ({
            color: color,
            title: {
                text: title,
                x: 'center',
                y: 'bottom',
                itemGap: 10,
                textStyle: {
                    color: '#535353',
                    fontSize: 10,
                    fontWeight: 'bolder',
                    padding: 100
                },
            },
            animation: false,
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    center: ['50%', '95%'],
                    startAngle: 0,
                    endAngle: 300,
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: false,
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '10',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: data
                }
            ]
        });
    }

    getPerfil(color, title, data_chart) {

        return ({
            color: color,
            title: {
                text: title,
                x: 'center',
                y: 'bottom',
                itemGap: 10,
                textStyle: {
                    color: '#535353',
                    fontSize: 10,
                    fontWeight: 'bolder',
                    padding: 100
                },
            },
            animation: false,
            tooltip: {
                show: 'hide',
                trigger: 'none',
                showContent: 'false',
                axisPointer: {
                    type: 'none',
                }
            },
            series: [
                {
                    selectedMode: false,
                    type: 'pie',
                    radius: ['50%', '70%'],
                    center: ['50%', '95%'],
                    startAngle: 0,
                    endAngle: 300,
                    avoidLabelOverlap: true,
                    label: {
                        normal: {
                            show: false,
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                fontSize: '10',
                                fontWeight: 'bold'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: data_chart,
                }
            ]
        });
    }

    // return({
    //     title: {
    //         text: '你幸福吗 \n ？\n aaaa',
    //         subtext: 'From ExcelHome',
    //         x: 'center',
    //         y: 'bottom',
    //         itemGap: 10,
    //         textStyle : {
    //             color : 'rgba(30,144,255,0.8)',
    //             fontSize : 10,
    //             fontWeight : 'bolder',
    //             padding: 100
    //         },
    //     },
    //     animation: false,
    //     series: [
    //         {
    //             name:'访问来源',
    //             type:'pie',
    //             radius: ['50%', '70%'],
    //                         center: ['50%', '95%'],
    //                         startAngle: 180,
    //                         endAngle: 360,
    //             avoidLabelOverlap: false,
    //             label: {
    //                 normal: {
    //                     show: false,
    //                 },
    //                 emphasis: {
    //                     show: true,
    //                     textStyle: {
    //                         fontSize: '10',
    //                         fontWeight: 'bold'
    //                     }
    //                 }
    //             },
    //             labelLine: {
    //                 normal: {
    //                     show: false
    //                 }
    //             },
    //             data:[
    //                 {value:335},
    //                 {value:310},
    //                 {value:234},
    //                 {value:135},
    //                 {value:1548}
    //             ]
    //         }
    //     ]
    // });

    getVolatilidade(series_data: any[], dias: any[]) {
        // series_data[0].name = 'Ações';
        const colors = ['#167AF7'];
        return ({
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'cross',
                    animation: true,
                    label: {
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        textStyle: {
                            color: '#222'
                        }
                    }
                }
            },
            color: colors,
            legend: {
                selectedMode: false,
                show: true,
                orient: 'hoverAnimation',
                x: 'center',
                y: 'bottom',
                data: ['Volatilidade anualizada de 21 dias úteis'],
                itemWidth: 5,
                itemHeight: 5,
                textStyle: {
                    fontFamily: 'roboto',
                    color: 'auto'
                }
            },
            xAxis: {
                type: 'category',
                data: dias
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}%'
                }
            },
            series: [{
                name: 'Volatilidade anualizada de 21 dias úteis',
                data: series_data,
                type: 'line',
                symbol: 'none'
            }]
        })
    }

    getDrawdown(series_data: any[], dias: any[]) {
        const colors = ['#167AF7'];
        return ({
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'cross',
                    animation: true,
                    label: {
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        textStyle: {
                            color: '#222'
                        }
                    }
                }
            },
            color: colors,
            legend: {
                selectedMode: false,
                show: true,
                orient: 'hoverAnimation',
                x: 'center',
                y: 'bottom',
                data: ['Perda em relação ao topo anterior'],
                itemWidth: 5,
                itemHeight: 5,
                textStyle: {
                    fontFamily: 'roboto',
                    color: 'auto'
                }
            },
            xAxis: {
                position: 'top',
                type: 'category',
                data: dias
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}%'
                }
            },
            series: [{
                name: 'Perda em relação ao topo anterior',
                data: series_data,
                type: 'line',
                symbol: 'none'
            }]
        })
    }

    getCotistas(series_data: any[], dias: any[]) {
        // series_data[0].name = 'Ações';
        const colors = ['#167AF7'];
        return ({
            color: colors,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'cross',
                    animation: true,
                    label: {
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        textStyle: {
                            color: '#222'
                        }
                    }
                }
            },
            legend: {
                selectedMode: false,
                show: true,
                orient: 'hoverAnimation',
                x: 'center',
                y: 'bottom',
                data: ['Cotistas'],
                itemWidth: 5,
                itemHeight: 5,
                textStyle: {
                    fontFamily: 'roboto',
                    color: 'auto'
                }
            },
            xAxis: {
                type: 'category',
                data: dias
            },
            yAxis: {
                type: 'value',
            },
            series: [{
                name: 'Cotistas',
                data: series_data,
                type: 'line',
                symbol: 'none'
            }]
        })
    }

    getPatrimonioOld(series_data: any[]) {
        const colors = ['#167AF7', '#00C2B7'];
        let handdle_series_data = [];
        if (series_data[0]) {
            handdle_series_data.push({
                name: 'Valor aplicado',
                type: 'line',
                symbol: 'none',
                data: series_data[0],
            })
        }
        if (series_data[1]) {
            handdle_series_data.push({
                name: 'Patrimônio líquido',
                type: 'line',
                symbol: 'none',
                data: series_data[1],
            })
        }

        return ({
            color: colors,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'cross',
                    animation: true,
                    label: {
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        textStyle: {
                            color: '#222'
                        }
                    }
                }
            },
            legend: {
                selectedMode: false,
                show: true,
                orient: 'horizontal',
                x: 'center',
                y: 'bottom',
                data: ['Valor aplicado', 'Patrimônio líquido'],
                itemWidth: 5,
                itemHeight: 5,
                textStyle: {
                    fontWeight: 'bold',
                    color: 'auto'
                }
            },
            xAxis: [
                {
                    show: false,
                    data: [820, 932, 901, 934, 1290, 1330, 1320]
                },
                {
                    type: 'category',
                    axisLine: {
                        onZero: false,
                        lineStyle: {
                            color: '#FFFFFF'
                        }
                    },
                    data: []
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        onZero: false,
                        lineStyle: {
                            color: '#FFFFFF'
                        }
                    },
                }
            ],
            series: handdle_series_data
        })
    }

    getPatrimonio(series_data: any[], dias: any[]) {
        const colors = ['#167AF7'];
        return ({
            color: colors,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'cross',
                    animation: true,
                    label: {
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        textStyle: {
                            color: '#222'
                        }
                    }
                }
            },
            legend: {
                selectedMode: false,
                show: true,
                orient: 'hoverAnimation',
                x: 'center',
                y: 'bottom',
                data: ['Soma dos títulos e valor em caixa, menos obrigações.'],
                itemWidth: 5,
                itemHeight: 5,
                textStyle: {
                    fontFamily: 'roboto',
                    color: 'auto'
                }
            },
            xAxis: {
                type: 'category',
                data: dias
            },
            yAxis: {
                type: 'value',
            },
            series: [{
                name: 'Soma dos títulos e valor em caixa, menos obrigações.',
                data: series_data,
                type: 'line',
                symbol: 'none'
            }]
        })
    }


    umAnoComparadorOptionsDetalhes(colors: string[], series_data: any[]) {
        if (series_data.length === 1) {
            const data = series_data[0].data;
            const variação = series_data[0].variacao;
            return ({
                color: colors,
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'cross',
                        animation: true,
                        label: {
                            backgroundColor: '#ccc',
                            borderColor: '#aaa',
                            borderWidth: 1,
                            shadowBlur: 0,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            textStyle: {
                                color: '#222'
                            }
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: data
                },
                yAxis: {
                    type: 'category'
                },
                series: [{
                    data: variação,
                    type: 'line',
                    symbol: 'none'
                }]
            })
        } else if (series_data.length > 1) {
            let bigger_data_length = series_data[0].data.length;
            let data_with_bigger_length = series_data[0].data;
            let series = [];
            for (const uma_serie of series_data) {
                if (uma_serie.data.length > bigger_data_length) {
                    bigger_data_length = uma_serie.data.length
                    data_with_bigger_length = uma_serie.data;
                }
                if (series_data.indexOf(uma_serie) === 0) {
                    series.push({
                        type: 'line',
                        smooth: true,
                        name: 'CDI',
                        data: uma_serie.variacao,
                        symbol: 'none'
                    })
                } else {
                    series.push({
                        type: 'line',
                        smooth: true,
                        name: 'Fundo',
                        data: uma_serie.variacao,
                        symbol: 'none'
                    })
                }
            }
            
            return ({
                color: colors,
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'cross',
                        animation: true,
                        label: {
                            backgroundColor: '#ccc',
                            borderColor: '#aaa',
                            borderWidth: 1,
                            shadowBlur: 0,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            textStyle: {
                                color: '#222'
                            }
                        }
                    }
                },
                legend:
                {
                    orient: 'horizontal',
                    selectedMode: false,
                    show: true,
                    x: 'center',
                    y: 'bottom',
                    data: ['CDI', 'Fundo'],
                    itemWidth: 5,
                    itemHeight: 5,
                    textStyle: {
                        fontWeight: 'bold',
                        color: 'auto'
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        data: series_data[0].data
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    }
                ],
                series: series
            })
        }
    }

    umAnoComparadorOptions(colors: string[], series_data: any[]) {
        if (series_data.length === 1) {
            const data = series_data[0].data;
            const variação = series_data[0].variacao;
            return ({
                color: colors,
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'cross',
                        animation: true,
                        label: {
                            backgroundColor: '#ccc',
                            borderColor: '#aaa',
                            borderWidth: 1,
                            shadowBlur: 0,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            textStyle: {
                                color: '#222'
                            }
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    data: data
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}%'
                    }
                },
                series: [{
                    data: variação,
                    type: 'line',
                    symbol: 'none'
                }]
            })
        } else if (series_data.length > 1) {
            let bigger_data_length = series_data[0].data.length;
            let data_with_bigger_length = series_data[0].data;
            let series = [];
            for (const uma_serie of series_data) {
                if (uma_serie.data.length > bigger_data_length) {
                    bigger_data_length = uma_serie.data.length
                    data_with_bigger_length = uma_serie.data;
                }
                series.push({
                    type: 'line',
                    smooth: true,
                    data: uma_serie.variacao,
                    symbol: 'none'
                })
            }
            return ({
                color: colors,
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'cross',
                        animation: true,
                        label: {
                            backgroundColor: '#ccc',
                            borderColor: '#aaa',
                            borderWidth: 1,
                            shadowBlur: 0,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            textStyle: {
                                color: '#222'
                            }
                        }
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: '#000000'
                            }
                        },
                        data: series_data[1].data
                    },
                    {
                        type: 'category',
                        axisLine: {
                            onZero: false,
                            lineStyle: {
                                color: '#FFFFFF'
                            }
                        },
                        data: series_data[1].data
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    }
                ],
                series: series
            })
        }
    }

    doisAnosComparadorOptions() {

    }

    cincoAnosComparadorOptions() {

    }

    getRentabilidade(colors: string[], periodo: number, series_data: any[], dias: any[]) {
        return ({
            color: colors,
            tooltip: {
                trigger: 'item',
                axisPointer: {
                    type: 'cross',
                    animation: true,
                    label: {
                        backgroundColor: '#ccc',
                        borderColor: '#aaa',
                        borderWidth: 1,
                        shadowBlur: 0,
                        shadowOffsetX: 0,
                        shadowOffsetY: 0,
                        textStyle: {
                            color: '#222'
                        }
                    }
                }
            },
            xAxis: {
                type: 'category',
                data: dias
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: '#FFFFFF'
                    }
                },
            },
            series: [{
                data: series_data,
                type: 'line',
                symbol: 'none'
            }]
        })
    }

    getComparador(colors: any[], series_data: any[]) {
        let periodo_function;
        periodo_function = this.umAnoComparadorOptions;
        if (series_data.length === 1 || series_data.length > 1) {
            const chartOptions = periodo_function(colors, series_data);
            return chartOptions;
        }
    }

    getCharts(colors, series_data, chart_type, names) {
        if (!chart_type || chart_type != 'area') {
            chart_type = 'line'
        }
        let series_arr = [];
        if (series_data) {
            for (const i in series_data) {
                if (series_data[i].data && series_data[i].variacao && (series_data[i].data.length === series_data[i].variacao.length)) {
                    let x_y_arr = [];
                    for (const j in series_data[i].data) {
                        if (series_data[i].data[j]) {
                            let small_arr = [];
                            small_arr.push(series_data[i].data[j]);
                            small_arr.push(series_data[i].variacao[j]);
                            x_y_arr.push(small_arr)
                        }
                    }
                    series_arr.push({
                        name: names[i],
                        type: chart_type,
                        data: x_y_arr,
                        color: colors[i],
                        visible: true
                    })
                }
            }
            return (series_arr)
        }
}
    sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();

    private itemSource = new BehaviorSubject({});

    item = this.itemSource.asObservable()

    changeItem(item_update) {
        this.itemSource.next('s')
    }

    currentChart() {
        this.sidebarVisibilityChange.next(this.current_chart_data);
    }

    getComparadorDetalhes(colors: any[], series_data: any[]) {
        let periodo_function;
        periodo_function = this.umAnoComparadorOptionsDetalhes;
        if (series_data.length === 1 || series_data.length > 1) {
            const chartOptions = periodo_function(colors, series_data);
            return chartOptions;
        }
    }
    highchartsTest() {
        return ({

            title: {
                text: 'Solar Employment Growth by Sector, 2010-2016'
            },

            subtitle: {
                text: 'Source: thesolarfoundation.com'
            },

            yAxis: {
                title: {
                    text: 'Number of Employees'
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2010
                }
            },

            series: [{
                name: 'Installation',
                data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
            }, {
                name: 'Manufacturing',
                data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
            }, {
                name: 'Sales & Distribution',
                data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
            }, {
                name: 'Project Development',
                data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
            }, {
                name: 'Other',
                data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
            }],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        }
        )
    }
}
