<div class="overall-section-contact" (click)="actionClick($event)">

  <div class="banner-template" [ngStyle]="{'background': customBackground()}" >
    <div class="banner-icon">
      <img width="55" height="55" [src]="imageUrl">
    </div>

    <div class="banner-description">
      <div class="banner-main" [ngStyle]="{'color': customMainFont()}">{{mainMessage}}</div>
      <div class="banner-sub" [ngStyle]="{'color': customSubFont()}">Investimentos: {{subMessage}}</div>
    </div>

    <div class="banner-indicator" [ngStyle]="{background: (perfil) ? '#0EBE6A' : 'inset'}">
      <mat-icon [ngStyle]="{'color': customArrowColor()}">arrow_forward</mat-icon>
      <!-- <img [attr.data-src]="arrowUrl"> -->
    </div>
  </div>
</div>
