<!-- ng template deve ir antes do toolbar porque devido ao button de filtro para mobile -->
<ng-template #buttonsMenu>
	<button mat-icon-button [matMenuTriggerFor]="menu">
		<mat-icon>more_vert</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
		<button mat-menu-item *ngFor="let item of buttons"
				[disabled]="item.disabled"
				(click)="item.onClick($event)">
			<mat-icon *ngIf="item.mdIcon">{{item.mdIcon}}</mat-icon>
			<mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" [class]="item.svgClass"></mat-icon>
			<span>{{item.text}}</span>
		</button>
	</mat-menu>
</ng-template>

<ng-template #buttonsList>
	<button mat-icon-button *ngFor="let item of buttons"
			[disabled]="item.disabled"
			(click)="item.onClick($event)" [matTooltip]="item.text" matTooltipPosition="below">
		<mat-icon *ngIf="item.mdIcon">{{item.mdIcon}}</mat-icon>
		<mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" [class]="item.svgClass"></mat-icon>
	</button>
</ng-template>

<ng-template #perfil>
	<button class="minha-conta" routerLink="/app/perfil" mat-button matLine
			style="text-align:center; background: #00FFD2; color: #323232">

		MINHA CONTA
	</button>
</ng-template>


<mat-toolbar [ngClass]="isDarkMode ? 'dark-toolbar' : 'light-mode-toolbar'" style="height: 80px; display: flex; justify-content: center">
	<div class="nav-list" [class.showToolBarBackground]="showToolBarBackground" fxLayout="row">
		<button class="hamburger-button" mat-icon-button (click)="toggleSidenav($event)">
			<mat-icon>menu</mat-icon>
		</button>
	
		<a (click)="goHome()" class="logo-icon">
			<mat-icon [inline]="true"svgIcon="logodeitadobranco"></mat-icon>
		</a>
		<button
			(menuOpened)="isOpened()"
			(menuClosed)="isClosed()"
			mat-button
			[matMenuTriggerFor]="dropInvest"
			matLine
			class="gu-unselectable"
			[ngClass]="{
                'selected':(urlRendaFixa.isActive),
                'not-selected':!(urlRendaFixa.isActive),
                'submenu-selecting':activeInvest
            }">
			INVESTIMENTOS
		</button>
		<!-- <button
			class="gu-unselectable"
			[ngClass]="{
                    'selected':urlInvest.isActive,
                    'not-selected':!urlInvest.isActive
                }"
			routerLinkActive
			#urlInvest="routerLinkActive"
			matLine
			mat-button
			routerLink="/app/parceiros">INVESTIR
		</button> -->
		<button
			class="gu-unselectable"
			(menuOpened)="isActiveFerramentas()"
			(menuClosed)="disactiveFerramentas()"
			[matMenuTriggerFor]="dropFerramentas"
			[ngClass]="{
                    'selected':(urlComparador.isActive || urlRanking.isActive || urlCalc.isActive || urlPerfil.isActive),
                    'not-selected':!(urlComparador.isActive || urlRanking.isActive || urlCalc.isActive || urlPerfil.isActive),
                    'submenu-selecting':activeFerramentas
                }"
			matLine
			mat-button>FERRAMENTAS
		</button>
		<button
			class="gu-unselectable"
			[ngClass]="{
                    'selected':(activeEducations || urlNotice.isActive || urlFaq.isActive),
                    'not-selected':!(activeEducations || urlNotice.isActive || urlFaq.isActive),
                    'submenu-selecting':activeEducations
                }"
			(menuOpened)="isActiveEducations()"
			(menuClosed)="disactiveEducations()"
			matLine
			mat-button
			[matMenuTriggerFor]="dropEducations">
			APRENDER AGORA
		</button>
		<button
			class="gu-unselectable"
			[ngStyle]="{'border-radius': (activeClass) ? '32px' : 'inset'}"
			style="border: 2px solid white; padding: 0 10px"
			mat-button
			matLine
			(click)="openAssessorDialog()">FALE COM ESPECIALISTA
		</button>
		<button
			class="minha-conta gu-unselectable"
			*ngIf="!isLogin; else perfil"
			routerLink="/usuario/login"
			mat-button
			matLine
			style=" text-align:center">
			<img class="entrar-icon" src="/assets/icons/icon-btn-login.svg" alt="user">&nbsp;&nbsp;
			ENTRAR
		</button>
	</div>
</mat-toolbar>
		  
<mat-menu [class]="isDarkMode ? 'dark-menu' : ''" #dropEducations="matMenu" yPosition="below">
	<button mat-menu-item (click)="openExternally('https://blog.apprendafixa.com.br/materiais-educativos')">MATERIAIS GRATUITOS</button>
	<button #urlNotice="routerLinkActive" routerLinkActive="link-activate" mat-menu-item
			routerLink="/app/noticias">BLOG
	</button>
	<button mat-menu-item (click)="openExternally('https://www.youtube.com/apprendafixa')">VÍDEOS</button>
	<button #urlFaq="routerLinkActive" routerLinkActive="link-activate" mat-menu-item routerLink="/app/faq">
		DÚVIDAS FREQUENTES
	</button>
</mat-menu>
<mat-menu [class]="isDarkMode ? 'dark-menu' : ''" #dropFerramentas="matMenu" yPosition="below">
	<button #urlComparador="routerLinkActive" mat-menu-item routerLinkActive="link-activate"
			routerLink="/app/fundos/comparador">COMPARADOR DE FUNDOS
	</button>
	<button #urlRanking="routerLinkActive" mat-menu-item routerLinkActive="link-activate"
			routerLink="/app/ranking">RANKING
	</button>
	<button #urlCalc="routerLinkActive" mat-menu-item routerLinkActive="link-activate"
			routerLink="/app/calculadoras">CALCULADORAS
	</button>
	<button #urlPerfil="routerLinkActive" mat-menu-item routerLinkActive="link-activate"
			routerLink="/app/perfil/suitability">DESCUBRA SEU PERFIL
	</button>
</mat-menu>
<mat-menu [class]="isDarkMode ? 'dark-menu' : ''" #dropInvest="matMenu" yPosition="below">
	<button #urlRendaFixa="routerLinkActive" mat-menu-item routerLinkActive="link-activate"
			routerLink="/app/investimentos/rendafixa">RENDA FIXA
    </button>
    <button #urlRendaFixa="routerLinkActive" mat-menu-item routerLinkActive="link-activate"
			(click)="openExternally('https://materiais.apprendafixa.com.br/renda-variavel')">RENDA VARIÁVEL
	</button>
	<button mat-menu-item routerLinkActive="link-activate" routerLink="/app/fundos">FUNDOS DE INVESTIMENTOS
	</button>
</mat-menu>

<div [ngStyle]="{'display': !showStores ? 'none' : 'Block'}" style="margin:0 auto; width:100%;">
	<div style="height: 66px;" class="fixed-popup" ngClass.xs="xs" *ngIf="!fixedPopupIsHidden">
		<div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center">
			<div class="left-side" fxLayout="row" fxLayoutAlign="start center">
				<span class="text" fxHide.xs>O App Renda Fixa também está disponível para mobile!</span>
				<a target="_blank" rel="noopener" href="http://bit.ly/apprendafixaandroid" class="img-container">
					<img src="/assets/logo/googleplay.png" alt="GooglePlay"/>
				</a>
				<a target="_blank" rel="noopener" href="http://bit.ly/apprendafixaios" class="img-container">
					<img src="/assets/logo/appstore.png" alt="AppStore"/>
				</a>
			</div>
			<button mat-icon-button (click)="closeFixedPopup()">
				<mat-icon>close</mat-icon>
			</button>
		</div>
	</div>
</div>

