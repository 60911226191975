import {environment, EnvironmentType} from "./../../environments/environment";

export class Constants {
    private host: string;
    
    constructor() {
        if (environment.production) {
            // this.host = "http://127.0.0.1:4444/";
            // this.host = "https://teste-dashboard.apprendafixa.com.br/";
            this.host = "https://api2.apprendafixa.com.br/";
        } else {
            // this.host = "https://api2.apprendafixa.com.br/";  // produção
            this.host = "https://api-test.apprendafixa.com.br/";  // teste
            // this.host = "https://api-homolog.apprendafixa.com.br/";  // homologação
            // this.host = "http://127.0.0.1:5000/";  // local
        }
    }
    
    public get recaptcha(){
        if (environment.production) return '6LdsVjsdAAAAAB8_v0h38z53yEDL_QYcs7TGB_9E';
        else return '6LcGVz8dAAAAAG1fNlJhxSnmh6udjdNRNpD4pNPD';
    }
    
    public readonly paths = {

        /* As constants abaixo estão em ordem alfabética, se for testar novos endpoints cria uma seção no final do objeto
        com um comentário escrito Debug e, depois de validado, posicione no local apropriado. */

        alertaTD: "vx/alertaTD/{objectId}/{titulo_id}",
        avista: 'vn/avista',
        avistaAwareApproved: 'vx/avista/click_not_exibit_ok_message',
        avistaAwareErrors: 'vx/avista/click_not_exibit_error_message',
        avistaClient: 'vx/avista/cliente',
        avistaClientApproved: 'vx/avista/cliente/aprovado',
        avistaClientCpfAvailable: 'vx/avista/cliente/{cpf}/available',
        avistaClientDocument: 'vx/avista/cliente/documento',
        avistaClientLogin: 'vn/avista/cliente/login',
        avistaClientToken: 'vx/avista/cliente/token',
        avistaResetPassword: 'vx/avista/cliente/resetPassword',
        avistaWallet: 'vx/avista/carteira',
        avistaWalletCompra: 'vn/avista/carteira/investir/',
        avistaWalletDelete: 'vx/avista/carteira/excluir',
        avistaWalletInvest: 'vx/avista/carteira/investir/{compraid}',
        buttonInvestirClicked: 'vx/button_investir_clicked',
        buttonTdInvestirClicked: 'vx/button_td_investir_clicked',
        calcAplicacao: "vx/aplicacao",
        calcComparador: "vx/rendafixa",
        calcDI: "vx/cetip",
        calcEquivalencia: "vx/equivalencia",
        calcPre_Pos: "vx/api/calculadoras/pre-pos",
        calcTD: "vx/tesouro",
        cep: 'vx/cep/{cep}',
        conversaoTaxas: "vx/conversao_taxas",
        enviaLead: 'vf/envia_lead',
        featuredBanners: "vn/get_featured_banners",
        feedNews: "vx/feed",
        feedAdd: "vx/feed/add",
        feedNewsId: "vx/feed/{id}",
        feedSaved: "vx/feed/saved",
        feedNewsIdSave: "vx/feed/{id}/save",
        feedNewsIdLike: "vx/feed/{id}/like",
        fundosComparador: 'fundos/comparador_fundos_v2',
        getDdi: 'vf/get_ddi',
        getIndexesChart: "vx/getIndicesGrafico/{start_date}/{end_date}",
        getInit: "vx/get_init/{hash}",
        getInvestments: 'vn/get_featured_investments_v2',
        getTitulosInfo: "vx/titulosInfo",
        ibovValue: 'rv/last_ibov',
        indicatorsHistory: "vn/get_indices_grafico",
        indicatorsReqApi: "vx/get_taxes",
        indicatorsReqApiVn: "vn/get_taxes",
        insertOrUpdateObjectId: "vx/insertOrUpdateObjectId",
        insertOrUpdateUser: "vx/insertOrUpdateUser",
        investThirdParty:"vx/get_thirdparty_featured_data/web/",
        investPremium: "vn/send_leads_eqi",
        leadGeral: 'vx/button_investir_data_filled_clicked',
        leadHurst: 'vx/button_investir_lead_filled_clicked',
        loginRecover: "vx/password",
        loginReadToken: "vx/read_token",
        loginSignUp: "vx/sign_up_user",
        loginSignUpEx: "vx/sign_up_user_ex",
        loginToken: "vx/token",
        magnetisRobotDetails: 'vn/get_all_allocation_magnetis/{risk}/{valinv}',
        municipios: 'vx/municipios/',
        p2pInvestments: "vn/get_p2p_investments",
        p2pDetails: "vn/get_p2p_details",
        perfil: "profile/basic/{email}",
        privateFeatured: "vn/get_featured_investments",
        privateHistory: "vn/get_private_history",
        publicInfo: "vx/titulosInfo",
        privateInvestClick:"vx/button_investir_clicked/",
        privateInvestmentCountTitles: "vx/count_titles",
        privateInvestments:"vx/get_investment/{asset}/",
        privateInvestmentsThirdParty:"vx/get_thirdparty_investments/{asset}/web/",
        privateInvestmentsThirdPartyClick:"vx/thirdparty_investments_clicked/",
        privateInvestSend:"vx/button_investir_data_filled_clicked_web/",
        privateSimulate: "vn/compute_private_investment",
        publicHistoric: "v2/td/historico/{asset_id}/{page}",
        publicHistoricChart: "v4/td/historicodt/", //{asset_id}/{start_date}/{end_date}
        publicHistoricSelic: "v4/td/historico_selicdt/{asset_id}/{start_date}/{end_date}",
        publicInvestments: "vx/td/cotacao/{asset}",
        redirectIdApple: 'vx/callbacks/login_apple',
        robotHistory: 'vn/get_history_robot/{robot}/{risk}',
        robotInvestments: 'vn/get_robot_list/',
        robotQueryInvestments: 'vn/get_robot_list/{robot}/{risk}',
        sendP2PLead: "vx/button_investir_data_filled_clicked_p2p",
        setTDAlert: "vx/setTD",
        sopsInvestments: 'vn/get_operacoes_estruturadas',
        suitability: "profile/suitability/{email}",
        suitability_ex: "profile/suitability_ex/{email}",
        update_one_signal_player_id_list: "vx/update_one_signal_player_id_list",
        userWithNoObjectId: "vx/one_signal_id",
        validatePhoneNumberGet: "vn/validate_phone_number?telefone={telefone}&pais={pais}",
        wallet: "vn/minhaCarteira/{wallet}",
        warrenRobotDetails: 'vn/get_allocation/{robot}/{risk}',
    }
    
    public get (key: string, host?: string) : string {
        host = host ? host : this.host;
        const path = host + this.paths[key];
        if (path === undefined) {
            throw new Error("Couldnt find " + key + " in paths");
        }
        return path;
    }

    public getUrl () {
        return this.host
    }

    public in (key: string) : boolean {
        return typeof this.paths[key] === "undefined";
    }

    public get env(): EnvironmentType {
        return environment;
    }
};
