import {Injectable, OnInit} from '@angular/core';

interface ScopeItem {
    value: any,
    once: boolean
}

export class Scope {
    private data: any = {};

    get(key) {
        const _tmp = this.data[key];
        if (!_tmp) {
            return undefined;
        } else if (_tmp.once) {
            delete this.data[key];
        }
        return _tmp.value;
    }

    getValue<T>(key): T {
        return <T>(this.get(key));
    }

    set(key, value, once?: boolean) {
        this.data[key] = {
            value: value,
            once: !!once
        }
    }

    delete(key?) {
        if (key !== undefined) {
            delete this.data[key];
        } else {
            this.data = {};
        }
    }
}

@Injectable()
export class ScopeService {
    private readonly rootScopeKey = "$root";
    private scopes: any = {
        "$root": new Scope()
    };

    private _getScope(scope: string): Scope {
        scope = scope === null || scope === undefined ? this.rootScopeKey : scope;
        if (!this.scopes[scope]) {
            this.scopes[scope] = new Scope();
        }
        return this.scopes[scope];
    }

    get(scope?: string): Scope {
        return this._getScope(scope);
    }

    delete(scope?: string) {
        if (scope !== undefined) {
            this.get(scope).delete();
        } else {
            this.scopes = {};
        }
    }

    getValue(key, scope?: string) {
        return this.get(scope).get(key);
    }

    setValue(key, value, scope?: string, once?: boolean) {
        this._getScope(scope).set(key, value, once);
    }

    setValueOnce(key, value, scope?: string) {
        return this.setValue(key, value, scope, true);
    }

    deleteValue(key, scope?: string) {
        this.get(scope).delete(key);
    }
}
