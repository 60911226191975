import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import { Sidenav, SidenavToggleResult } from '../../model/sidenav';


@Injectable()
export class SidenavService {
    private informer = new Subject();
    private static sidenav: Sidenav;
    private get sidenav(): Sidenav {return SidenavService.sidenav;};
    private set sidenav(value: Sidenav) {SidenavService.sidenav = value;};
    constructor() {
    }

    public inform () {
        this.informer.next();
    }

    public subscribe(complete?: () => void): Promise<object> {
        return new Promise<object>((resolve, reject) => {
            this.informer.subscribe(resolve, reject, complete);
        });
    }

    public __publishSidenav(sidenav: Sidenav) {
        this.sidenav = sidenav;
    }

    public toggle(isOpen?: boolean): Promise<SidenavToggleResult> {
        return this.sidenav.toggle(isOpen);
    }

    public open(): Promise<SidenavToggleResult> {
        return this.sidenav.open();
    }

    public close(): Promise<SidenavToggleResult> {
        return this.sidenav.close();
    }

    public get isOpened(): boolean {
        return this.sidenav._isOpened;
    }

    public get isClosed(): boolean {
        return !this.isOpened;
    }
}
