<div mat-dialog-content>
    <h3 mat-dialog-title>Upload</h3>
    <p>Carregando {{fileName}}</p>
    <div class="progress-bar-container">
        <div class="progress-bar primary-background-color" [style.width]="progress">
          {{progress}}
        </div>
    </div>
</div>
<div mat-dialog-content class="footer">
    <div mat-dialog-actions>
        <button mat-button (click)="close()">Cancelar</button>
        <!-- <button mat-button color="accent" [disabled]="loading" mat-dialog-close>Ok</button> -->
    </div>
</div>