<div [ngClass]="isDarkMode ? 'dark-background' : ''" class="new-section">
  <div class="blue-banner"></div>
  <div class="new-home-header">
    <div [ngClass]="isDarkMode ? 'dark-secondary-color' : ''" class="box-section">

      <div class="new-title-description">
        <h1 [ngClass]="isDarkMode ? 'dark-primary-font' : ''">Encontre o melhor <br>
        investimento para você</h1>
      </div>

      <div class="blue-line"></div>

      <div class="header-description" style="margin-bottom: 20px">
        <h2 [ngClass]="isDarkMode ? 'dark-primary-font' : ''">Busque investimentos com alta rentabilidade em renda fixa ou renda variável. Faça parte de mais de 300 mil investidores do App Renda Fixa!</h2>
      </div>
      <br>
      <mat-form-field [ngClass]="isDarkMode ? 'dark-mat-form-field' : 'light-mat-form-field'" appearance="outline" [ngStyle.xs]="{'width': '240px'}" [ngStyle.gt-xs]="{'width': '450px'}">
          <mat-icon class="gray-money" matPrefix></mat-icon>
          <input matInput class="input-search" currencyMask 
          placeholder="Quanto você quer investir?" 
          [(ngModel)]="quando_investir_rendaFixa" />
      </mat-form-field>

      <br>
      <button mat-raised-button color="accent" (click)="searchInvetimento()"><mat-icon>search</mat-icon>PESQUISAR</button>
    </div>
  </div>

  <!-- PERFIL -->

  <div class="row-templates-banner" style="margin-top: 65px">
    <div class="float-left">
      <app-button-template [subFont]="isDarkMode ? 'white' : '#888888'"
        [mainMessage]="'Tire suas dúvidas com nossos especialistas'"
        [subMessage]="'Via e-mail ou WhatsApp'" [arrowColor]="isDarkMode ? 'white' : '#323232'"
        [icon]="'new-icon-talk-to-expert'" [mainFont]="isDarkMode ? 'white' : '#323232'"
        [background]="isDarkMode ? '#474747' : 'white'" (click)="openAssessorDialog()">
      </app-button-template>
    </div>
    <div class="float-right">
      <app-button-template
      [perfil]="true"
      [arrowColor]="'white'" [mainFont]="isDarkMode ? 'white' : '#323232'"
      [mainMessage]="'Descubra seu perfil de investidor'" [subFont]="isDarkMode ? 'white' : '#888888'"
      [subMessage]="'Conservador, Moderado ou Arrojado?'" [icon]="'new-icon-perfil'"
      [background]="isDarkMode ? '#474747' : 'white'" [direction]="'in'"
      [clickable]="'/perfil/suitability'"></app-button-template>
    </div>
  </div>

  <!-- BANNERS -->
  <div class="dashboard-ads" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.lt-sm="column">
    <!-- Desktop -->
    <div style="border-radius: 8px;" fxLayout="column" class="title-container" class.xs="title-container mobile" fxFlex="100%" fxFlex.gt-xs="100%">
      <div class="left-info">
          <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="new-title-description">Parceiros</span>
          <div class="blue-line"></div>
      </div>
      <div style="border-radius: 8px;">
          <app-animated-banner [items]="carouselParceirosItems"></app-animated-banner>
      </div>
      
    </div>
  </div>

  <!-- OPORTUNIDADES DE INVESTIMENTOS -->
  <app-advisory-call-card [medium]="'Home'" [campaign]="'Home'" [darkMode]="isDarkMode"></app-advisory-call-card>

  <!-- INDICADORES -->
  <div class="middle-content">
  <div class="pink-back"></div>
    <div class="content">
      <div class="white-header">
        <span class="white-title-description">Brasil e o mundo</span>
        <div class="white-line"></div>
      </div>
      <app-new-indicators [darkMode]="isDarkMode"></app-new-indicators>
    </div>
  </div>

    <!-- INICIO DE INDICADORES COM GRAFICO -->

    <app-graphic-chart [darkMode]="isDarkMode"></app-graphic-chart>

    <div class="million-content" style="margin-top: 150px;">
      <div [ngClass]="isDarkMode ? 'dark-secondary-color' : ''"  class="box-section">
        <div class="million">
          <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="new-title-description">Descubra como alcançar</span>
          <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="new-title-description">seu primeiro milhão</span>
          <div class="blue-line"></div>
          <div class="row-templates-million">
            <mat-form-field [ngClass]="isDarkMode ? 'dark-mat-form-field' : 'light-mat-form-field'" appearance="outline">
                <input matInput currencyMask class="gray-money" placeholder="Valor para investir" [(ngModel)]="milMoney" />
            </mat-form-field>
            <mat-form-field [ngClass]="isDarkMode ? 'dark-mat-form-field' : 'light-mat-form-field'" appearance="outline">
                <input matInput currencyMask class="gray-calendar" placeholder="Valor para investir todo mês" [(ngModel)]="milCalendar" />
            </mat-form-field>
            <mat-form-field [ngClass]="isDarkMode ? 'dark-mat-form-field' : 'light-mat-form-field'" appearance="outline">
                <mat-select
                placeholder="Qual seu tipo de investimento?"
                class=""
                style="padding: 7px 0"
                [(ngModel)]="milInvest"
                required>
                <mat-option [ngStyle]="{'background': isDarkMode ? '#212121' : 'white', 'color': isDarkMode ? 'white' : '#323232'}" value="1">Poupança</mat-option>
                <mat-option [ngStyle]="{'background': isDarkMode ? '#212121' : 'white', 'color': isDarkMode ? 'white' : '#323232'}" value="2">Tesouro selic</mat-option>
                <mat-option [ngStyle]="{'background': isDarkMode ? '#212121' : 'white', 'color': isDarkMode ? 'white' : '#323232'}" value="3">Renda fixa curto prazo</mat-option>
                <mat-option [ngStyle]="{'background': isDarkMode ? '#212121' : 'white', 'color': isDarkMode ? 'white' : '#323232'}" value="4">Renda fixa longo prazo</mat-option>
              </mat-select>
            </mat-form-field>
            
          </div>
          <button mat-raised-button color="accent" (click)="calculate()">CALCULAR</button>
        </div>
      </div>

      <div class="blue-back"></div>

      <div class="row-templates-banner">
        <app-button-template
          [mainMessage]="'Descubra quanto você pode ganhar na renda fixa'"
          [subMessage]="'Calculadora de renda fixa'"
          [icon]="'money'" [subFont]="isDarkMode ? 'white' : '#888888'"
          [background]="isDarkMode ? '#474747' : 'white'" [arrowColor]="isDarkMode ? 'white' : '#323232'"
          [direction]="'in'" [mainFont]="isDarkMode ? 'white' : '#323232'"
          [clickable]="'/calculadoras'"></app-button-template>

        <app-button-template
          [mainMessage]="'Calcule os fundos existentes com os indicadores usados pelo mercado'"
          [subMessage]="'Comparador de Fundos'"
          [icon]="'finance'" [subFont]="isDarkMode ? 'white' : '#888888'"
          [background]="isDarkMode ? '#474747' : 'white'" [arrowColor]="isDarkMode ? 'white' : '#323232'"
          [direction]="'in'" [mainFont]="isDarkMode ? 'white' : '#323232'"
          [clickable]="'/fundos/comparador'"></app-button-template>
      </div>
    </div>
    

  <!-- PATROCINADORES CAROUSEL-->
  <div class="new-title" style="margin-top: 40px;">
    <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="new-title-description">Nossos parceiros</span>
    <span [ngClass]="isDarkMode ? 'dark-primary-font' : ''" class="new-title-description">em destaque</span>
    <div class="blue-line"></div>
  </div>

  <br>
  <br>

  <div class="fix-responsive">
    <owl-carousel-o *ngIf="loaded" [options]="carouselOptions">
      <ng-container *ngFor="let card of cardsParceiros">
        <ng-template carouselSlide [width]="240">
          <mat-card [ngClass]="isDarkMode ? 'dark-secondary-color' : ''" class="cards-patrocinado">
            <div class="img">
              <img [src]="card.imagem">
            </div>
          </mat-card>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
