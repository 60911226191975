<footer class="page-footer dark-theme">
    <div class="container"
        fxLayout="row" fxLayoutAlign="space-between start">
        <figure class="image-container"
            fxLayout="column" fxLayoutAlign="center center"
            fxFlex="100%" fxFlex.gt-sm="15%">
            <img src="assets/logo/porco-footer.svg" alt="">
            <figcaption fxLayout="column" fxLayoutAlign="space-around center">
                <span>Copyright</span>
                <span>App Renda Fixa&copy; {{currentYear}}</span>
            </figcaption>
        </figure>
        <div class="text-container" fxHide.lt-md
            fxLayout="row" fxLayoutAlign="space-around start"
            fxFlex="100%" fxFlex.gt-sm="85%">
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span class="title">Sobre o App</span>
                <a rel="noopener" target="_blank" href="https://assets-apprendafixa.s3.amazonaws.com/termos-de-uso-apprendafixa.pdf"><span>
                    <mat-icon svgIcon="termosdeuso" class="svgicon micro"></mat-icon>
                    Termos de Uso
                </span></a>
                <a rel="noopener" target="_blank" href="https://assets-apprendafixa.s3.amazonaws.com/politica-de-privacidade-apprendafixa.pdf"><span>
                    <mat-icon svgIcon="politicadeprivacidade" class="svgicon micro"></mat-icon>
                    Politica de Privacidade
                </span></a>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span class="title">Blog</span>
                <span>
                    <a target="_blank" rel="noopener" href="https://blog.apprendafixa.com.br/">
                        <mat-icon svgIcon="blog" class="svgicon micro"></mat-icon>
                        Blog App Renda Fixa
                    </a>
                </span>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span class="title">Siga o App Renda Fixa</span>
                <span>
                    <a target="_blank" rel="noopener" href="http://facebook.com/apprendafixa">
                        <mat-icon svgIcon="facebook" class="svgicon micro"></mat-icon>
                        Facebook
                    </a>
                </span>       
                <span>
                    <a target="_blank" rel="noopener" href="https://www.instagram.com/apprendafixa/">
                        <mat-icon svgIcon="instagram" class="svgicon micro"></mat-icon>
                        Instagram
                    </a>
                </span>
                <span>
                    <a target="_blank" rel="noopener" href="https://www.youtube.com/apprendafixa">
                        <mat-icon svgIcon="youtube" class="svgicon micro"></mat-icon>
                        Youtube
                    </a>
                </span>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span class="title">Fale com a gente</span>
                <span>
                    <a target="_blank" rel="noopener" href="https://api.whatsapp.com/send?phone=5511988829851">
                        <mat-icon svgIcon="whatsapp" class="svgicon micro"></mat-icon>
                        Fale com o Especialista
                    </a>
                </span>
                <span>
                    <a target="_blank" rel="noopener" href="mailto:contato@rendafixa.rocks">
                        <mat-icon svgIcon="email" class="svgicon micro"></mat-icon>
                        contato@rendafixa.rocks
                    </a>
                </span>
                <span>
                    <a target="_blank" rel="noopener" href="mailto:contato@rendafixa.rocks?subject=Reportat erro">
                        <mat-icon svgIcon="alerta" class="svgicon micro"></mat-icon>
                        Reportar erro
                    </a>
                </span>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span class="title">Baixe o App</span>
                <span>
                        <a target="_blank" rel="noopener" href="http://bit.ly/apprendafixaandroid">
                        <mat-icon svgIcon="android" class="svgicon micro"></mat-icon>
                        Android
                    </a>
                </span>
                <span>
                    <a target="_blank" rel="noopener" href="http://bit.ly/apprendafixaios">
                        <mat-icon svgIcon="ios" class="svgicon micro"></mat-icon>
                        IOS
                    </a>
                </span>
            </div>
        </div>
        <div class="text-container mobile" fxHide.gt-sm
            fxLayout="column" fxLayoutAlign="center start" fxFlex="100%">
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span class="title">Acompanhe</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start stretch">
                <a target="_blank" rel="noopener" href="http://facebook.com/apprendafixa">
                    <span><mat-icon svgIcon="facebook" class="svgicon"></mat-icon></span>
                </a>
                <a target="_blank" rel="noopener" href="https://www.instagram.com/apprendafixa/">
                    <span><mat-icon svgIcon="instagram" class="svgicon"></mat-icon></span>
                </a>
                <a target="_blank" rel="noopener" href="https://www.youtube.com/apprendafixa">
                    <span><mat-icon svgIcon="youtube" class="svgicon"></mat-icon></span>
                </a>
            </div>
            <div fxLayout="column" fxLayoutAlign="start stretch">
                <span class="title">Baixe o App</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start stretch">
                <a target="_blank" rel="noopener" href="http://bit.ly/apprendafixaandroid">
                    <span><mat-icon svgIcon="android" class="svgicon"></mat-icon></span>
                </a>
                <a target="_blank" rel="noopener" href="http://bit.ly/apprendafixaios">
                    <span><mat-icon svgIcon="ios" class="svgicon"></mat-icon></span>
                </a>
            </div>
        </div>
    </div>
</footer>