import {Injectable} from '@angular/core';
import {FutureService} from './future.service';
import { Emissor } from '../model/emissor';
import { Rating } from '../model/rating';
import { LatinKiller } from '../utils/latin-killer';

interface EmissorResult {
    a?: string,
    ag?: string,
    agencia?: string,
    n: string,
    r: string
}

@Injectable()
export class EmissoresService extends FutureService {
    private emissoresList: Emissor[] = [];
    private _emissores: object = {};
    constructor() {
        super();
    }

    public __getEmissores(result): void {
        let emissores = {};
        let emissoresList: Emissor[] = [];
        result = result["resultados"];
        result = result["emissores"];
        (<Array<EmissorResult>>result).forEach((emissor: EmissorResult) => {
            let _emissor: Emissor = {
                nome: emissor.n,
                agencia: emissor.a || emissor.ag || emissor.agencia || null,
                rating: Rating.get(emissor.r)
            }
            if (!_emissor.nome) return;
            const name = _emissor.nome.toUpperCase();
            emissores[name] = _emissor;
            emissores[LatinKiller.kill(name)] = _emissor;
            emissoresList.push(_emissor);
        });

        // Hard fixes
        if ("AVISTA INVESTIMENTOS" in emissores) {
            emissores["AVISTA"] = emissores["AVISTA INVESTIMENTOS"];
            emissores["AVISTA FINANCEIRA"] = emissores["AVISTA"];
        } else if ("AVISTA FINANCEIRA" in emissores) { 
            emissores["AVISTA"] = emissores["AVISTA FINANCEIRA"]; 
            emissores["AVISTA INVESTIMENTOS"] = emissores["AVISTA"]; 
        }

        this._emissores = emissores;
        this.emissoresList = emissoresList;
        this.init();
        this.inform();
    }

    getEmissor(key: string): Emissor {
        const _key: any = key;
        key = (_key || {}).toUpperCase ? _key.toUpperCase() : key;
        return this._emissores[key];
    }

    getEmissorSafe(key: string): Emissor {
        const emissor = this.getEmissor(key);
        if (emissor)
            return emissor;
        return {
            nome: key,
            agencia: null,
            rating: null
        }
    }

    public getEmissorName(key: string): string {
        return this.getEmissorSafe(key).nome;
    }

    public get emissores(): Emissor[] {
        return this.emissoresList;
    }
}
