import {Dth} from "./../utils/dth";
import {Titulo, Dados, Consolidado, Carteira} from './titulo';
import {Rating} from './rating';
import { AnonymousSubject } from 'rxjs/Rx';
export {Rating} from './rating';
export {Carteira} from './titulo';


export function parseVencto(vencto: string): Date {
    vencto = vencto.toLowerCase();
    if (vencto.indexOf(" dias")) {
        return new Dth().addDays(parseInt(vencto)).date;
    } else {
        return Dth.from(vencto.replace("-", "\\"), "\\").date;
    }
}

export function parseCarencia(carencia: string | number): number {
    if (typeof carencia === "number") {
        return carencia;
    } else if (!carencia) {
        return null;
    }
    carencia = carencia.toLowerCase();
    if (carencia.indexOf(" dias")) {
        carencia = carencia.replace(" dias", "");
    } else if (carencia.indexOf("vencimento") >= 0) {
        return null;
    } else {
        return new Dth().daysBetween(Dth.from(carencia.replace("-", "/")));
    }
    return parseInt(carencia);
}

export function parseRating(rating: string): Rating {
    return Rating.get(rating);
}


export interface Instituicao extends Dados {
    distribuicao: string;
    agencia?: string;
    __agencia: string;  // ordering
    rating?: Rating;
}

export class TituloCardBase extends Titulo implements Instituicao {
    public _taxa: string;
    public _taxaCompra?: string;
    public qualificado?: boolean;
    public distribuicao: string;
    public agencia?: string;
    public __agencia: string;  // ordering
    public rating?: Rating;
    public cores?: any;
    public amortizacao?: string;
    public aporte_fgc?: number;
    public carencia?: number;
    public _vencto: string;
    public taxaCompra?: number;
    public taxaVenda?: number;
    public precoCompra?: number;
    public precoVenda?: number;
    public jurosSemestrais?: boolean;
    public nom?: string;
    public nomeTd?: string;
    public nomeTdOld?: string;
    public poup: number;
    public patrocinado?: string;
    public invest?: boolean;
    public teq?: string;
    public nm_url_amigavel?: string;
    public imagem?: string;
    public detalhes?: string;
    public enable_popup?: boolean;
    public logo?: any;
    public url?: string;
    public tt?: any;
    public lft?: boolean;
    public imovel?: boolean;
    public rentabilidades?: any;
    public escalonado?: any;
    public juros?: Number;
    public diasIni?: any;
    public taxaString?: string;
}

export class TituloCard extends TituloCardBase {
    constructor(obj?: TituloCardBase) {
        super();
        if (!obj) {
            return;
        }
        Object.keys(obj).forEach(key => {
            this[key] = obj[key];
        });
        this.patrocinado = this.patrocinado === undefined ? null : this.patrocinado;
    }

    public static from(base: TituloCardBase, extra?: any): TituloCard {
        if (extra) {
            Object.keys(base).forEach(key => {
                if (!(key in extra))
                    extra[key] = base[key];
            });
            base = extra;
        }
        return new TituloCard(base);
    }

    public get ratingHashValue(): number {
        return this.rating ? this.rating.value : null;
    }

    public get venctoHashValue(): number {
        return this.vencto ? this.vencto.getTime() : null;
    }

    private calcValmin(): number {
        let i = 0.01;
        let _iper = 0;
        if (!this.pu) {
            return null;
        }
        do {
            _iper = this.pu * i;
            i += 0.01;
        } while (_iper < 30);
        return _iper;
    }

    public get valmin(): number {
        if (!this.carteira) return null;
        return Carteira.isTesourodireto(this) ? this.calcValmin() : this.valinv;
    }

    public get isPatrocinado(): boolean {
        return !!this.patrocinado;
    }

    public static formatVencto(vencto: string): string {
        return vencto.replace("-", "\\");
    }

    public static parseVencto(vencto: string): Date {
        return parseVencto(vencto);
    }

    public static parseCarencia(carencia: string | number): number {
        return parseCarencia(carencia);
    }

    public static parseRating(rating: string): Rating {
        return parseRating(rating);
    }

    public static formatTaxa(taxa: number, idx: string): string {
        idx = idx.toUpperCase();
        const _taxa = (taxa * 100) + "%";
        if (["IPCA", "IGPM"].indexOf(idx) > -1) {
            return idx + " +" + _taxa;
        } else if (idx === "CDI") {
            return _taxa + " " + idx;
        }
        return _taxa;
    }
}
